import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  ForwardedRef,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Button,
  Box,
  TextField,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  MenuItem,
  Autocomplete,
} from '@mui/material';
import {
  DataSheetGrid,
  createTextColumn,
  dateColumn,
  keyColumn,
  DataSheetGridRef,
  Column,
  DataSheetGridProps,
} from 'react-datasheet-grid';
import 'react-datasheet-grid/dist/style.css';
import axios from 'axios';
import config from '../config';
import { brandSelectColumn, selectColumn } from '../components/selectColumn';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';

// Återanvänd interfaces från gamla filen
interface ImportRow {
  articleNo: string;
  pluNo: string;
  attribute1Code?: string | null;
  attribute2Code?: string | null;
  attribute3Code?: string | null;
  attribute1: string | null;
  attribute2: string | null;
  attribute3: string | null;
  ean13: string | null;
  articleName: string | null;
  supplierPrice: string | null;
  weight: string | null;
  price: string | null;
  priceOrig: string | null;
  priceDkk: string | null;
  priceOrigDkk: string | null;
  brand: string | null;
  exactMatchExists?: boolean;
  existingPlu?: string;
  matchedAttributes?: string[];
  partialMatch?: boolean;
}

interface AttributeOption {
  code: string;
  number: string;
}

// Lägg till denna typ-definition i början av filen, nära dina andra interfaces
type AttributeType = 'attribute1' | 'attribute2' | 'attribute3';

// Uppdatera Attributes interface
interface Attributes {
  attribute1: (AttributeOption | string)[];
  attribute2: string[];
  attribute3: string[];
  brands: string[];
}

const createEmptyRow = (): ImportRow => ({
  articleNo: '',
  pluNo: '',
  attribute1: null,
  attribute2: null,
  attribute3: null,
  ean13: '',
  articleName: '',
  supplierPrice: '',
  weight: '',
  price: '',
  priceOrig: '',
  priceDkk: '',
  priceOrigDkk: '',
  brand: null,
  exactMatchExists: false,
  existingPlu: '',
  matchedAttributes: [],
  partialMatch: false,
});

const generateRowKey = ({
  rowData,
  rowIndex,
}: {
  rowData: ImportRow;
  rowIndex: number;
}) => {
  if (rowData.articleNo && rowData.pluNo) {
    return `${rowData.articleNo}-${rowData.pluNo}`;
  }
  return `row-${rowIndex}`;
};

// Lägg till interface för props
interface ProductImportEditProps {
  isReadOnly?: boolean;
}

// Lägg till efter ImportRow interface
interface ValidationError {
  row: number;
  errors: string[];
}

// Lägg till denna valideringsfunktion före ProductImportEdit komponenten
const validateRow = (row: ImportRow, index: number): string[] => {
  const errors: string[] = [];

  if (row.ean13 && row.ean13.length > 13) {
    errors.push(`EAN13 får inte innehålla mer än 13 siffror`);
  }

  if (row.weight && !/^\d+$/.test(row.weight)) {
    errors.push(`Vikt får endast innehålla siffror`);
  }

  const priceRegex = /^\d+([.,]\d+)?$/;
  if (row.supplierPrice && !priceRegex.test(row.supplierPrice)) {
    errors.push(`Inpris får endast innehålla siffror med en punkt eller komma`);
  }

  if (row.price && !priceRegex.test(row.price)) {
    errors.push(`Pris får endast innehålla siffror med en punkt eller komma`);
  }

  if (row.priceOrig && !priceRegex.test(row.priceOrig)) {
    errors.push(
      `Ordinarie pris får endast innehålla siffror med en punkt eller komma`,
    );
  }

  if (row.priceDkk && !priceRegex.test(row.priceDkk)) {
    errors.push(
      `Pris DKK får endast innehålla siffror med en punkt eller komma`,
    );
  }

  if (row.priceOrigDkk && !priceRegex.test(row.priceOrigDkk)) {
    errors.push(
      `Ordinarie pris DKK får endast innehålla siffror med en punkt eller komma`,
    );
  }

  return errors;
};

// Lägg till ny hjälpfunktion för att gruppera fel
const groupValidationErrors = (errors: ValidationError[]) => {
  const groupedErrors: Record<string, number[]> = {};

  errors.forEach(({ row, errors }) => {
    errors.forEach((error) => {
      if (!groupedErrors[error]) {
        groupedErrors[error] = [];
      }
      groupedErrors[error].push(row);
    });
  });

  return Object.entries(groupedErrors).map(([error, rows]) => ({
    message: error,
    rows: rows.sort((a, b) => a - b),
  }));
};

// Lägg till dessa interfaces efter de befintliga
interface AddAttributeDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (
    attributeCode: string,
    attributeNumber: string,
    attributeType: AttributeType,
  ) => Promise<void>;
  initialValue?: string;
  initialAttributeType?: AttributeType;
}

const AddAttributeDialog: React.FC<AddAttributeDialogProps> = ({
  open,
  onClose,
  onAdd,
  initialValue = '',
  initialAttributeType = 'attribute1',
}) => {
  const [attributeCode, setAttributeCode] = useState(initialValue);
  const [attributeNumber, setAttributeNumber] = useState('');
  const [attributeType, setAttributeType] =
    useState<AttributeType>(initialAttributeType);
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const attributeTypeLabels: Record<AttributeType, string> = {
    attribute1: 'Färg',
    attribute2: 'Storlek',
    attribute3: 'Längd',
  };

  // Återställ formuläret när dialogen öppnas
  useEffect(() => {
    if (open) {
      setAttributeCode(initialValue);
      setAttributeType(initialAttributeType);
      setError(null);
      setIsSubmitting(false);
      // Hämta nytt nummer om det är attribute1
      if (initialAttributeType === 'attribute1') {
        fetchNextNumber('attribute1');
      }
    }
  }, [open, initialValue, initialAttributeType]);

  // Flytta ut fetchNextNumber till en separat funktion
  const fetchNextNumber = async (type: AttributeType) => {
    if (type === 'attribute1') {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `${config.API_URL}/attribute-values/next-number`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setAttributeNumber(response.data.nextNumber);
      } catch (error) {
        console.error('Fel vid hämtning av nästa nummer:', error);
      }
    } else {
      setAttributeNumber('');
    }
  };

  // Uppdatera useEffect för attributeType
  useEffect(() => {
    fetchNextNumber(attributeType);
  }, [attributeType]);

  const handleAttributeTypeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newType = e.target.value as AttributeType;
    setAttributeType(newType);
    if (newType !== 'attribute1') {
      setAttributeNumber('');
    }
  };

  const handleSubmit = async () => {
    try {
      setError(null);
      setIsSubmitting(true);
      await onAdd(attributeCode, attributeNumber, attributeType);
      setAttributeCode('');
      setAttributeNumber('');
      onClose();
    } catch (error: any) {
      setError(error.response?.data?.error || 'Ett fel uppstod');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Lägg till {attributeTypeLabels[attributeType]}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
          <TextField
            select
            label="Attributtyp"
            value={attributeType}
            onChange={(e) => setAttributeType(e.target.value as AttributeType)}
            fullWidth
            disabled={true} // Alltid inaktiverad eftersom vi nu får typen från kolumnen
          >
            {Object.entries(attributeTypeLabels).map(([value, label]) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Attributkod"
            value={attributeCode}
            onChange={(e) => setAttributeCode(e.target.value)}
            fullWidth
            required
          />
          <TextField
            label="Attributnummer"
            value={attributeNumber}
            onChange={(e) => setAttributeNumber(e.target.value)}
            fullWidth
            required
            disabled={attributeType === 'attribute1'} // Inaktivera fältet för attribute1
          />
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Avbryt</Button>
        <Button
          onClick={() => handleSubmit()}
          variant="contained"
          disabled={!attributeCode || !attributeNumber || isSubmitting}
        >
          Lägg till
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Uppdatera UnmatchedAttribute interface
interface UnmatchedAttribute {
  rowIndex: number;
  articleNo: string;
  attributeType: AttributeType;
  value: string;
  suggestions: string[];
  attributeNumber?: string;
  columnId?: AttributeType;
}

// Uppdatera interface för AttributeResolutionDialogProps
interface AttributeResolutionDialogProps {
  open: boolean;
  unmatchedAttributes: UnmatchedAttribute[];
  onClose: () => void;
  onAddNew: (
    attributeCode: string,
    attributeNumber: string,
    attributeType: AttributeType,
  ) => Promise<void>;
  onSelect: (
    rowIndex: number,
    attributeType: AttributeType,
    value: string,
  ) => void;
  attributes: Attributes;
  setUnmatchedAttributes: React.Dispatch<
    React.SetStateAction<UnmatchedAttribute[]>
  >;
}

const AttributeResolutionDialog: React.FC<AttributeResolutionDialogProps> = ({
  open,
  unmatchedAttributes,
  onClose,
  onAddNew,
  onSelect,
  attributes,
  setUnmatchedAttributes,
}) => {
  const [selectedValues, setSelectedValues] = useState<
    Record<string, { id: string; value: string } | null>
  >({});
  const [loading, setLoading] = useState(false);
  const [isAddAttributeDialogOpen, setIsAddAttributeDialogOpen] =
    useState(false);
  const [selectedAttributeToAdd, setSelectedAttributeToAdd] = useState<{
    value: string;
    attributeType: AttributeType;
    rowIndexes: number[];
  } | null>(null);

  const attributeTypeLabels: Record<AttributeType, string> = {
    attribute1: 'Färg',
    attribute2: 'Storlek',
    attribute3: 'Längd',
  };

  // Lägg till createUniqueSuggestions funktionen
  const createUniqueSuggestions = (
    suggestions: string[],
    attributeType: AttributeType,
  ) => {
    const uniqueValues = Array.from(new Set(suggestions));
    return uniqueValues.map((suggestion) => ({
      id: `${attributeType}-${suggestion}-${Math.random().toString(36).substr(2, 9)}`,
      value: suggestion,
    }));
  };

  // Gruppera omatchade attribut
  const groupedAttributes = useMemo(() => {
    const groups = new Map<string, UnmatchedAttribute[]>();

    unmatchedAttributes.forEach((attr) => {
      // Använd columnId för att bestämma rätt attributtyp
      const actualAttributeType =
        (attr.columnId as AttributeType) || attr.attributeType;
      const key = `${attr.value}-${actualAttributeType}`;
      if (!groups.has(key)) {
        groups.set(key, []);
      }
      groups.get(key)?.push({
        ...attr,
        attributeType: actualAttributeType, // Använd den korrekta attributtypen
      });
    });

    return Array.from(groups.values()).map((group) => ({
      ...group[0],
      rowIndexes: group.map((item) => item.rowIndex),
      articleNos: group.map((item) => item.articleNo),
    }));
  }, [unmatchedAttributes]);

  const handleAddNewClick = (
    attr: UnmatchedAttribute & { rowIndexes: number[] },
  ) => {
    setSelectedAttributeToAdd({
      value: attr.value,
      attributeType: (attr.columnId as AttributeType) || attr.attributeType, // Använd columnId om det finns
      rowIndexes: attr.rowIndexes,
    });
    setIsAddAttributeDialogOpen(true);
  };

  const handleAddAttributeSubmit = async (
    attributeCode: string,
    attributeNumber: string,
    attributeType: AttributeType,
  ) => {
    if (!selectedAttributeToAdd) return;

    try {
      setLoading(true);
      await onAddNew(attributeCode, attributeNumber, attributeType);

      // Uppdatera alla berörda rader
      selectedAttributeToAdd.rowIndexes.forEach((rowIndex) => {
        onSelect(rowIndex, attributeType, attributeCode);
      });

      // Stäng bara add-dialogen
      setIsAddAttributeDialogOpen(false);

      // Ta bort bara det hanterade attributet från listan
      setUnmatchedAttributes((prev: UnmatchedAttribute[]) => {
        const updatedList = prev.filter(
          (attr: UnmatchedAttribute) =>
            !(
              attr.value === selectedAttributeToAdd.value &&
              attr.attributeType === attributeType
            ),
        );

        // Stäng huvuddialogen endast om listan är tom
        if (updatedList.length === 0) {
          onClose();
        }

        return updatedList;
      });

      setSelectedAttributeToAdd(null);
    } catch (error) {
      console.error('Fel vid tillägg av nytt attribut:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Oidentifierade attribut</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            {groupedAttributes.map((attr, index) => {
              const uniqueSuggestions = createUniqueSuggestions(
                attr.suggestions,
                attr.attributeType,
              );
              const key = `${attr.value}-${attr.attributeType}`;

              return (
                <Paper key={`${key}-${index}`} sx={{ p: 2, mb: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    {attributeTypeLabels[attr.attributeType]}: {attr.value}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Förekommer i {attr.rowIndexes.length} rad(er)
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Artiklar: {attr.articleNos.join(', ')}
                  </Typography>

                  <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                    <Autocomplete
                      fullWidth
                      options={uniqueSuggestions}
                      getOptionLabel={(option) => option.value}
                      value={selectedValues[key] || null}
                      onChange={(_, newValue) => {
                        if (newValue) {
                          setSelectedValues((prev) => ({
                            ...prev,
                            [key]: newValue,
                          }));
                          attr.rowIndexes.forEach((rowIndex) => {
                            onSelect(
                              rowIndex,
                              attr.attributeType,
                              newValue.value,
                            );
                          });
                        }
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option.value}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Välj existerande attribut"
                          variant="outlined"
                        />
                      )}
                      ListboxProps={{
                        style: {
                          maxHeight: '200px',
                          position: 'relative',
                          zIndex: 9999,
                        },
                      }}
                      componentsProps={{
                        popper: {
                          style: {
                            zIndex: 9999,
                            position: 'absolute',
                          },
                        },
                        paper: {
                          style: {
                            position: 'relative',
                          },
                        },
                      }}
                      sx={{
                        '& .MuiAutocomplete-popper': {
                          position: 'absolute !important',
                          zIndex: 9999,
                        },
                      }}
                    />
                    <Button
                      variant="contained"
                      onClick={() => handleAddNewClick(attr)}
                      disabled={loading}
                    >
                      Lägg till ny
                    </Button>
                  </Box>
                </Paper>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Stäng</Button>
        </DialogActions>
      </Dialog>

      <AddAttributeDialog
        open={isAddAttributeDialogOpen}
        onClose={() => {
          setIsAddAttributeDialogOpen(false);
          setSelectedAttributeToAdd(null);
        }}
        onAdd={handleAddAttributeSubmit}
        initialValue={selectedAttributeToAdd?.value}
        initialAttributeType={selectedAttributeToAdd?.attributeType}
      />
    </>
  );
};

// Lägg till denna interface för event details
interface UnmatchedAttributeEventDetail {
  value: string;
  rowIndex: number;
  rowData: ImportRow;
  suggestions: string[];
}

// Flytta fetchNextAttributeNumber utanför komponenterna
const fetchNextAttributeNumber = async (
  type: AttributeType,
): Promise<string> => {
  if (type === 'attribute1') {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${config.API_URL}/attribute-values/next-number`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return response.data.nextNumber;
    } catch (error) {
      console.error('Fel vid hämtning av nästa nummer:', error);
      return '';
    }
  }
  return '';
};

const ProductImportEdit: React.FC<ProductImportEditProps> = ({
  isReadOnly = false,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [rows, setRows] = useState<ImportRow[]>([
    createEmptyRow(),
    createEmptyRow(),
    createEmptyRow(),
  ]);
  const [importName, setImportName] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [attributes, setAttributes] = useState<Attributes>({
    attribute1: [],
    attribute2: [],
    attribute3: [],
    brands: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [usedPluNumbers, setUsedPluNumbers] = useState<Set<string>>(new Set());
  const gridRef = useRef<DataSheetGridRef>(null);
  const [feedback, setFeedback] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({ open: false, message: '', severity: 'success' });
  const [previousRows, setPreviousRows] = useState<ImportRow[]>([]);
  const [validationDialog, setValidationDialog] = useState<{
    open: boolean;
    errors: ValidationError[];
  }>({
    open: false,
    errors: [],
  });
  const debouncePluUpdateRef = useRef<NodeJS.Timeout>();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isAddColorDialogOpen, setIsAddColorDialogOpen] = useState(false);
  const [unmatchedAttributes, setUnmatchedAttributes] = useState<
    UnmatchedAttribute[]
  >([]);
  const [isResolutionDialogOpen, setIsResolutionDialogOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const token = localStorage.getItem('token');

      if (!token) {
        console.error('Ingen token hittades');
        setIsLoading(false);
        return;
      }

      try {
        // Hämta attribut och varumärken parallellt
        const [attributesResponse, brandsResponse] = await Promise.all([
          axios.get(`${config.API_URL}/product-imports/attributes`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }),
          axios.get(`${config.API_URL}/product-imports/brands`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }),
        ]);

        setAttributes({
          ...attributesResponse.data,
          brands: brandsResponse.data.map((b: { name: string }) => b.name),
        });

        // Om vi har ett ID, hämta även existerande import
        if (id) {
          await fetchExistingImport();
        }
      } catch (error: any) {
        console.error('Fel vid hämtning av data:', error);
        setFeedback({
          open: true,
          message: 'Kunde inte hämta data',
          severity: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const fetchExistingImport = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${config.API_URL}/product-imports/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setImportName(response.data.importName);

      // Konvertera activationDate till Date-objekt för varje rad och mappa attributkoder
      const processedRows = response.data.rows.map((row: any) => ({
        ...row,
        activationDate: row.activationDate
          ? new Date(row.activationDate)
          : null,
        // Mappa attributkoderna till attributfälten
        attribute1: row.attribute1Code,
        attribute2: row.attribute2Code,
        attribute3: row.attribute3Code,
        // Mappa danska priserna
        priceDkk: row.dkkPrice || '',
        priceOrigDkk: row.dkkPriceOrig || '',
      }));

      setRows(processedRows);

      const usedPlus = new Set<string>(
        processedRows.map((row: ImportRow) => row.pluNo).filter(Boolean),
      );
      setUsedPluNumbers(usedPlus);
    } catch (error) {
      console.error('Fel vid hämtning av import:', error);
      setFeedback({
        open: true,
        message: 'Kunde inte hämta importen',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowsChange = async (
    newRows: ImportRow[],
    operations: { type: string; fromRowIndex: number; toRowIndex: number }[],
  ) => {
    const DEBOUNCE_TIME = 300;

    console.log('handleRowsChange triggered:', {
      newRows,
      operations,
    });

    const adjustedRows = [...newRows].map((row) => {
      if (!row.articleNo?.trim()) {
        return {
          ...row,
          articleNo: '',
          pluNo: '',
        };
      }
      return row;
    });

    while (
      adjustedRows.length <=
      adjustedRows.filter((row) => row.articleNo?.trim()).length
    ) {
      adjustedRows.push(createEmptyRow());
    }

    setRows(adjustedRows);

    if (debouncePluUpdateRef.current) {
      clearTimeout(debouncePluUpdateRef.current);
    }

    debouncePluUpdateRef.current = setTimeout(async () => {
      const rowsToProcess = adjustedRows
        .map((row, index) => ({ row, index }))
        .filter(({ row, index }) => {
          const prevRow = rows[index];
          const currentArticleNo = row.articleNo?.trim() || '';
          const prevArticleNo = prevRow?.articleNo?.trim() || '';

          const attribute1Changed = row.attribute1 !== prevRow?.attribute1;
          const attribute2Changed = row.attribute2 !== prevRow?.attribute2;
          const attribute3Changed = row.attribute3 !== prevRow?.attribute3;
          const articleNoChanged = currentArticleNo !== prevArticleNo;

          console.log('Checking row changes:', {
            rowIndex: index,
            currentArticleNo,
            prevArticleNo,
            attribute1: {
              current: row.attribute1,
              previous: prevRow?.attribute1,
              changed: attribute1Changed,
            },
            attribute2: {
              current: row.attribute2,
              previous: prevRow?.attribute2,
              changed: attribute2Changed,
            },
            attribute3: {
              current: row.attribute3,
              previous: prevRow?.attribute3,
              changed: attribute3Changed,
            },
            articleNoChanged,
          });

          return (
            currentArticleNo &&
            (articleNoChanged ||
              attribute1Changed ||
              attribute2Changed ||
              attribute3Changed ||
              !row.pluNo)
          );
        });

      if (rowsToProcess.length === 0) {
        console.log('No rows to process');
        return;
      }

      console.log('Rows to process:', rowsToProcess);

      const currentPluNumbers = new Set(
        adjustedRows.map((r) => r.pluNo).filter(Boolean),
      );

      for (const { row, index } of rowsToProcess) {
        try {
          const token = localStorage.getItem('token');

          console.log('Making API request for row:', {
            rowIndex: index,
            articleNo: row.articleNo.trim(),
            attributes: {
              attribute1: row.attribute1,
              attribute2: row.attribute2,
              attribute3: row.attribute3,
            },
            existingPluNumbers: Array.from(currentPluNumbers),
            importId: id,
          });

          const { data } = await axios.post(
            `${config.API_URL}/product-imports/next-plu/${row.articleNo.trim()}`,
            {
              existingPluNumbers: Array.from(currentPluNumbers),
              attribute1Code: row.attribute1 || undefined,
              attribute2Code: row.attribute2 || undefined,
              attribute3Code: row.attribute3 || undefined,
              importId: id,
              checkPartialMatch: true,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            },
          );

          console.log('Backend response:', {
            rowIndex: index,
            articleNo: row.articleNo,
            response: data,
            matchDetails: {
              exactMatchExists: data.exactMatchExists,
              existingPlu: data.existingPlu,
              matchedAttributes: data.matchedAttributes,
              partialMatch: data.partialMatch,
            },
          });

          let nextPlu = data.nextPlu;

          if (!nextPlu) {
            const existingPluNumbers = Array.from(currentPluNumbers)
              .map((plu) => parseInt(plu))
              .filter((num) => !isNaN(num));

            const maxPlu = Math.max(0, ...existingPluNumbers);
            nextPlu = (maxPlu + 1).toString();
            console.log('Generated new PLU:', {
              maxExistingPlu: maxPlu,
              newPlu: nextPlu,
            });
          }

          currentPluNumbers.add(nextPlu);

          console.log('Updating row with match data:', {
            rowIndex: index,
            nextPlu,
            productName: data.productName,
            exactMatchExists: data.exactMatchExists,
            existingPlu: data.existingPlu,
            matchedAttributes: data.matchedAttributes,
            partialMatch: data.partialMatch,
            brandName: data.brandName,
          });

          setRows((prevRows) =>
            prevRows.map((r, idx) =>
              idx === index
                ? {
                    ...r,
                    pluNo: nextPlu,
                    articleName: data.productName || r.articleName,
                    exactMatchExists: data.exactMatchExists,
                    existingPlu: data.existingPlu,
                    matchedAttributes: data.matchedAttributes,
                    partialMatch: data.partialMatch,
                    brand: data.brandName || r.brand,
                  }
                : r,
            ),
          );
        } catch (error: any) {
          console.error('Error processing row:', {
            rowIndex: index,
            articleNo: row.articleNo,
            error: error.response?.data || error.message,
          });
          if (error.response?.status === 409) {
            console.error('PLU conflict:', error.response.data.error);
          }
        }
      }

      setUsedPluNumbers(currentPluNumbers);
    }, DEBOUNCE_TIME);

    setPreviousRows(rows);
  };

  const handleSave = useCallback(
    async (publish: boolean) => {
      try {
        if (!importName.trim()) {
          setValidationDialog({
            open: true,
            errors: [{ row: 0, errors: ['Importnamn måste anges'] }],
          });
          return;
        }

        // Lägg till kontroll för att säkerställa att vi har ett ID innan publicering
        if (publish && !id) {
          setFeedback({
            open: true,
            message:
              'Du måste först spara importen som utkast innan publicering',
            severity: 'error',
          });
          setIsDialogOpen(false);
          return;
        }

        const validRows = rows
          .filter((row) => row.articleNo?.trim() && row.pluNo?.trim())
          .map((row) => {
            // Skapa en kopia av raden med alla ursprungliga fält
            const validRow: ImportRow = {
              ...row,
              attribute1: row.attribute1,
              attribute2: row.attribute2,
              attribute3: row.attribute3,
              attribute1Code: row.attribute1,
              attribute2Code: row.attribute2,
              attribute3Code: row.attribute3,
              ean13: row.ean13?.trim() || null,
              articleName: row.articleName?.trim() || null,
              supplierPrice: row.supplierPrice || null,
              weight: row.weight || null,
              price: row.price || null,
              priceOrig: row.priceOrig || null,
              priceDkk: row.priceDkk || null,
              priceOrigDkk: row.priceOrigDkk || null,
              brand: row.brand || null,
            };

            // Validera raden
            const errors = validateRow(validRow, rows.indexOf(row) + 1);
            if (errors.length > 0) {
              throw new Error(
                `Valideringsfel på rad ${rows.indexOf(row) + 1}: ${errors.join(
                  ', ',
                )}`,
              );
            }

            // Skapa payload för backend
            const payloadRow: any = {
              articleNo: row.articleNo,
              pluNo: row.pluNo,
              attribute1Code: row.attribute1,
              attribute2Code: row.attribute2,
              attribute3Code: row.attribute3,
              ean13: row.ean13?.trim() || null,
              articleName: row.articleName?.trim() || null,
              supplierPrice: row.supplierPrice || null,
              weight: row.weight || null,
              price: row.price || null,
              priceOrig: row.priceOrig || null,
              brand: row.brand || null,
            };

            // Lägg endast till DKK-priser om de är ifyllda
            if (row.priceDkk?.trim()) {
              payloadRow.dkkPrice = row.priceDkk.trim();
            }
            if (row.priceOrigDkk?.trim()) {
              payloadRow.dkkPriceOrig = row.priceOrigDkk.trim();
            }

            return payloadRow;
          });

        console.log('Validerade rader att skicka:', validRows);

        const token = localStorage.getItem('token');
        const payload = {
          importName,
          rows: validRows,
        };

        // Om det är en publicering
        if (publish) {
          if (!id) {
            throw new Error('Import-ID saknas för publicering');
          }

          try {
            // Spara först den uppdaterade datan
            await axios.put(
              `${config.API_URL}/product-imports/${id}`,
              payload,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              },
            );

            // Sedan publicera
            await axios.post(
              `${config.API_URL}/product-imports/${id}/publish`,
              payload,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            );

            setFeedback({
              open: true,
              message: 'Importen har publicerats',
              severity: 'success',
            });

            setIsDialogOpen(false);
            navigate('/product-import');
          } catch (error) {
            if (axios.isAxiosError(error)) {
              console.error('Fel vid publicering:', error);
              setFeedback({
                open: true,
                message:
                  error.response?.data?.error ||
                  'Ett fel uppstod vid publicering',
                severity: 'error',
              });
            }
            setIsDialogOpen(false);
          }
          return;
        }

        // Spara som utkast
        try {
          const url = id
            ? `${config.API_URL}/product-imports/${id}`
            : `${config.API_URL}/product-imports`;

          const method = id ? 'put' : 'post';

          const response = await axios[method](url, payload, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });

          console.log('Save response:', response.data);

          const newImportId =
            response.data.id || response.data.importId || response.data._id;

          if (!id && newImportId) {
            console.log('Navigating to new import:', newImportId);
            navigate(`/product-import/edit/${newImportId}`, {
              replace: true,
            });
          }

          setFeedback({
            open: true,
            message: 'Importen har sparats',
            severity: 'success',
          });
        } catch (error: any) {
          console.error('Save error:', error.response?.data || error);
          if (axios.isAxiosError(error)) {
            console.error('Fel vid sparande:', error);
            setFeedback({
              open: true,
              message:
                error.response?.data?.error || 'Ett fel uppstod vid sparande',
              severity: 'error',
            });
          }
        }
      } catch (error) {
        console.error('Fel vid sparande:', error);
        setValidationDialog({
          open: true,
          errors: [
            {
              row: 0,
              errors: [
                error instanceof Error
                  ? error.message
                  : 'Ett fel uppstod vid sparande',
              ],
            },
          ],
        });
      }
    },
    [importName, rows, id, navigate],
  );

  const columns = useMemo((): Column<ImportRow>[] => {
    const getCellClassName = (rowData: ImportRow) =>
      rowData.exactMatchExists
        ? 'exact-match-cell'
        : rowData.partialMatch
          ? 'partial-match-cell'
          : '';

    const baseColumnProps = {
      cellClassName: ({ rowData }: { rowData: ImportRow }) =>
        getCellClassName(rowData),
    };

    return [
      {
        ...keyColumn('articleNo', createTextColumn()),
        title: 'Artikelnummer',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn('pluNo', createTextColumn()),
        title: 'PLU',
        disabled: false,
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn(
          'attribute1',
          selectColumn({
            choices: attributes.attribute1.map(
              (attr: string | AttributeOption) => ({
                label: typeof attr === 'string' ? attr : attr.code,
                value: typeof attr === 'string' ? attr : attr.code,
                toString: () => (typeof attr === 'string' ? attr : attr.code),
              }),
            ),
            disabled: false,
            columnId: 'attribute1',
          }),
        ),
        title: 'Färg',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn(
          'attribute2',
          selectColumn({
            choices: attributes.attribute2.map(
              (attr: string | AttributeOption) => ({
                label: typeof attr === 'string' ? attr : attr.code,
                value: typeof attr === 'string' ? attr : attr.code,
                toString: () => (typeof attr === 'string' ? attr : attr.code),
              }),
            ),
            disabled: false,
            columnId: 'attribute2',
          }),
        ),
        title: 'Storlek',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn(
          'attribute3',
          selectColumn({
            choices: attributes.attribute3.map(
              (attr: string | AttributeOption) => ({
                label: typeof attr === 'string' ? attr : attr.code,
                value: typeof attr === 'string' ? attr : attr.code,
                toString: () => (typeof attr === 'string' ? attr : attr.code),
              }),
            ),
            disabled: false,
            columnId: 'attribute3',
          }),
        ),
        title: 'Längd',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn('ean13', createTextColumn()),
        title: 'EAN13',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn('articleName', createTextColumn()),
        title: 'Artikelnamn',
        cellClassName: baseColumnProps.cellClassName,
        disabled: ({ rowData }) => !!rowData.articleName,
      },
      {
        ...keyColumn('supplierPrice', createTextColumn()),
        title: 'Inpris',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn('priceOrig', createTextColumn()),
        title: 'Ord. pris',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn('price', createTextColumn()),
        title: 'Pris',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn('priceOrigDkk', createTextColumn()),
        title: 'Ord. pris DKK',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn('priceDkk', createTextColumn()),
        title: 'Pris DKK',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn('weight', createTextColumn()),
        title: 'Vikt (g)',
        cellClassName: baseColumnProps.cellClassName,
      },
      {
        ...keyColumn(
          'brand',
          brandSelectColumn({
            choices: attributes.brands.map((brand: string) => ({
              label: brand,
              value: brand,
              toString: () => brand,
            })),
            disabled: false,
          }),
        ),
        title: 'Varumärke',
        cellClassName: baseColumnProps.cellClassName,
        disabled: ({ rowData }) => !!rowData.brand,
      },
    ];
  }, [attributes]);

  const gridProps: DataSheetGridProps<ImportRow> = {
    value: rows,
    onChange: handleRowsChange,
    columns: columns,
    createRow: createEmptyRow,
    duplicateRow: ({ rowData }) => ({
      ...rowData,
      pluNo: '',
    }),
    rowKey: generateRowKey,
    rowClassName: ({ rowData }) =>
      rowData.exactMatchExists
        ? 'exact-match-row'
        : rowData.partialMatch
          ? 'partial-match-row'
          : '',
  };

  const handleAddAttribute = async (
    attributeCode: string,
    attributeNumber: string,
    attributeType: AttributeType,
  ) => {
    const token = localStorage.getItem('token');

    try {
      await axios.post(
        `${config.API_URL}/attribute-values`,
        {
          attributeCode,
          attributeNumber,
          attributeType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      // Hämta uppdaterad attributdata
      const [attributesResponse, brandsResponse] = await Promise.all([
        axios.get(`${config.API_URL}/product-imports/attributes`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        axios.get(`${config.API_URL}/product-imports/brands`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      ]);

      // Uppdatera attributes state med den nya datan
      const newAttributes = {
        ...attributesResponse.data,
        brands: brandsResponse.data.map((b: { name: string }) => b.name),
      };

      setAttributes({ ...newAttributes });

      // Ta INTE bort denna rad - vi vill behålla dialogen öppen
      // setIsResolutionDialogOpen(false);

      // Uppdatera bara unmatchedAttributes i AttributeResolutionDialog
      // setUnmatchedAttributes([]); - Ta bort denna rad

      setFeedback({
        open: true,
        message: 'Attributet har lagts till',
        severity: 'success',
      });
    } catch (error) {
      console.error('Fel vid tillägg av attribut:', error);
      setFeedback({
        open: true,
        message: 'Ett fel uppstod när attributet skulle läggas till',
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    const handleUnmatchedAttribute = async (event: Event) => {
      if (!(event instanceof CustomEvent)) return;

      const detail = event.detail;
      if (!detail) {
        console.error('Ogiltig event data:', detail);
        return;
      }

      const { value, rowIndex, attributeType, suggestions } = detail;

      // Kontrollera bara de nödvändiga värdena
      if (!value || rowIndex === undefined) {
        console.error('Saknade värden i event data:', {
          value,
          rowIndex,
        });
        return;
      }

      let attributeNumber: string | undefined;
      if (attributeType === 'attribute1') {
        try {
          attributeNumber = await fetchNextAttributeNumber(attributeType);
        } catch (error) {
          console.error('Kunde inte hämta nästa attributnummer:', error);
        }
      }

      // Hämta articleNo från rows state istället
      const articleNo = rows[rowIndex]?.articleNo || '';

      setUnmatchedAttributes((prev) => [
        ...prev,
        {
          rowIndex,
          articleNo,
          attributeType,
          value,
          suggestions: suggestions || [],
          attributeNumber,
        },
      ]);

      // Öppna dialogen
      setIsResolutionDialogOpen(true);
    };

    window.addEventListener(
      'unmatched-attribute',
      handleUnmatchedAttribute as EventListener,
    );
    return () => {
      window.removeEventListener(
        'unmatched-attribute',
        handleUnmatchedAttribute as EventListener,
      );
    };
  }, [rows]); // Lägg till rows som dependency

  const handleAttributeResolution = (
    rowIndex: number,
    attributeType: AttributeType,
    value: string,
  ) => {
    setRows((prevRows) =>
      prevRows.map((row, index) =>
        index === rowIndex ? { ...row, [attributeType]: value } : row,
      ),
    );
  };

  // Lägg till denna hjälpfunktion
  const determineAttributeType = (event: CustomEvent): AttributeType => {
    // Hämta kolumnnamnet från event.target eller annan relevant källa
    const columnName = event.detail.columnId || '';

    switch (columnName) {
      case 'attribute1':
        return 'attribute1';
      case 'attribute2':
        return 'attribute2';
      case 'attribute3':
        return 'attribute3';
      default:
        return 'attribute1'; // Default till attribute1 om vi inte kan bestämma
    }
  };

  return (
    <Container maxWidth={false}>
      <Typography variant="h4" gutterBottom>
        {id
          ? isReadOnly
            ? 'Visa import'
            : 'Redigera import'
          : 'Ny produktimport'}
      </Typography>

      <Box
        sx={{
          mb: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TextField
          label="Importnamn"
          value={importName}
          onChange={(e) => setImportName(e.target.value)}
          sx={{ flexGrow: 1, mr: 2 }}
          disabled={isReadOnly}
          required
          error={!importName.trim()}
          helperText={!importName.trim() ? 'Importnamn måste anges' : ''}
        />
        <Button
          variant="outlined"
          onClick={() => setIsAddColorDialogOpen(true)}
          disabled={isReadOnly}
        >
          Lägg till attribut
        </Button>
      </Box>

      <AddAttributeDialog
        open={isAddColorDialogOpen}
        onClose={() => setIsAddColorDialogOpen(false)}
        onAdd={handleAddAttribute}
      />

      {feedback.open && (
        <Box sx={{ mb: 2 }}>
          <Typography
            color={feedback.severity === 'error' ? 'error' : 'success'}
            sx={{ whiteSpace: 'pre-line' }}
          >
            {feedback.message}
          </Typography>
        </Box>
      )}

      <Paper
        sx={{
          p: isFullscreen ? 1 : 2,
          height: isFullscreen ? '100vh' : '70vh',
          position: isFullscreen ? 'fixed' : 'relative',
          top: isFullscreen ? 0 : 'auto',
          left: isFullscreen ? 0 : 'auto',
          right: isFullscreen ? 0 : 'auto',
          bottom: isFullscreen ? 0 : 'auto',
          zIndex: isFullscreen ? 1300 : 1,
          m: isFullscreen ? 0 : undefined,
          borderRadius: isFullscreen ? 0 : undefined,
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Typography>Laddar...</Typography>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                mb: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body2"
                sx={{ display: 'flex', gap: 2, alignItems: 'center' }}
              >
                <Box
                  component="span"
                  sx={{
                    width: 16,
                    height: 16,
                    bgcolor: '#fff3e0',
                    border: '1px solid #ffb74d',
                  }}
                />
                Exakt matchning med befintlig produkt
              </Typography>
              <Button
                onClick={() => setIsFullscreen(!isFullscreen)}
                startIcon={isFullscreen ? <FullscreenExit /> : <Fullscreen />}
              >
                {isFullscreen ? 'Stäng fullskärm' : 'Fullskärm'}
              </Button>
            </Box>

            <Box
              sx={{
                height: isFullscreen
                  ? 'calc(100vh - 64px)'
                  : 'calc(70vh - 80px)',
                '& .exact-match-cell': {
                  backgroundColor: '#fff3e0 !important',
                  borderColor: '#ffb74d !important',
                },
                '& .partial-match-cell': {
                  backgroundColor: '#f5f5f5 !important',
                  borderColor: '#bdbdbd !important',
                },
                '& .dsg-cell': {
                  fontSize: '0.75rem !important',
                  padding: '2px 4px !important',
                  whiteSpace: 'normal !important',
                  overflow: 'visible !important',
                  textOverflow: 'clip !important',
                  lineHeight: '1.1 !important',
                  minHeight: '40px !important',
                  '& input': {
                    fontSize: '0.75rem !important',
                    padding: '2px 4px !important',
                  },
                  '& .MuiInputBase-root': {
                    fontSize: '0.75rem !important',
                    padding: '0px !important',
                  },
                  '& .MuiSelect-select': {
                    fontSize: '0.75rem !important',
                    padding: '2px 12px 2px 4px !important',
                    paddingRight: '12px !important',
                  },
                  '& .MuiSelect-icon': {
                    width: '16px !important',
                    height: '16px !important',
                    right: '0 !important',
                  },
                  // Styling för react-select komponenter
                  '& .select__control': {
                    minHeight: '28px !important',
                    padding: '0 !important',
                  },
                  '& .select__value-container': {
                    padding: '0 4px !important',
                  },
                  '& .select__indicators': {
                    width: '16px !important',
                  },
                  '& .select__indicator': {
                    padding: '0 !important',
                    width: '16px !important',
                    height: '16px !important',
                  },
                  '& .select__dropdown-indicator': {
                    padding: '0 !important',
                    width: '16px !important',
                    height: '16px !important',
                  },
                },
                '& .dsg-header-cell': {
                  fontSize: '0.75rem',
                  padding: '4px 6px',
                  fontWeight: 600,
                },
                '& .dsg-container': {
                  height: isFullscreen ? '75vh !important' : '500px !important',
                  maxHeight: isFullscreen ? 'none !important' : undefined,
                },
                // Lägg till styling för textfält och inputs
                '& .dsg-input-cell': {
                  fontSize: '0.75rem !important',
                  padding: '2px 4px !important',
                },
                // Styling för datumväljare
                '& .react-datepicker-wrapper': {
                  fontSize: '0.75rem !important',
                  '& input': {
                    fontSize: '0.75rem !important',
                    padding: '2px 4px !important',
                  },
                },
              }}
            >
              <DataSheetGrid<ImportRow>
                {...gridProps}
                ref={gridRef}
                key={JSON.stringify(attributes)}
              />
            </Box>
          </>
        )}
      </Paper>

      {!isFullscreen && (
        <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
          <Button
            variant="outlined"
            onClick={() => navigate('/product-import')}
          >
            {isReadOnly ? 'Stäng' : 'Avbryt'}
          </Button>
          {!isReadOnly && (
            <>
              <Button variant="contained" onClick={() => handleSave(false)}>
                Spara som utkast
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsDialogOpen(true)}
              >
                Publicera
              </Button>
            </>
          )}
        </Box>
      )}

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Bekräfta publicering</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Är du säker på att du vill publicera denna import?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Avbryt</Button>
          <Button
            onClick={() => handleSave(true)}
            variant="contained"
            color="primary"
          >
            Publicera
          </Button>
        </DialogActions>
      </Dialog>

      {/* Validation Dialog */}
      <Dialog
        open={validationDialog.open}
        onClose={() => setValidationDialog({ open: false, errors: [] })}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Valideringsfel</DialogTitle>
        <DialogContent>
          {groupValidationErrors(validationDialog.errors).map(
            (error, index) => (
              <Typography key={index} color="error" sx={{ mb: 1 }}>
                {error.message}
                {error.rows[0] !== 0 && ` (Rad: ${error.rows.join(', ')})`}
              </Typography>
            ),
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setValidationDialog({ open: false, errors: [] })}
          >
            Stäng
          </Button>
        </DialogActions>
      </Dialog>

      <AttributeResolutionDialog
        open={isResolutionDialogOpen}
        unmatchedAttributes={unmatchedAttributes}
        onClose={() => {
          setIsResolutionDialogOpen(false);
          setUnmatchedAttributes([]);
        }}
        onAddNew={handleAddAttribute}
        onSelect={handleAttributeResolution}
        attributes={attributes}
        setUnmatchedAttributes={setUnmatchedAttributes}
      />
    </Container>
  );
};

export default ProductImportEdit;
