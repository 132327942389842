import React, { useState, useEffect } from 'react';
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  TablePagination,
  Checkbox,
  FormControlLabel,
  Grid,
  Collapse,
  Snackbar,
  Alert,
  IconButton,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Stack,
  Chip,
  InputAdornment,
} from '@mui/material';
import config from '../config';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';
import InfoIcon from '@mui/icons-material/Info';
import PercentIcon from '@mui/icons-material/Percent';
import EuroIcon from '@mui/icons-material/Euro';
import { styled, alpha } from '@mui/material/styles';
import axios from 'axios';

// Lägg till denna styled-komponent före PriceChangeModule
const ExpandMore = styled((props: any) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

// Definiera gränssnitt för produkter och SKU-priser
interface Product {
  productId: number;
  productNumber: string;
  productName: string;
}

interface SKUPrice {
  skuId: number;
  priceLookupUnitNumber: string;
  attribute1Code: string;
  attribute1Number?: string;
  seUnitPrice?: number; // Svenska priser
  seUnitPriceOriginal?: number;
  dkUnitPrice?: number; // Danska priser
  dkUnitPriceOriginal?: number;
  unitPrice: number; // Bakåtkompatibilitet (samma som seUnitPrice)
  unitPriceOriginal: number; // Bakåtkompatibilitet (samma som seUnitPriceOriginal)
  taxRate: number;
  missingPrice?: boolean;
  currencyPrices?: {
    // Behåll för bakåtkompatibilitet
    [currencyCode: string]: {
      unitPrice: number;
      unitPriceOriginal: number;
    };
  };
}

interface CampaignSuggestion {
  productId: number;
  productNumber: string;
  productName: string;
  brandName: string;
  attribute1Code: string;
  attribute1Number: string;
  currentStock: number;
  daysSinceLastUpdate: number;
  currentPrice: number;
  suggestedDiscount: number;
  suggestedPrice: number;
  currencyCode: string;
}

// Lägg till interface för pagination
interface PaginationData {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

// Lägg till interface för varumärken
interface Brand {
  brandId: number;
  brandName: string;
  supplierId: number;
  supplierName: string;
}

// Lägg till interface för platta kategorier
interface FlatCategory {
  categoryId: number;
  categoryName: string;
  parentCategoryId: number | null;
  level: number;
  displayName: string;
  fullPath: string;
}

// Define supported currencies
interface Currency {
  code: string;
  name: string;
  symbol: string;
  flag: string; // Flag emoji or code
}

// Add country flag emoji definitions
const CURRENCIES: Currency[] = [
  { code: 'SEK', name: 'Svenska kronor', symbol: 'kr', flag: '🇸🇪' },
  { code: 'DKK', name: 'Danska kronor', symbol: 'kr', flag: '🇩🇰' },
];

// Lägg till denna nya komponent före PriceChangeModule
interface SKUPriceRowProps {
  sku: SKUPrice;
  selectedCurrencies: string[];
  currencyPriceInputs: {
    [currencyCode: string]: {
      unitPrice: string;
      unitPriceOriginal: string;
    };
  };
  discountInput?: string;
  onPriceChange: (
    currencyCode: string,
    priceType: 'unitPrice' | 'unitPriceOriginal',
    value: string,
  ) => void;
  onDiscountChange: (value: string) => void;
  showCurrencyHelpMessage: boolean;
  onHelpMessageClose: () => void;
  onToggleCurrency: (skuId: number, currencyCode: string) => void;
}

const SKUPriceRow = React.memo(
  ({
    sku,
    selectedCurrencies,
    currencyPriceInputs,
    discountInput,
    onPriceChange,
    onDiscountChange,
    showCurrencyHelpMessage,
    onHelpMessageClose,
    onToggleCurrency,
  }: SKUPriceRowProps) => {
    const calculateDiscountValue = (currencyCode: string) => {
      const prices = currencyPriceInputs[currencyCode];
      if (!prices) return '';

      const originalPrice = parseFloat(
        prices.unitPriceOriginal.replace(',', '.'),
      );
      const currentPrice = parseFloat(prices.unitPrice.replace(',', '.'));

      if (originalPrice <= 0 || currentPrice >= originalPrice) return '';
      const discount = ((originalPrice - currentPrice) / originalPrice) * 100;
      return discount.toFixed(0);
    };

    return (
      <TableRow>
        <TableCell>{sku.attribute1Code}</TableCell>
        <TableCell>
          <Box sx={{ mb: 2 }}>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                flexWrap: 'wrap',
                gap: 0.5,
                mb: 1,
              }}
            >
              {CURRENCIES.map((currency) => {
                const isSelected = selectedCurrencies.includes(currency.code);
                return (
                  <Chip
                    key={currency.code}
                    icon={
                      <Box
                        component="span"
                        sx={{ fontSize: '1.2rem', mr: 0.5 }}
                      >
                        {currency.flag}
                      </Box>
                    }
                    label={currency.code}
                    color={isSelected ? 'primary' : 'default'}
                    variant={isSelected ? 'filled' : 'outlined'}
                    onClick={() => onToggleCurrency(sku.skuId, currency.code)}
                    sx={{
                      fontWeight: isSelected ? 'bold' : 'normal',
                      borderWidth: isSelected ? 0 : 1,
                      '& .MuiChip-label': {
                        pl: 1.5,
                      },
                    }}
                  />
                );
              })}
            </Stack>
          </Box>

          {showCurrencyHelpMessage && (
            <Alert severity="info" sx={{ mb: 2 }} onClose={onHelpMessageClose}>
              <Typography variant="body2">
                Priserna du anger här kommer att lagras som egna priser i
                respektive valuta. Standardkonvertering gäller annars från SEK.
              </Typography>
            </Alert>
          )}

          {/* Svenska kronor (alltid synlig) */}
          <CurrencyPriceFields
            sku={sku}
            currencyCode="SEK"
            currencyPrices={currencyPriceInputs['SEK']}
            discountInput={discountInput}
            onPriceChange={(currencyCode, priceType, value) =>
              onPriceChange(currencyCode, priceType, value)
            }
            onDiscountChange={(value) => onDiscountChange(value)}
            primary={true}
          />

          {/* Övriga valutor */}
          {selectedCurrencies
            .filter((code) => code !== 'SEK')
            .map((currencyCode) => (
              <CurrencyPriceFields
                key={`${sku.skuId}-${currencyCode}`}
                sku={sku}
                currencyCode={currencyCode}
                currencyPrices={currencyPriceInputs[currencyCode]}
                discountValue={calculateDiscountValue(currencyCode)}
                onPriceChange={(currencyCode, priceType, value) =>
                  onPriceChange(currencyCode, priceType, value)
                }
                onDiscountChange={(value) => onDiscountChange(value)}
                primary={false}
              />
            ))}
        </TableCell>
      </TableRow>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.sku.skuId === nextProps.sku.skuId &&
      JSON.stringify(prevProps.selectedCurrencies) ===
        JSON.stringify(nextProps.selectedCurrencies) &&
      JSON.stringify(prevProps.currencyPriceInputs) ===
        JSON.stringify(nextProps.currencyPriceInputs) &&
      prevProps.discountInput === nextProps.discountInput &&
      prevProps.showCurrencyHelpMessage === nextProps.showCurrencyHelpMessage
    );
  },
);

// Uppdatera CurrencyPriceFields komponenten
interface CurrencyPriceFieldsProps {
  sku: SKUPrice;
  currencyCode: string;
  currencyPrices: {
    unitPrice: string;
    unitPriceOriginal: string;
  };
  discountInput?: string;
  discountValue?: string;
  onPriceChange: (
    currencyCode: string,
    priceType: 'unitPrice' | 'unitPriceOriginal',
    value: string,
  ) => void;
  onDiscountChange: (value: string) => void;
  primary?: boolean;
}

const CurrencyPriceFields = React.memo(
  ({
    sku,
    currencyCode,
    currencyPrices,
    discountInput,
    discountValue,
    onPriceChange,
    onDiscountChange,
    primary = false,
  }: CurrencyPriceFieldsProps) => {
    const currency = CURRENCIES.find((c) => c.code === currencyCode);

    return (
      <Card
        variant={primary ? 'outlined' : 'elevation'}
        elevation={primary ? 0 : 1}
        sx={{
          mb: 2,
          backgroundColor: primary ? 'background.paper' : alpha('#f5f5f5', 0.4),
          borderLeft: !primary ? `4px solid ${alpha('#1976d2', 0.6)}` : 'none',
        }}
      >
        <CardHeader
          avatar={
            <Box component="span" sx={{ fontSize: '1.2rem', mr: 0.5 }}>
              {currency?.flag}
            </Box>
          }
          title={currencyCode}
          titleTypographyProps={{ variant: 'subtitle1', fontWeight: 'bold' }}
          sx={{
            py: 1,
            px: 2,
            backgroundColor: primary
              ? alpha('#bbdefb', 0.1)
              : alpha('#1976d2', 0.05),
          }}
        />
        <CardContent sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                value={currencyPrices?.unitPriceOriginal || ''}
                variant="outlined"
                size="small"
                label="Ordinarie pris"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {currency?.symbol}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  onPriceChange(
                    currencyCode,
                    'unitPriceOriginal',
                    e.target.value,
                  )
                }
                inputProps={{
                  inputMode: 'decimal',
                  pattern: '^[0-9]*[.,]?[0-9]*$',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                value={primary ? discountInput || '' : discountValue || ''}
                variant="outlined"
                size="small"
                label="Rabatt"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PercentIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => onDiscountChange(e.target.value)}
                inputProps={{
                  inputMode: 'decimal',
                  pattern: '^[0-9]*[.,]?[0-9]*$',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                fullWidth
                value={currencyPrices?.unitPrice || ''}
                variant="outlined"
                size="small"
                label="Försäljningspris"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {currency?.symbol}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  onPriceChange(currencyCode, 'unitPrice', e.target.value)
                }
                inputProps={{
                  inputMode: 'decimal',
                  pattern: '^[0-9]*[.,]?[0-9]*$',
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.sku.skuId === nextProps.sku.skuId &&
      prevProps.currencyCode === nextProps.currencyCode &&
      JSON.stringify(prevProps.currencyPrices) ===
        JSON.stringify(nextProps.currencyPrices) &&
      prevProps.discountInput === nextProps.discountInput &&
      prevProps.discountValue === nextProps.discountValue
    );
  },
);

// Huvudkomponenten för prisändring
const PriceChangeModule: React.FC = () => {
  // States för produkter, sökning, vald produkt, paginering och dialog
  const [products, setProducts] = useState<Product[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProduct, setSelectedProduct] = useState<
    (Product & { skus: SKUPrice[] }) | null
  >(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Ny state för att hantera prisinmatningar som strängar
  const [priceInputs, setPriceInputs] = useState<{
    [skuId: number]: { unitPrice: string; unitPriceOriginal: string };
  }>({});

  // Ny state för att hålla reda på vilka produkter som är expanderade för redigering
  const [expandedProducts, setExpandedProducts] = useState<{
    [productId: number]: boolean;
  }>({});

  // Ny state för att hålla produkter med deras SKUs
  const [productsWithSkus, setProductsWithSkus] = useState<{
    [productId: number]: SKUPrice[];
  }>({});

  // Ny state för att hålla reda på vilka produkter som sparas
  const [savingProducts, setSavingProducts] = useState<{
    [productId: number]: boolean;
  }>({});

  const [isLoading, setIsLoading] = useState(false);

  // Lägg till ny state-variabel
  const [noPriceFilter, setNoPriceFilter] = useState(false);

  // Lägg till state-variabler för lagerfilter
  const [noStockFilter, setNoStockFilter] = useState(false);
  const [partialStockFilter, setPartialStockFilter] = useState(false);
  const [hasStockFilter, setHasStockFilter] = useState(false);

  // Lägg till state-variabel för aktiva produkter
  const [activeFilter, setActiveFilter] = useState<boolean | null>(null);

  // Lägg till denna rad bland de andra state-deklarationerna
  const [expanded, setExpanded] = useState(false);

  // Lägg till dessa states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success',
  );

  const [showCampaignSuggestions, setShowCampaignSuggestions] = useState(false);
  const [campaignSuggestions, setCampaignSuggestions] = useState<
    CampaignSuggestion[]
  >([]);

  // Lägg till nya states för paginering och sortering
  const [campaignPage, setCampaignPage] = useState(0);
  const [campaignRowsPerPage, setCampaignRowsPerPage] = useState(10);
  const [campaignPagination, setCampaignPagination] =
    useState<PaginationData | null>(null);
  const [sortBy, setSortBy] = useState('daysSinceLastUpdate');
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC'>('DESC');

  // Lägg till nytt state för rabattinput
  const [discountInput, setDiscountInput] = useState<{
    [skuId: number]: string;
  }>({});

  // Lägg till ny state-variabel för att spåra sparandet
  const [isSaving, setIsSaving] = useState(false);

  // Lägg till ny state för kampanjsökning
  const [campaignSearchTerm, setCampaignSearchTerm] = useState('');

  const [skuPrices, setSkuPrices] = useState<SKUPrice[]>([]);

  // Uppdatera state-variabel för varumärken
  const [brands, setBrands] = useState<Brand[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<string>('');

  // Lägg till state för kategorier
  const [flatCategories, setFlatCategories] = useState<FlatCategory[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null,
  );

  // Add new state for currency prices
  const [currencyPriceInputs, setCurrencyPriceInputs] = useState<{
    [skuId: number]: {
      [currencyCode: string]: {
        unitPrice: string;
        unitPriceOriginal: string;
      };
    };
  }>({});

  // State to track which currencies are being edited for each SKU
  const [selectedCurrencies, setSelectedCurrencies] = useState<{
    [skuId: number]: string[];
  }>({});

  // Add state for showing the help message
  const [showCurrencyHelpMessage, setShowCurrencyHelpMessage] = useState<{
    [skuId: number]: boolean;
  }>({});

  // Lägg till funktion för att beräkna rabattprocent
  const calculateDiscountPercentage = (
    originalPrice: number,
    currentPrice: number,
  ): string => {
    if (originalPrice <= 0 || currentPrice >= originalPrice) return '';
    const discount = ((originalPrice - currentPrice) / originalPrice) * 100;
    return discount.toFixed(0);
  };

  // Update useEffect for setting initial prices to include currency prices
  useEffect(() => {
    if (selectedProduct) {
      const initialInputs: {
        [skuId: number]: { unitPrice: string; unitPriceOriginal: string };
      } = {};
      const initialDiscountInputs: { [skuId: number]: string } = {};

      selectedProduct.skus.forEach((sku) => {
        initialInputs[sku.skuId] = {
          unitPrice: (sku.seUnitPrice || sku.unitPrice)
            .toString()
            .replace('.', ','),
          unitPriceOriginal: (sku.seUnitPriceOriginal || sku.unitPriceOriginal)
            .toString()
            .replace('.', ','),
        };

        // Beräkna och sätt initial rabattprocent
        const discountPercentage = calculateDiscountPercentage(
          sku.unitPriceOriginal,
          sku.unitPrice,
        );
        if (discountPercentage) {
          initialDiscountInputs[sku.skuId] = discountPercentage;
        }
      });
      setPriceInputs(initialInputs);
      setDiscountInput(initialDiscountInputs);
    } else {
      setPriceInputs({});
      setDiscountInput({});
    }
  }, [selectedProduct]);

  // Funktion för att hämta produkter från API
  const fetchProducts = async (
    search: string,
    page: number,
    pageSize: number,
  ) => {
    setIsLoading(true);
    try {
      // Bygg upp URL med alla filter
      let url = `${config.API_URL}/products?search=${encodeURIComponent(
        search,
      )}&page=${page + 1}&pageSize=${pageSize}&noPriceFilter=${noPriceFilter}`;

      // Lägg till brand-filter om ett varumärke är valt
      if (selectedBrand) {
        url += `&brand=${encodeURIComponent(selectedBrand)}`;
      }

      // Lägg till kategori-filter om en kategori är vald
      if (selectedCategoryId) {
        url += `&categoryId=${selectedCategoryId}`;
      }

      // Lägg till lagerfilter
      if (noStockFilter) {
        url += '&noStockFilter=true';
      }

      if (partialStockFilter) {
        url += '&partialStockFilter=true';
      }

      if (hasStockFilter) {
        url += '&hasStockFilter=true';
      }

      // Lägg till filter för aktiva produkter
      if (activeFilter !== null) {
        url += `&activeFilter=${activeFilter}`;
      }

      const response = await fetch(url);

      if (!response.ok) {
        console.error(
          'Server responded with an error:',
          response.status,
          response.statusText,
        );
        return;
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Funktion för att hämta SKUs för en specifik produkt från API
  const fetchProductSKUs = async (productId: number) => {
    try {
      const response = await fetch(
        `${config.API_URL}/products/${productId}/skus`,
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error(
          'Server responded with an error:',
          response.status,
          response.statusText,
          errorText,
        );
        return;
      }

      const data: SKUPrice[] = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching SKUs:', error);
    }
  };

  // Funktion för att hämta varumärken
  const fetchBrands = async () => {
    try {
      const response = await fetch(`${config.API_URL}/products/brands`);
      if (!response.ok) {
        throw new Error('Failed to fetch brands');
      }
      const data = await response.json();
      setBrands(data);
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  // Funktion för att hämta platta kategorier
  const fetchFlatCategories = async () => {
    try {
      const response = await fetch(
        `${config.API_URL}/products/categories?flat=true`,
      );
      if (!response.ok) {
        throw new Error('Failed to fetch categories');
      }
      const data = await response.json();
      setFlatCategories(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  // Hämta varumärken när komponenten laddas
  useEffect(() => {
    fetchBrands();
    fetchFlatCategories();
  }, []);

  // Ladda produkter när sökterm, sida, eller filter ändras
  useEffect(() => {
    const loadProducts = async () => {
      const data = await fetchProducts(searchTerm, page, rowsPerPage);
      if (data) {
        setProducts(data.products);
        setTotalPages(data.totalPages);
      }
    };
    loadProducts();
  }, [
    searchTerm,
    page,
    rowsPerPage,
    noPriceFilter,
    selectedBrand,
    selectedCategoryId,
    noStockFilter,
    partialStockFilter,
    hasStockFilter,
    activeFilter,
  ]);

  // Hantera produktklick men också initializing currency prices
  const handleProductClick = async (product: Product) => {
    // Om produkten redan är expanderad, kollapsa den
    if (expandedProducts[product.productId]) {
      setExpandedProducts((prev) => ({
        ...prev,
        [product.productId]: false,
      }));
      return;
    }

    // Om produkten inte har SKUs, hämta dem
    if (!productsWithSkus[product.productId]) {
      const skuData = await fetchProductSKUs(product.productId);
      if (skuData) {
        // Spara SKUs för produkten
        setProductsWithSkus((prev) => ({
          ...prev,
          [product.productId]: skuData,
        }));

        // Initiera prisinmatningar för SKUs
        const initialInputs: {
          [skuId: number]: { unitPrice: string; unitPriceOriginal: string };
        } = {};
        const initialDiscountInputs: { [skuId: number]: string } = {};
        const initialCurrencyInputs: {
          [skuId: number]: {
            [currencyCode: string]: {
              unitPrice: string;
              unitPriceOriginal: string;
            };
          };
        } = {};
        const initialSelectedCurrencies: {
          [skuId: number]: string[];
        } = {};

        skuData.forEach((sku) => {
          // Regular SEK price inputs
          initialInputs[sku.skuId] = {
            unitPrice: (sku.seUnitPrice || sku.unitPrice)
              .toString()
              .replace('.', ','),
            unitPriceOriginal: (
              sku.seUnitPriceOriginal || sku.unitPriceOriginal
            )
              .toString()
              .replace('.', ','),
          };

          // Initialize currency price inputs (always include SEK)
          initialCurrencyInputs[sku.skuId] = {
            SEK: {
              unitPrice: (sku.seUnitPrice || sku.unitPrice)
                .toString()
                .replace('.', ','),
              unitPriceOriginal: (
                sku.seUnitPriceOriginal || sku.unitPriceOriginal
              )
                .toString()
                .replace('.', ','),
            },
          };

          // Initialize SEK as the default selected currency
          initialSelectedCurrencies[sku.skuId] = ['SEK'];

          // Initialize DKK prices if available but don't select them by default
          if (
            sku.dkUnitPrice !== undefined &&
            sku.dkUnitPriceOriginal !== undefined
          ) {
            initialCurrencyInputs[sku.skuId]['DKK'] = {
              unitPrice: sku.dkUnitPrice.toString().replace('.', ','),
              unitPriceOriginal: sku.dkUnitPriceOriginal
                .toString()
                .replace('.', ','),
            };
            // Inte lägga till i selectedCurrencies - användaren får välja manuellt
          }

          // Legacy: Initialize existing currency prices if available (for backwards compatibility)
          if (sku.currencyPrices) {
            Object.entries(sku.currencyPrices).forEach(([code, prices]) => {
              if (
                code !== 'SEK' &&
                code !== 'DKK' &&
                initialCurrencyInputs[sku.skuId]
              ) {
                initialCurrencyInputs[sku.skuId][code] = {
                  unitPrice: prices.unitPrice.toString().replace('.', ','),
                  unitPriceOriginal: prices.unitPriceOriginal
                    .toString()
                    .replace('.', ','),
                };

                // Add currency to selected currencies if not already there
                if (!initialSelectedCurrencies[sku.skuId]?.includes(code)) {
                  initialSelectedCurrencies[sku.skuId].push(code);
                }
              }
            });
          }

          // Beräkna och sätt initial rabattprocent
          const discountPercentage = calculateDiscountPercentage(
            sku.unitPriceOriginal,
            sku.unitPrice,
          );
          if (discountPercentage) {
            initialDiscountInputs[sku.skuId] = discountPercentage;
          }
        });

        setPriceInputs((prev) => ({
          ...prev,
          ...initialInputs,
        }));

        setCurrencyPriceInputs((prev) => ({
          ...prev,
          ...initialCurrencyInputs,
        }));

        setDiscountInput((prev) => ({
          ...prev,
          ...initialDiscountInputs,
        }));

        // Set selected currencies
        setSelectedCurrencies((prev) => ({
          ...prev,
          ...initialSelectedCurrencies,
        }));
      }
    }

    // Expandera produkten
    setExpandedProducts((prev) => ({
      ...prev,
      [product.productId]: true,
    }));
  };

  // Hantera sökinput
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0);
    setSelectedProduct(null);
  };

  // Hantera stängning av dialog
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedProduct(null);
    setPriceInputs({});
    setDiscountInput({});
  };

  // Hantera prisändring genom att uppdatera priceInputs state
  const handlePriceChange = (
    skuId: number,
    priceType: 'unitPrice' | 'unitPriceOriginal',
    value: string,
  ) => {
    setPriceInputs((prev) => ({
      ...prev,
      [skuId]: {
        ...prev[skuId],
        [priceType]: value,
      },
    }));
  };

  // Fix linter errors by ensuring currencyCode is always defined
  const handleCurrencyPriceChange = (
    skuId: number,
    currencyCode: string,
    priceType: 'unitPrice' | 'unitPriceOriginal',
    value: string,
  ) => {
    setCurrencyPriceInputs((prev) => {
      const currentSkuCurrencies = prev[skuId] || {};
      const currentCurrencyPrices = currentSkuCurrencies[currencyCode] || {
        unitPrice: '',
        unitPriceOriginal: '',
      };

      return {
        ...prev,
        [skuId]: {
          ...currentSkuCurrencies,
          [currencyCode]: {
            ...currentCurrencyPrices,
            [priceType]: value,
          },
        },
      };
    });
  };

  // Function to handle currency discount change
  const handleCurrencyDiscountChange = (
    skuId: number,
    currencyCode: string,
    discountValue: string,
  ) => {
    // If value is empty, set sale price to original price
    if (!discountValue.trim()) {
      const originalPrice =
        currencyPriceInputs[skuId]?.[currencyCode]?.unitPriceOriginal || '0';
      handleCurrencyPriceChange(
        skuId,
        currencyCode,
        'unitPrice',
        originalPrice,
      );
      return;
    }

    // Calculate discounted price
    const originalPrice = parseFloat(
      currencyPriceInputs[skuId]?.[currencyCode]?.unitPriceOriginal.replace(
        ',',
        '.',
      ) || '0',
    );

    if (originalPrice && discountValue) {
      const discount = parseFloat(discountValue) / 100;
      if (!isNaN(discount)) {
        const discountedPrice = Math.round(originalPrice * (1 - discount));
        handleCurrencyPriceChange(
          skuId,
          currencyCode,
          'unitPrice',
          discountedPrice.toString(),
        );
      }
    }
  };

  // Add function to calculate discount percentage for currency prices
  const calculateCurrencyDiscountPercentage = (
    skuId: number,
    currencyCode: string,
  ): string => {
    const prices = currencyPriceInputs[skuId]?.[currencyCode];
    if (!prices) return '';

    const originalPrice = parseFloat(
      prices.unitPriceOriginal.replace(',', '.'),
    );
    const currentPrice = parseFloat(prices.unitPrice.replace(',', '.'));

    if (originalPrice <= 0 || currentPrice >= originalPrice) return '';
    const discount = ((originalPrice - currentPrice) / originalPrice) * 100;
    return discount.toFixed(0);
  };

  // Update toggleCurrency to show help message on first selection
  const toggleCurrency = (skuId: number, currencyCode: string) => {
    setSelectedCurrencies((prev) => {
      const currentCurrencies = prev[skuId] || [];
      if (currentCurrencies.includes(currencyCode)) {
        // Remove currency if already selected
        return {
          ...prev,
          [skuId]: currentCurrencies.filter((code) => code !== currencyCode),
        };
      } else {
        // Add currency if not already selected
        // Show help message if this is not SEK and we're adding a new currency
        if (currencyCode !== 'SEK' && currentCurrencies.length <= 1) {
          setShowCurrencyHelpMessage((prev) => ({
            ...prev,
            [skuId]: true,
          }));

          // Auto-hide the message after 8 seconds
          setTimeout(() => {
            setShowCurrencyHelpMessage((prev) => ({
              ...prev,
              [skuId]: false,
            }));
          }, 8000);
        }

        return {
          ...prev,
          [skuId]: [...currentCurrencies, currencyCode],
        };
      }
    });

    // Initialize price inputs for this currency if not already present
    if (!currencyPriceInputs[skuId]?.[currencyCode]) {
      // Try to get base prices from SEK or default to empty
      const baseSEKPrices = priceInputs[skuId] || {
        unitPrice: '',
        unitPriceOriginal: '',
      };

      setCurrencyPriceInputs((prev) => ({
        ...prev,
        [skuId]: {
          ...(prev[skuId] || {}),
          [currencyCode]: {
            unitPrice: baseSEKPrices.unitPrice,
            unitPriceOriginal: baseSEKPrices.unitPriceOriginal,
          },
        },
      }));
    }
  };

  // Uppdatera handleSavePricesForProduct för att endast skicka danska priser om DKK är aktivt
  const handleSavePricesForProduct = async (productId: number) => {
    const skus = productsWithSkus[productId];
    if (!skus) return;

    setSavingProducts((prev) => ({
      ...prev,
      [productId]: true,
    }));

    try {
      const updatedSkus = skus.map((sku) => {
        // Basstruktur som alltid ska skickas (svenska priser)
        const updatedSku: {
          attribute1Code: string;
          unitPrice: number;
          unitPriceOriginal: number;
          taxRate: number;
          dkUnitPrice?: number;
          dkUnitPriceOriginal?: number;
        } = {
          attribute1Code: sku.attribute1Code,
          unitPrice:
            parseFloat(
              currencyPriceInputs[sku.skuId]?.['SEK']?.unitPrice.replace(
                ',',
                '.',
              ),
            ) || sku.unitPrice,
          unitPriceOriginal:
            parseFloat(
              currencyPriceInputs[sku.skuId]?.[
                'SEK'
              ]?.unitPriceOriginal.replace(',', '.'),
            ) || sku.unitPriceOriginal,
          taxRate: sku.taxRate,
        };

        // Lägg endast till danska priser om DKK är aktivt för denna SKU
        if (selectedCurrencies[sku.skuId]?.includes('DKK')) {
          updatedSku.dkUnitPrice =
            parseFloat(
              currencyPriceInputs[sku.skuId]?.['DKK']?.unitPrice.replace(
                ',',
                '.',
              ),
            ) ||
            sku.dkUnitPrice ||
            0;
          updatedSku.dkUnitPriceOriginal =
            parseFloat(
              currencyPriceInputs[sku.skuId]?.[
                'DKK'
              ]?.unitPriceOriginal.replace(',', '.'),
            ) ||
            sku.dkUnitPriceOriginal ||
            0;
        }

        return updatedSku;
      });

      const response = await fetch(
        `${config.API_URL}/products/${productId}/update-prices`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ skus: updatedSkus }),
        },
      );

      const data = await response.json();

      if (response.ok) {
        setSnackbarMessage('Priser uppdaterade');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);

        // Kollapsa produkten efter sparande
        setExpandedProducts((prev) => ({
          ...prev,
          [productId]: false,
        }));

        // Uppdatera produktlistan
        const updatedData = await fetchProducts(searchTerm, page, rowsPerPage);
        if (updatedData) {
          setProducts(updatedData.products);
          setTotalPages(updatedData.totalPages);
        }
      } else {
        setSnackbarMessage(`Fel vid uppdatering av priser: ${data.error}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error updating prices:', error);
      setSnackbarMessage('Ett fel inträffade vid uppdatering av priser');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setSavingProducts((prev) => ({
        ...prev,
        [productId]: false,
      }));
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Lägg till hanterare för filtret
  const handleNoPriceFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNoPriceFilter(e.target.checked);
    setPage(0);
  };

  // Hanterare för lagerfilter
  const handleNoStockFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNoStockFilter(e.target.checked);
    setPage(0);
  };

  const handlePartialStockFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPartialStockFilter(e.target.checked);
    setPage(0);
  };

  const handleHasStockFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setHasStockFilter(e.target.checked);
    setPage(0);
  };

  // Hanterare för aktiva produkter filter
  const handleActiveFilterChange = (value: boolean | null) => {
    setActiveFilter(value);
    setPage(0);
  };

  // Lägg till denna hanterare
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Separera laddningsfunktionen för kampanjförslag
  const loadCampaignSuggestions = async () => {
    try {
      const params: any = {
        page: campaignPage + 1,
        limit: campaignRowsPerPage,
        minDays: 15,
        minStock: 5,
        sortBy,
        sortOrder,
      };

      // Lägg endast till search-parametern om det faktiskt finns en söksträng
      if (campaignSearchTerm.trim()) {
        params.search = campaignSearchTerm;
      }

      const response = await axios.get<{
        campaignSuggestions: CampaignSuggestion[];
        pagination: PaginationData;
      }>(`${config.API_URL}/sales/campaign-suggestions`, {
        params,
      });

      setCampaignSuggestions(response.data.campaignSuggestions);
      setCampaignPagination(response.data.pagination);
    } catch (error) {
      console.error('Fel vid hämtning av kampanjförslag:', error);
    }
  };

  // Uppdatera useEffect för kampanjförslag
  useEffect(() => {
    if (showCampaignSuggestions) {
      loadCampaignSuggestions();
    }
  }, [
    showCampaignSuggestions,
    campaignPage,
    campaignRowsPerPage,
    sortBy,
    sortOrder,
    campaignSearchTerm,
  ]);

  // Ta bort den direkta anropet av loadCampaignSuggestions från handleCampaignSearchChange
  const handleCampaignSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCampaignSearchTerm(event.target.value);
    setCampaignPage(0); // Återställ till första sidan vid ny sökning
  };

  // Hanterare för paginering
  const handleCampaignPageChange = (event: unknown, newPage: number) => {
    setCampaignPage(newPage);
  };

  const handleCampaignRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCampaignRowsPerPage(parseInt(event.target.value, 10));
    setCampaignPage(0);
  };

  const handleDiscountChange = (skuId: number, discountValue: string) => {
    // Uppdatera discount input state
    setDiscountInput((prev) => ({
      ...prev,
      [skuId]: discountValue,
    }));

    // Om värdet är tomt, sätt försäljningspris till ordinarie pris
    if (!discountValue.trim()) {
      const originalPrice = priceInputs[skuId]?.unitPriceOriginal || '0';
      handlePriceChange(skuId, 'unitPrice', originalPrice);
      return;
    }

    // Hämta originalpriser
    const originalPrice =
      parseFloat(priceInputs[skuId]?.unitPriceOriginal.replace(',', '.')) || 0;

    if (originalPrice && discountValue) {
      const discount = parseFloat(discountValue) / 100;
      if (!isNaN(discount)) {
        // Avrunda till heltal med Math.round
        const discountedPrice = Math.round(originalPrice * (1 - discount));
        // Uppdatera försäljningspriset
        handlePriceChange(skuId, 'unitPrice', discountedPrice.toString());
      }
    }
  };

  // Hantera val av varumärke
  const handleBrandChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedBrand(event.target.value);
    setPage(0); // Återställ till första sidan vid nytt varumärkesval
  };

  // Hantera val av kategori
  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedCategoryId(value ? Number(value) : null);
    setPage(0); // Återställ till första sidan vid nytt kategorival
  };

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Prishantering
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={() => setShowCampaignSuggestions(!showCampaignSuggestions)}
        sx={{ mr: 2 }}
      >
        {showCampaignSuggestions
          ? 'Visa alla produkter'
          : 'Visa kampanjförslag'}
      </Button>

      {/* Visa rätt sökruta beroende på läge */}
      {showCampaignSuggestions ? (
        <TextField
          label="Sök kampanjförslag..."
          variant="outlined"
          fullWidth
          value={campaignSearchTerm}
          onChange={handleCampaignSearchChange}
          margin="normal"
        />
      ) : (
        <TextField
          label="Sök artikelnummer eller produktnamn..."
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          margin="normal"
        />
      )}

      <Paper sx={{ p: 2, mb: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => setExpanded(!expanded)}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FilterListIcon sx={{ mr: 1 }} />
            <Typography variant="h6">Produktfilter</Typography>
          </Box>
          <ExpandMore
            expand={expanded}
            aria-expanded={expanded}
            aria-label="visa filter"
            sx={{ ml: 'auto' }}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <MonetizationOnIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="subtitle1">Pris</Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={noPriceFilter}
                        onChange={handleNoPriceFilterChange}
                      />
                    }
                    label="Saknar pris"
                  />
                </Paper>
              </Grid>

              {/* Uppdatera varumärkesfilter */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <FilterListIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="subtitle1">Varumärke</Typography>
                  </Box>
                  <Box sx={{ minWidth: 120 }}>
                    <TextField
                      select
                      fullWidth
                      label="Välj varumärke"
                      value={selectedBrand}
                      onChange={handleBrandChange}
                      variant="outlined"
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="">Alla varumärken</option>
                      {brands.map((brand) => (
                        <option key={brand.brandId} value={brand.brandName}>
                          {brand.brandName}
                        </option>
                      ))}
                    </TextField>
                  </Box>
                </Paper>
              </Grid>

              {/* Kategorifilter */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <FilterListIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="subtitle1">Kategori</Typography>
                  </Box>
                  <Box sx={{ minWidth: 120 }}>
                    <TextField
                      select
                      fullWidth
                      label="Välj kategori"
                      value={selectedCategoryId || ''}
                      onChange={handleCategoryChange}
                      variant="outlined"
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="">Alla kategorier</option>
                      {flatCategories
                        .slice()
                        .sort((a, b) =>
                          a.fullPath.localeCompare(b.fullPath, 'sv'),
                        )
                        .map((category) => (
                          <option
                            key={category.categoryId}
                            value={category.categoryId}
                          >
                            {category.fullPath}
                          </option>
                        ))}
                    </TextField>
                  </Box>
                </Paper>
              </Grid>

              {/* Lagerfilter */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <FilterListIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="subtitle1">Lager</Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={noStockFilter}
                        onChange={handleNoStockFilterChange}
                      />
                    }
                    label="Saknar lager"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={partialStockFilter}
                        onChange={handlePartialStockFilterChange}
                      />
                    }
                    label="Delvis i lager"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hasStockFilter}
                        onChange={handleHasStockFilterChange}
                      />
                    }
                    label="Finns i lager"
                  />
                </Paper>
              </Grid>

              {/* Aktiva produkter filter */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <FilterListIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="subtitle1">Status</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Button
                      variant={activeFilter === null ? 'contained' : 'outlined'}
                      size="small"
                      onClick={() => handleActiveFilterChange(null)}
                      sx={{ mb: 1 }}
                    >
                      Alla produkter
                    </Button>
                    <Button
                      variant={activeFilter === true ? 'contained' : 'outlined'}
                      size="small"
                      onClick={() => handleActiveFilterChange(true)}
                      sx={{ mb: 1 }}
                    >
                      Aktiva produkter
                    </Button>
                    <Button
                      variant={
                        activeFilter === false ? 'contained' : 'outlined'
                      }
                      size="small"
                      onClick={() => handleActiveFilterChange(false)}
                    >
                      Inaktiva produkter
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Paper>

      {showCampaignSuggestions ? (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Artikelnummer</TableCell>
                  <TableCell>Produkt</TableCell>
                  <TableCell>Varumärke</TableCell>
                  <TableCell>Färg</TableCell>
                  <TableCell
                    align="right"
                    onClick={() => {
                      setSortBy('currentStock');
                      setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Lagersaldo
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() => {
                      setSortBy('daysSinceLastUpdate');
                      setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Dagar i lager
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() => {
                      setSortBy('currentPrice');
                      setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Nuvarande pris
                  </TableCell>
                  <TableCell align="right">Föreslagen rabatt</TableCell>
                  <TableCell align="right">Föreslaget pris</TableCell>
                  <TableCell align="right">Åtgärder</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {campaignSuggestions.map((suggestion, index) => (
                  <React.Fragment
                    key={`${suggestion.productId}-${suggestion.attribute1Number}-${suggestion.attribute1Code}-${index}`}
                  >
                    <TableRow>
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() =>
                            handleProductClick({
                              productId: suggestion.productId,
                              productNumber: suggestion.productNumber,
                              productName: suggestion.productName,
                            })
                          }
                        >
                          {expandedProducts[suggestion.productId] ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>{suggestion.productNumber}</TableCell>
                      <TableCell>{suggestion.productName}</TableCell>
                      <TableCell>{suggestion.brandName}</TableCell>
                      <TableCell>{suggestion.attribute1Code}</TableCell>
                      <TableCell align="right">
                        {suggestion.currentStock}
                      </TableCell>
                      <TableCell align="right">
                        {suggestion.daysSinceLastUpdate}
                      </TableCell>
                      <TableCell align="right">
                        {suggestion.currentPrice.toLocaleString('sv-SE', {
                          style: 'currency',
                          currency: suggestion.currencyCode,
                        })}
                      </TableCell>
                      <TableCell align="right">
                        {(suggestion.suggestedDiscount * 100).toFixed(0)}%
                      </TableCell>
                      <TableCell align="right">
                        {suggestion.suggestedPrice.toLocaleString('sv-SE', {
                          style: 'currency',
                          currency: suggestion.currencyCode,
                        })}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() =>
                            handleProductClick({
                              productId: suggestion.productId,
                              productNumber: suggestion.productNumber,
                              productName: suggestion.productName,
                            })
                          }
                          startIcon={
                            expandedProducts[suggestion.productId] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )
                          }
                        >
                          {expandedProducts[suggestion.productId]
                            ? 'Stäng'
                            : 'Ändra pris'}
                        </Button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={11}
                      >
                        <Collapse
                          in={expandedProducts[suggestion.productId]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box sx={{ margin: 1 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              Prishantering
                            </Typography>
                            {productsWithSkus[suggestion.productId] && (
                              <Table size="small" aria-label="price management">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Färg</TableCell>
                                    <TableCell>
                                      Priser
                                      <Tooltip title="Välj valutor och ange olika priser för varje valuta">
                                        <IconButton size="small">
                                          <InfoIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {productsWithSkus[suggestion.productId].map(
                                    (sku) => (
                                      <SKUPriceRow
                                        key={sku.skuId}
                                        sku={sku}
                                        selectedCurrencies={
                                          selectedCurrencies[sku.skuId] || [
                                            'SEK',
                                          ]
                                        }
                                        currencyPriceInputs={
                                          currencyPriceInputs[sku.skuId] || {}
                                        }
                                        discountInput={discountInput[sku.skuId]}
                                        onPriceChange={(
                                          currencyCode,
                                          priceType,
                                          value,
                                        ) =>
                                          handleCurrencyPriceChange(
                                            sku.skuId,
                                            currencyCode,
                                            priceType,
                                            value,
                                          )
                                        }
                                        onDiscountChange={(value) =>
                                          handleDiscountChange(sku.skuId, value)
                                        }
                                        showCurrencyHelpMessage={
                                          showCurrencyHelpMessage[sku.skuId]
                                        }
                                        onHelpMessageClose={() =>
                                          setShowCurrencyHelpMessage(
                                            (prev) => ({
                                              ...prev,
                                              [sku.skuId]: false,
                                            }),
                                          )
                                        }
                                        onToggleCurrency={toggleCurrency}
                                      />
                                    ),
                                  )}
                                </TableBody>
                              </Table>
                            )}
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mt: 2,
                              }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleSavePricesForProduct(
                                    suggestion.productId,
                                  )
                                }
                                disabled={savingProducts[suggestion.productId]}
                                startIcon={
                                  savingProducts[suggestion.productId] ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <SaveIcon />
                                  )
                                }
                              >
                                {savingProducts[suggestion.productId]
                                  ? 'Sparar...'
                                  : 'Spara'}
                              </Button>
                            </Box>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={campaignPagination?.totalItems || 0}
            page={campaignPage}
            onPageChange={handleCampaignPageChange}
            rowsPerPage={campaignRowsPerPage}
            onRowsPerPageChange={handleCampaignRowsPerPageChange}
            labelRowsPerPage="Rader per sida:"
            labelDisplayedRows={({ from, to, count }) => {
              if (count === 0) return 'Inga resultat';
              return `${from}-${to} av ${count !== -1 ? count : `mer än ${to}`}`;
            }}
          />
        </>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ position: 'relative' }}>
            {isLoading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(18, 18, 18, 0.7)',
                  backdropFilter: 'blur(4px)',
                  zIndex: 1,
                }}
              >
                <CircularProgress sx={{ color: '#BB86FC' }} />
              </Box>
            )}
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Artikelnummer</TableCell>
                  <TableCell>Produktnamn</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => (
                  <React.Fragment key={product.productId}>
                    <TableRow
                      hover
                      onClick={() => handleProductClick(product)}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleProductClick(product);
                          }}
                        >
                          {expandedProducts[product.productId] ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>{product.productNumber}</TableCell>
                      <TableCell>{product.productName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={3}
                      >
                        <Collapse
                          in={expandedProducts[product.productId]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box sx={{ margin: 1 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              Prishantering
                            </Typography>
                            {productsWithSkus[product.productId] && (
                              <Table size="small" aria-label="price management">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Färg</TableCell>
                                    <TableCell>
                                      Priser
                                      <Tooltip title="Välj valutor och ange olika priser för varje valuta">
                                        <IconButton size="small">
                                          <InfoIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {productsWithSkus[product.productId].map(
                                    (sku) => (
                                      <SKUPriceRow
                                        key={sku.skuId}
                                        sku={sku}
                                        selectedCurrencies={
                                          selectedCurrencies[sku.skuId] || [
                                            'SEK',
                                          ]
                                        }
                                        currencyPriceInputs={
                                          currencyPriceInputs[sku.skuId] || {}
                                        }
                                        discountInput={discountInput[sku.skuId]}
                                        onPriceChange={(
                                          currencyCode,
                                          priceType,
                                          value,
                                        ) =>
                                          handleCurrencyPriceChange(
                                            sku.skuId,
                                            currencyCode,
                                            priceType,
                                            value,
                                          )
                                        }
                                        onDiscountChange={(value) =>
                                          handleDiscountChange(sku.skuId, value)
                                        }
                                        showCurrencyHelpMessage={
                                          showCurrencyHelpMessage[sku.skuId]
                                        }
                                        onHelpMessageClose={() =>
                                          setShowCurrencyHelpMessage(
                                            (prev) => ({
                                              ...prev,
                                              [sku.skuId]: false,
                                            }),
                                          )
                                        }
                                        onToggleCurrency={toggleCurrency}
                                      />
                                    ),
                                  )}
                                </TableBody>
                              </Table>
                            )}
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mt: 2,
                              }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleSavePricesForProduct(product.productId)
                                }
                                disabled={savingProducts[product.productId]}
                                startIcon={
                                  savingProducts[product.productId] ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <SaveIcon />
                                  )
                                }
                              >
                                {savingProducts[product.productId]
                                  ? 'Sparar...'
                                  : 'Spara'}
                              </Button>
                            </Box>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalPages * rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rader per sida:"
          />
        </>
      )}

      {/* Snackbar för meddelanden */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{
            width: '100%',
            backgroundColor: (theme) =>
              snackbarSeverity === 'success'
                ? theme.palette.success.main
                : theme.palette.error.main,
            color: 'white',
            '& .MuiAlert-icon': {
              color: 'white',
            },
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PriceChangeModule;
