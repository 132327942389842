import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3F51B5', // Djup indigo - distinkt huvudfärg
      light: '#757DE8',
      dark: '#002984',
    },
    secondary: {
      main: '#FF4081', // Stark rosa accent
      light: '#FF79B0',
      dark: '#C60055',
    },
    error: {
      main: '#FF3D00', // Livlig orange-röd
    },
    background: {
      default: '#F9FAFF', // Ljus blåtonad bakgrund
      paper: '#FFFFFF',
    },
    success: {
      main: '#00C853', // Klar grön
    },
    warning: {
      main: '#FFAB00', // Varm gul
    },
    text: {
      primary: '#1A237E', // Mörkare blå för text
      secondary: '#455A64',
    },
  },
  typography: {
    fontFamily: '"Poppins", "Inter", "Roboto", sans-serif',
    h1: {
      fontSize: '2.75rem',
      fontWeight: 700,
      letterSpacing: '-0.02em',
      background: 'linear-gradient(135deg, #3F51B5, #FF4081)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 600,
      letterSpacing: '-0.01em',
    },
    h3: {
      fontSize: '1.875rem',
      fontWeight: 600,
      letterSpacing: '-0.01em',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 600,
      letterSpacing: '-0.01em',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.6,
    },
    button: {
      fontWeight: 600,
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          padding: '0.625rem 1.5rem',
          transition: 'all 0.3s ease-in-out',
          fontWeight: 600,
          boxShadow: 'none',
        },
        contained: {
          background: 'linear-gradient(135deg, #3F51B5, #536DFE)',
          '&:hover': {
            background: 'linear-gradient(135deg, #303F9F, #3F51B5)',
            boxShadow: '0 6px 12px rgba(63, 81, 181, 0.2)',
            transform: 'translateY(-2px)',
          },
        },
        outlined: {
          borderWidth: '2px',
          '&:hover': {
            borderWidth: '2px',
            backgroundColor: 'rgba(63, 81, 181, 0.04)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          backgroundColor: '#FFFFFF',
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.05)',
          transition: 'all 0.3s ease-in-out',
          overflow: 'hidden',
          '&:hover': {
            boxShadow: '0 15px 35px rgba(0, 0, 0, 0.1)',
            transform: 'translateY(-5px)',
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '4px',
            background: 'linear-gradient(90deg, #3F51B5, #FF4081)',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          backdropFilter: 'blur(10px)',
          color: '#1A237E',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          borderRadius: '16px',
          transition: 'all 0.3s ease-in-out',
        },
        elevation1: {
          boxShadow: '0 5px 15px rgba(0, 0, 0, 0.05)',
        },
        elevation2: {
          boxShadow: '0 8px 20px rgba(0, 0, 0, 0.08)',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            transition: 'all 0.3s ease-in-out',
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#3F51B5',
              borderWidth: '2px',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#3F51B5',
              borderWidth: '2px',
              boxShadow: '0 0 0 4px rgba(63, 81, 181, 0.1)',
            },
          },
          '& .MuiInputLabel-root': {
            '&.Mui-focused': {
              color: '#3F51B5',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #E3F2FD',
          padding: '16px',
        },
        head: {
          fontWeight: 600,
          backgroundColor: '#E8EAF6',
          color: '#1A237E',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#F5F7FF',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          fontWeight: 500,
          '&.MuiChip-colorPrimary': {
            background: 'linear-gradient(135deg, #3F51B5, #536DFE)',
          },
          '&.MuiChip-colorSecondary': {
            background: 'linear-gradient(135deg, #FF4081, #F50057)',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
          '&.Mui-selected': {
            color: '#3F51B5',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: '3px',
          borderRadius: '3px',
          background: 'linear-gradient(90deg, #3F51B5, #536DFE)',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          height: '8px',
          backgroundColor: '#E8EAF6',
        },
        bar: {
          borderRadius: '10px',
          background: 'linear-gradient(90deg, #3F51B5, #536DFE)',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: '#3F51B5',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: '#F5F7FF',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#E8EAF6',
        },
      },
    },
  },
});

export default theme;
