import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  CircularProgress,
  Grow,
  Avatar,
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GroupIcon from '@mui/icons-material/Group';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import InventoryIcon from '@mui/icons-material/Inventory';
import UpdateIcon from '@mui/icons-material/Update';
import CategoryIcon from '@mui/icons-material/Category';
import axios from 'axios';
import config from '../config';

interface DashboardStats {
  products: {
    total: number;
    brands: number;
    categories: number;
    needingDescription: number;
  };
  prices: {
    productsWithPrice: number;
    averagePrice: number;
    onSale: number;
  };
}

interface Import {
  type: 'import';
  name: string;
  status: string;
  timestamp: string;
  rowCount: number;
}

interface ActivitiesResponse {
  imports: Import[];
}

interface ActivityItem {
  type: 'price' | 'product' | 'description';
  message: string;
  timestamp: string;
}

const Dashboard: React.FC = () => {
  const [stats, setStats] = useState<DashboardStats>({
    products: {
      total: 0,
      brands: 0,
      categories: 0,
      needingDescription: 0,
    },
    prices: {
      productsWithPrice: 0,
      averagePrice: 0,
      onSale: 0,
    },
  });

  const [recentActivities, setRecentActivities] = useState<ActivityItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const handleProductManagementClick = () => {
    navigate('/product-management');
  };

  const handlePriceManagementClick = () => {
    navigate('/price-change');
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      setIsLoading(true);
      try {
        const [statsResponse, activitiesResponse] = await Promise.all([
          axios.get<DashboardStats>(`${config.API_URL}/dashboard/stats`),
          axios.get<ActivitiesResponse>(
            `${config.API_URL}/dashboard/activities`,
          ),
        ]);

        setStats(statsResponse.data);

        const allActivities = activitiesResponse.data.imports.map((imp) => ({
          type: 'product' as const,
          message: `Produktimport: ${imp.name} (${imp.rowCount} rader)`,
          timestamp: imp.timestamp,
        }));

        setRecentActivities(allActivities);
      } catch (error) {
        console.error('Fel vid hämtning av dashboard-data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDashboardData();
    const interval = setInterval(fetchDashboardData, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  // Lägg till animation för statistik
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, [stats]);

  if (isLoading) {
    return (
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Box>
      <Typography
        variant="h4"
        component="h1"
        sx={{
          mb: 4,
          fontWeight: 700,
          background: 'linear-gradient(135deg, #3F51B5, #FF4081)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          letterSpacing: '-0.02em',
          display: 'inline-block',
        }}
      >
        Dashboard
      </Typography>

      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Grid container spacing={4}>
          {/* Statistik kort */}
          <Grid item xs={12} md={8}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Grow in={!isLoading} timeout={500}>
                  <Card
                    sx={{
                      height: '100%',
                      cursor: 'pointer',
                      transition: 'all 0.3s ease',
                      position: 'relative',
                      overflow: 'hidden',
                      '&:hover': {
                        transform: 'translateY(-8px)',
                        boxShadow:
                          '0 20px 40px rgba(0, 0, 0, 0.1), 0 15px 20px rgba(63, 81, 181, 0.1)',
                      },
                    }}
                    onClick={handleProductManagementClick}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background:
                          'linear-gradient(135deg, rgba(63, 81, 181, 0.05) 0%, rgba(63, 81, 181, 0) 100%)',
                        zIndex: 0,
                      }}
                    />
                    <CardContent sx={{ position: 'relative', zIndex: 1, p: 3 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mb: 3,
                        }}
                      >
                        <Avatar
                          sx={{
                            bgcolor: 'primary.main',
                            width: 48,
                            height: 48,
                            boxShadow: '0 4px 12px rgba(63, 81, 181, 0.2)',
                            mr: 2,
                          }}
                        >
                          <InventoryIcon sx={{ fontSize: 28 }} />
                        </Avatar>
                        <Typography
                          variant="h6"
                          component="div"
                          fontWeight={600}
                        >
                          Produkter
                        </Typography>
                      </Box>
                      <Typography
                        variant="h3"
                        component="div"
                        sx={{
                          mb: 3,
                          fontWeight: 700,
                          color: 'primary.main',
                        }}
                      >
                        {stats.products.total.toLocaleString()}
                      </Typography>
                      <Box sx={{ mb: 2 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mb: 1,
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            fontWeight={500}
                          >
                            Behöver beskrivning
                          </Typography>
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            color="primary.main"
                          >
                            {stats.products.needingDescription}
                          </Typography>
                        </Box>
                        <LinearProgress
                          variant="determinate"
                          value={
                            (stats.products.needingDescription /
                              stats.products.total) *
                            100
                          }
                          sx={{
                            height: 10,
                            borderRadius: 5,
                            mb: 1,
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: 3,
                          pt: 2,
                          borderTop: '1px solid',
                          borderColor: 'rgba(0, 0, 0, 0.06)',
                        }}
                      >
                        <Box sx={{ textAlign: 'center' }}>
                          <Typography
                            variant="h6"
                            color="primary.main"
                            fontWeight={600}
                          >
                            {stats.products.brands}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Varumärken
                          </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                          <Typography
                            variant="h6"
                            color="primary.main"
                            fontWeight={600}
                          >
                            {stats.products.categories}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Kategorier
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grow>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grow in={!isLoading} timeout={700}>
                  <Card
                    sx={{
                      height: '100%',
                      cursor: 'pointer',
                      transition: 'all 0.3s ease',
                      position: 'relative',
                      overflow: 'hidden',
                      '&:hover': {
                        transform: 'translateY(-8px)',
                        boxShadow:
                          '0 20px 40px rgba(0, 0, 0, 0.1), 0 15px 20px rgba(255, 64, 129, 0.1)',
                      },
                    }}
                    onClick={handlePriceManagementClick}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background:
                          'linear-gradient(135deg, rgba(255, 64, 129, 0.05) 0%, rgba(255, 64, 129, 0) 100%)',
                        zIndex: 0,
                      }}
                    />
                    <CardContent sx={{ position: 'relative', zIndex: 1, p: 3 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mb: 3,
                        }}
                      >
                        <Avatar
                          sx={{
                            bgcolor: 'secondary.main',
                            width: 48,
                            height: 48,
                            boxShadow: '0 4px 12px rgba(255, 64, 129, 0.2)',
                            mr: 2,
                          }}
                        >
                          <MonetizationOnIcon sx={{ fontSize: 28 }} />
                        </Avatar>
                        <Typography
                          variant="h6"
                          component="div"
                          fontWeight={600}
                        >
                          Priser
                        </Typography>
                      </Box>
                      <Typography
                        variant="h3"
                        component="div"
                        sx={{
                          mb: 3,
                          fontWeight: 700,
                          color: 'secondary.main',
                        }}
                      >
                        {stats.prices.productsWithPrice.toLocaleString()}
                      </Typography>
                      <Box sx={{ mb: 2 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mb: 1,
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            fontWeight={500}
                          >
                            Produkter på rea
                          </Typography>
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            color="secondary.main"
                          >
                            {stats.prices.onSale}
                          </Typography>
                        </Box>
                        <LinearProgress
                          variant="determinate"
                          value={
                            (stats.prices.onSale /
                              stats.prices.productsWithPrice) *
                            100
                          }
                          color="secondary"
                          sx={{
                            height: 10,
                            borderRadius: 5,
                            mb: 1,
                            '& .MuiLinearProgress-bar': {
                              background:
                                'linear-gradient(90deg, #FF4081, #F50057)',
                            },
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          mt: 3,
                          pt: 2,
                          borderTop: '1px solid',
                          borderColor: 'rgba(0, 0, 0, 0.06)',
                        }}
                      >
                        <Box sx={{ textAlign: 'center' }}>
                          <Typography
                            variant="h6"
                            color="secondary.main"
                            fontWeight={600}
                          >
                            {stats.prices.averagePrice.toLocaleString()} kr
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Genomsnittspris
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grow>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <Grow in={!isLoading} timeout={900}>
              <Card
                sx={{
                  height: '100%',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '4px',
                    background: 'linear-gradient(90deg, #3F51B5, #FF4081)',
                    zIndex: 1,
                  }}
                />
                <CardContent sx={{ p: 0 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      p: 3,
                      borderBottom: '1px solid',
                      borderColor: 'rgba(0, 0, 0, 0.06)',
                    }}
                  >
                    <Avatar
                      sx={{
                        background: 'linear-gradient(135deg, #3F51B5, #536DFE)',
                        width: 40,
                        height: 40,
                        boxShadow: '0 4px 12px rgba(63, 81, 181, 0.2)',
                        mr: 2,
                      }}
                    >
                      <UpdateIcon sx={{ fontSize: 24 }} />
                    </Avatar>
                    <Typography variant="h6" component="div" fontWeight={600}>
                      Senaste aktiviteter
                    </Typography>
                  </Box>
                  <List sx={{ py: 0 }}>
                    {recentActivities.length > 0 ? (
                      recentActivities.map((activity, index) => (
                        <React.Fragment key={index}>
                          <ListItem
                            alignItems="flex-start"
                            sx={{
                              py: 2,
                              px: 3,
                              transition: 'all 0.2s ease',
                              '&:hover': {
                                backgroundColor: 'rgba(63, 81, 181, 0.05)',
                              },
                            }}
                          >
                            <ListItemIcon sx={{ minWidth: 42 }}>
                              <Avatar
                                sx={{
                                  width: 32,
                                  height: 32,
                                  bgcolor:
                                    activity.type === 'price'
                                      ? 'secondary.light'
                                      : activity.type === 'product'
                                        ? 'primary.light'
                                        : 'success.light',
                                }}
                              >
                                {activity.type === 'price' && (
                                  <MonetizationOnIcon fontSize="small" />
                                )}
                                {activity.type === 'product' && (
                                  <InventoryIcon fontSize="small" />
                                )}
                                {activity.type === 'description' && (
                                  <SmartToyIcon fontSize="small" />
                                )}
                              </Avatar>
                            </ListItemIcon>
                            <ListItemText
                              primary={activity.message}
                              secondary={activity.timestamp}
                              primaryTypographyProps={{
                                variant: 'body2',
                                fontWeight: 500,
                                color: 'text.primary',
                              }}
                              secondaryTypographyProps={{
                                variant: 'caption',
                                color: 'text.secondary',
                                sx: { mt: 0.5 },
                              }}
                            />
                          </ListItem>
                          {index < recentActivities.length - 1 && (
                            <Divider variant="inset" component="li" />
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <Box sx={{ p: 4, textAlign: 'center' }}>
                        <Typography variant="body2" color="text.secondary">
                          Inga aktiviteter att visa
                        </Typography>
                      </Box>
                    )}
                  </List>
                </CardContent>
              </Card>
            </Grow>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Dashboard;
