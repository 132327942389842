// UploadMediaModal.tsx
import React, { useState } from 'react';
import {
  Modal,
  TextField,
  Button,
  Typography,
  Autocomplete,
  CircularProgress,
  Box,
} from '@mui/material';
import DropzoneArea from './DropzoneArea';

interface UploadMediaModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: UploadData) => void;
  uploadedImages: string[];
  handlePreview: (data: UploadData) => void;
  productNumber: string;
  availableColors: string[];
  initialColorCode: string;
  attribute1Number: string; // Ny prop
}

interface UploadData {
  articleNumber: string;
  colorCode: string;
  attribute1Number: string;
  images: File[];
  transparentIndices: number[];
}

const UploadMediaModal: React.FC<UploadMediaModalProps> = ({
  open,
  onClose,
  onSubmit,
  uploadedImages,
  handlePreview,
  productNumber,
  availableColors,
  initialColorCode,
  attribute1Number, // Ny prop
}) => {
  const [selectedColor, setSelectedColor] = useState<string>(
    initialColorCode || '',
  );
  const [files, setFiles] = useState<File[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [transparentIndices, setTransparentIndices] = useState<number[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const onDrop = (acceptedFiles: File[]) => {
    const imageFiles = acceptedFiles.filter((file) =>
      file.type.startsWith('image/'),
    );

    if (imageFiles.length < acceptedFiles.length) {
      alert('Endast bildfiler är tillåtna (PNG, JPG, JPEG, GIF, WEBP)');
    }

    setFiles((prevFiles) => [...prevFiles, ...imageFiles]);
  };

  const handlePreviewClick = async () => {
    if (!selectedColor || files.length === 0) {
      alert('Vänligen välj en färg och lägg till bilder.');
      return;
    }

    setIsProcessing(true);

    const formData = new FormData();
    files.forEach((file) => formData.append('images', file));
    formData.append('articleNumber', productNumber);
    formData.append('colorCode', selectedColor);
    formData.append('attribute1Number', attribute1Number);
    formData.append('transparentIndices', JSON.stringify(transparentIndices));

    await handlePreview({
      articleNumber: productNumber,
      colorCode: selectedColor,
      attribute1Number: attribute1Number,
      images: files,
      transparentIndices: transparentIndices,
    });

    setIsProcessing(false);
    setFiles([]);
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    try {
      await onSubmit({
        articleNumber: productNumber,
        colorCode: selectedColor,
        attribute1Number: attribute1Number,
        images: files,
        transparentIndices: transparentIndices,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleImageClick = (url: string) => {
    setSelectedImage(url);
  };

  const handleCloseImageViewer = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div
          className="modal-content"
          style={{
            padding: '2rem',
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            margin: '50px auto',
            maxWidth: '600px',
            maxHeight: '80vh',
            overflowY: 'auto',
            position: 'relative',
            borderRadius: '24px',
            backdropFilter: 'blur(20px)',
            boxShadow:
              '0 20px 80px rgba(0, 0, 0, 0.3), 0 0 40px rgba(63, 81, 181, 0.2)',
            color: '#1A237E',
          }}
        >
          {isSaving && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                backdropFilter: 'blur(10px)',
                zIndex: 1,
                borderRadius: '24px',
              }}
            >
              <CircularProgress sx={{ color: '#3F51B5' }} />
            </div>
          )}

          <Box
            sx={{
              position: 'relative',
              mb: 3,
              '&::before': {
                content: '""',
                position: 'absolute',
                top: -32,
                left: -32,
                right: -32,
                height: '4px',
                background: 'linear-gradient(90deg, #3F51B5, #FF4081)',
              },
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                background: 'linear-gradient(135deg, #3F51B5, #FF4081)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: 700,
                letterSpacing: '-0.02em',
                marginBottom: '1rem',
              }}
            >
              Ladda upp bilder
            </Typography>

            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                color: '#455A64',
                marginBottom: '1.5rem',
              }}
            >
              Artikelnummer: {productNumber}
            </Typography>
          </Box>

          <Autocomplete
            options={availableColors}
            value={selectedColor}
            onChange={(event, newValue) => {
              setSelectedColor(newValue || '');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Färg"
                variant="outlined"
                fullWidth
                margin="normal"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.2)',
                    },
                    '&.Mui-focused': {
                      boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.3)',
                    },
                  },
                }}
              />
            )}
            sx={{ mb: 3 }}
          />

          <DropzoneArea
            onDrop={onDrop}
            files={files}
            setFiles={setFiles}
            transparentIndices={transparentIndices}
            setTransparentIndices={setTransparentIndices}
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1.5rem',
            }}
          >
            <Button
              onClick={handlePreviewClick}
              variant="outlined"
              disabled={isProcessing}
              sx={{
                borderRadius: '12px',
                padding: '0.625rem 1.5rem',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(63, 81, 181, 0.2)',
                },
                '&:active': {
                  transform: 'translateY(-1px)',
                },
              }}
            >
              {isProcessing ? 'Bearbetar...' : 'Förhandsgranska'}
            </Button>
          </div>

          {uploadedImages.length > 0 && (
            <div style={{ marginTop: '2rem' }}>
              <Typography
                variant="h6"
                sx={{
                  color: '#1A237E',
                  marginBottom: '1rem',
                  fontWeight: 600,
                }}
              >
                Bearbetade bilder:
              </Typography>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
                  gap: '1rem',
                  maxWidth: '100%',
                  overflow: 'hidden',
                }}
              >
                {uploadedImages.map((url, index) => {
                  const fileName = url.split('/').pop() || url;
                  const fullUrl = `https://mirakel-api.enghssport.com/processed/${fileName}`;
                  return (
                    <Box
                      key={index}
                      sx={{
                        position: 'relative',
                        aspectRatio: '3/4',
                        borderRadius: '16px',
                        overflow: 'hidden',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                        '&:hover': {
                          transform: 'scale(1.05) translateY(-5px)',
                          boxShadow: '0 12px 20px rgba(0, 0, 0, 0.15)',
                        },
                      }}
                    >
                      <img
                        src={fullUrl}
                        alt={`Bild ${index + 1}`}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                        onClick={() => handleImageClick(fullUrl)}
                      />
                    </Box>
                  );
                })}
              </div>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '2rem',
              gap: '1rem',
            }}
          >
            <Button
              onClick={onClose}
              sx={{
                color: '#455A64',
                padding: '0.625rem 1.5rem',
                borderRadius: '12px',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: 'rgba(63, 81, 181, 0.05)',
                },
              }}
            >
              Avbryt
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              disabled={uploadedImages.length === 0}
              sx={{
                background: 'linear-gradient(135deg, #3F51B5, #536DFE)',
                borderRadius: '12px',
                padding: '0.625rem 1.5rem',
                fontWeight: 600,
                transition: 'all 0.3s ease',
                '&:hover': {
                  background: 'linear-gradient(135deg, #3949AB, #3F51B5)',
                  transform: 'translateY(-3px)',
                  boxShadow: '0 8px 25px rgba(63, 81, 181, 0.5)',
                },
                '&:active': {
                  transform: 'translateY(-1px)',
                },
              }}
            >
              Spara
            </Button>
          </div>
        </div>
      </Modal>

      {/* Modal för förstorad bild */}
      <Modal
        open={!!selectedImage}
        onClose={handleCloseImageViewer}
        aria-labelledby="image-preview-modal"
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            padding: '2rem',
            borderRadius: '24px',
            maxWidth: '90vw',
            maxHeight: '90vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backdropFilter: 'blur(20px)',
            boxShadow:
              '0 20px 80px rgba(0, 0, 0, 0.3), 0 0 40px rgba(63, 81, 181, 0.2)',
          }}
        >
          <img
            src={selectedImage || ''}
            alt="Förstorad bild"
            style={{
              maxWidth: '100%',
              maxHeight: 'calc(90vh - 120px)',
              objectFit: 'contain',
              borderRadius: '16px',
              boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
            }}
          />
          <Button
            onClick={handleCloseImageViewer}
            variant="outlined"
            sx={{
              marginTop: '1.5rem',
              borderRadius: '12px',
              padding: '0.625rem 1.5rem',
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(63, 81, 181, 0.2)',
              },
            }}
          >
            Stäng
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UploadMediaModal;
