import React, { ReactNode } from 'react';
import { Container, Box } from '@mui/material';
import CustomAppBar from './CustomAppBar'; // Importera AppBar-komponenten

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: 'background.default',
      }}
    >
      <CustomAppBar />
      <Container
        maxWidth={false}
        sx={{
          mt: 4,
          mb: 4,
          px: { xs: 2, sm: 3, md: 4 },
          flex: 1,
        }}
      >
        {children}
      </Container>
    </Box>
  );
};

export default Layout;
