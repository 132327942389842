import React, { useContext, useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Box,
  Badge,
  Avatar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';
import DescriptionIcon from '@mui/icons-material/Description';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import GroupIcon from '@mui/icons-material/Group';
import BusinessIcon from '@mui/icons-material/Business';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import NotificationsIcon from '@mui/icons-material/Notifications';
import BuildIcon from '@mui/icons-material/Build';
import LogoutIcon from '@mui/icons-material/Logout';
import { styled } from '@mui/material/styles';
import StockNotificationsDialog from './StockNotificationsDialog';
import config from '../config';
import { StockNotification } from '../types/notifications';

const AnimatedIcon = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    fontSize: 20,
    color: theme.palette.primary.main,
    transition: 'transform 0.3s ease',
  },
  '&:hover .MuiSvgIcon-root': {
    transform: 'scale(1.2) rotate(5deg)',
  },
}));

const GlowButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    background: 'linear-gradient(90deg, transparent, #3F51B5, transparent)',
    transform: 'translateX(-100%)',
    transition: 'transform 0.4s ease',
  },
  '&:hover::after': {
    transform: 'translateX(100%)',
  },
}));

const CustomAppBar: React.FC = () => {
  const navigate = useNavigate();
  const { setToken, setPermissions, permissions } = useContext(AuthContext);
  const [anchorElProducts, setAnchorElProducts] = useState<null | HTMLElement>(
    null,
  );
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [notifications, setNotifications] = useState<StockNotification[]>([]);

  // Kontrollera om skärmen är mobil/surfplatta
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    // Hämta notifikationer när komponenten monteras
    fetchNotifications();

    // Sätt upp en intervall för att uppdatera notifikationer var 5:e minut
    const interval = setInterval(fetchNotifications, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const fetchNotifications = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        `${config.API_URL}/notifications/stock-changes`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.ok) {
        const data = await response.json();
        setNotifications(data);
      } else {
        console.error(
          'Fel vid hämtning av notifikationer:',
          await response.text(),
        );
      }
    } catch (error) {
      console.error('Fel vid hämtning av notifikationer:', error);
    }
  };

  const handleMarkAsRead = async (notificationId: number) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        `${config.API_URL}/notifications/stock-changes/${notificationId}/mark-read`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.ok) {
        setNotifications(
          notifications.filter((n) => n.notificationId !== notificationId),
        );
      } else {
        console.error(
          'Fel vid markering av notifikation som läst:',
          await response.text(),
        );
      }
    } catch (error) {
      console.error('Fel vid markering av notifikation som läst:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    setToken(null);
    setPermissions([]);
    navigate('/');
  };

  // Öppna/stäng meny för Produkter
  const handleProductsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElProducts(event.currentTarget);
  };

  const handleProductsMenuClose = () => {
    setAnchorElProducts(null);
  };

  // Navigera och stänga menyn
  const handleNavigate = (path: string) => {
    navigate(path);
    handleProductsMenuClose();
  };

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        borderBottom: '1px solid rgba(63, 81, 181, 0.1)',
      }}
    >
      <Toolbar sx={{ py: 1 }}>
        <Typography
          variant="h5"
          sx={{
            background: 'linear-gradient(135deg, #3F51B5, #FF4081)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontWeight: 700,
            flexGrow: 1,
            cursor: 'pointer',
            letterSpacing: '-0.02em',
          }}
          onClick={() => handleNavigate('/dashboard')}
        >
          MiRAKEL
        </Typography>

        {/* Om skärmen är desktop */}
        {!isMobile && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* Notifikationsikon - endast synlig för användare med manage_product_descriptions */}
            {permissions.includes('manage_product_descriptions') && (
              <IconButton
                color="primary"
                onClick={() => setNotificationsOpen(true)}
                sx={{
                  mr: 2,
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.1) rotate(15deg)',
                  },
                }}
              >
                <Badge
                  badgeContent={notifications.length}
                  color="secondary"
                  sx={{
                    '& .MuiBadge-badge': {
                      animation:
                        notifications.length > 0
                          ? 'pulse 1.5s infinite'
                          : 'none',
                    },
                  }}
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            )}

            {/* Dropdown-meny för huvudkategori Produkter */}
            <GlowButton
              color="primary"
              onClick={handleProductsMenuOpen}
              sx={{ mx: 1 }}
            >
              <AnimatedIcon>
                <InventoryIcon sx={{ mr: 1 }} />
              </AnimatedIcon>
              Produkter
            </GlowButton>
            <Menu
              anchorEl={anchorElProducts}
              open={Boolean(anchorElProducts)}
              onClose={handleProductsMenuClose}
              PaperProps={{
                elevation: 3,
                sx: {
                  mt: 1.5,
                  borderRadius: '12px',
                  overflow: 'hidden',
                  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
                  '& .MuiList-root': {
                    py: 1,
                  },
                },
              }}
            >
              {permissions.includes('manage_product_descriptions') && (
                <MenuItem
                  onClick={() => handleNavigate('/product-management')}
                  sx={{
                    py: 1.5,
                    px: 2,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: 'rgba(63, 81, 181, 0.08)',
                      transform: 'translateX(5px)',
                    },
                  }}
                >
                  <AnimatedIcon>
                    <DescriptionIcon sx={{ mr: 1.5 }} />
                  </AnimatedIcon>
                  Hantera Produkter
                </MenuItem>
              )}
              {permissions.includes('manage_prices') && (
                <MenuItem
                  onClick={() => handleNavigate('/price-change')}
                  sx={{
                    py: 1.5,
                    px: 2,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: 'rgba(63, 81, 181, 0.08)',
                      transform: 'translateX(5px)',
                    },
                  }}
                >
                  <AnimatedIcon>
                    <PriceChangeIcon sx={{ mr: 1.5 }} />
                  </AnimatedIcon>
                  Prishantering
                </MenuItem>
              )}
              {permissions.includes('manage_prices') && (
                <MenuItem
                  onClick={() => handleNavigate('/price-tags')}
                  sx={{
                    py: 1.5,
                    px: 2,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: 'rgba(63, 81, 181, 0.08)',
                      transform: 'translateX(5px)',
                    },
                  }}
                >
                  <AnimatedIcon>
                    <PriceChangeIcon sx={{ mr: 1.5 }} />
                  </AnimatedIcon>
                  Prisskyltar
                </MenuItem>
              )}
              {permissions.includes('manage_product_descriptions') && (
                <MenuItem
                  onClick={() => handleNavigate('/product-import')}
                  sx={{
                    py: 1.5,
                    px: 2,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: 'rgba(63, 81, 181, 0.08)',
                      transform: 'translateX(5px)',
                    },
                  }}
                >
                  <AnimatedIcon>
                    <CloudUploadIcon sx={{ mr: 1.5 }} />
                  </AnimatedIcon>
                  Produktimport
                </MenuItem>
              )}
              <MenuItem
                onClick={() => handleNavigate('/sales')}
                sx={{
                  py: 1.5,
                  px: 2,
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    backgroundColor: 'rgba(63, 81, 181, 0.08)',
                    transform: 'translateX(5px)',
                  },
                }}
              >
                <AnimatedIcon>
                  <ShowChartIcon sx={{ mr: 1.5 }} />
                </AnimatedIcon>
                Försäljning
              </MenuItem>
            </Menu>
            {permissions.includes('manage_users') && (
              <GlowButton
                color="primary"
                onClick={() => handleNavigate('/user-management')}
                sx={{ mx: 1 }}
              >
                <AnimatedIcon>
                  <GroupIcon sx={{ mr: 1 }} />
                </AnimatedIcon>
                Användarhantering
              </GlowButton>
            )}
            {permissions.includes('manage_companies') && (
              <GlowButton
                color="primary"
                onClick={() => handleNavigate('/jobs')}
                sx={{ mx: 1 }}
              >
                <AnimatedIcon>
                  <BuildIcon sx={{ mr: 1 }} />
                </AnimatedIcon>
                Systemjobb
              </GlowButton>
            )}

            <Button
              variant="outlined"
              color="primary"
              onClick={handleLogout}
              startIcon={<LogoutIcon />}
              sx={{
                ml: 2,
                borderRadius: '8px',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              Logga ut
            </Button>
          </Box>
        )}

        {/* Om skärmen är mobil/surfplatta, visa hamburgermeny */}
        {isMobile && (
          <>
            {/* Notifikationsikon för mobil */}
            {permissions.includes('manage_product_descriptions') && (
              <IconButton
                color="primary"
                onClick={() => setNotificationsOpen(true)}
                sx={{
                  mr: 2,
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.1) rotate(15deg)',
                  },
                }}
              >
                <Badge
                  badgeContent={notifications.length}
                  color="secondary"
                  sx={{
                    '& .MuiBadge-badge': {
                      animation:
                        notifications.length > 0
                          ? 'pulse 1.5s infinite'
                          : 'none',
                    },
                  }}
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            )}

            <IconButton
              edge="start"
              color="primary"
              aria-label="menu"
              onClick={handleProductsMenuOpen}
              sx={{
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.1) rotate(5deg)',
                },
              }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElProducts}
              open={Boolean(anchorElProducts)}
              onClose={handleProductsMenuClose}
              PaperProps={{
                elevation: 3,
                sx: {
                  mt: 1.5,
                  borderRadius: '12px',
                  overflow: 'hidden',
                  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
                  '& .MuiList-root': {
                    py: 1,
                  },
                },
              }}
            >
              {/* Menyalternativ för mobila enheter */}
              {permissions.includes('manage_product_descriptions') && (
                <MenuItem
                  onClick={() => handleNavigate('/product-management')}
                  sx={{
                    py: 1.5,
                    px: 2,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: 'rgba(63, 81, 181, 0.08)',
                      transform: 'translateX(5px)',
                    },
                  }}
                >
                  <AnimatedIcon>
                    <DescriptionIcon sx={{ mr: 1.5 }} />
                  </AnimatedIcon>
                  Hantera Produkter
                </MenuItem>
              )}
              {permissions.includes('manage_prices') && (
                <MenuItem
                  onClick={() => handleNavigate('/price-change')}
                  sx={{
                    py: 1.5,
                    px: 2,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: 'rgba(63, 81, 181, 0.08)',
                      transform: 'translateX(5px)',
                    },
                  }}
                >
                  <AnimatedIcon>
                    <PriceChangeIcon sx={{ mr: 1.5 }} />
                  </AnimatedIcon>
                  Prisändringar
                </MenuItem>
              )}
              {permissions.includes('manage_users') && (
                <MenuItem
                  onClick={() => handleNavigate('/user-management')}
                  sx={{
                    py: 1.5,
                    px: 2,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: 'rgba(63, 81, 181, 0.08)',
                      transform: 'translateX(5px)',
                    },
                  }}
                >
                  <AnimatedIcon>
                    <GroupIcon sx={{ mr: 1.5 }} />
                  </AnimatedIcon>
                  Användarhantering
                </MenuItem>
              )}
              <MenuItem
                onClick={() => handleNavigate('/sales')}
                sx={{
                  py: 1.5,
                  px: 2,
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    backgroundColor: 'rgba(63, 81, 181, 0.08)',
                    transform: 'translateX(5px)',
                  },
                }}
              >
                <AnimatedIcon>
                  <ShowChartIcon sx={{ mr: 1.5 }} />
                </AnimatedIcon>
                Försäljning
              </MenuItem>
              <MenuItem
                onClick={handleLogout}
                sx={{
                  py: 1.5,
                  px: 2,
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 64, 129, 0.08)',
                    transform: 'translateX(5px)',
                  },
                }}
              >
                <AnimatedIcon>
                  <LogoutIcon
                    sx={{ mr: 1.5, color: theme.palette.secondary.main }}
                  />
                </AnimatedIcon>
                Logga ut
              </MenuItem>
            </Menu>
          </>
        )}

        {/* Notifikationsdialog */}
        <StockNotificationsDialog
          open={notificationsOpen}
          onClose={() => setNotificationsOpen(false)}
          notifications={notifications}
          onMarkAsRead={handleMarkAsRead}
        />
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
