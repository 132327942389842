import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  SelectChangeEvent,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import config from '../config';
import axios from 'axios';

// Interface för prislappstyper
interface PriceTagType {
  id: string;
  name: string;
  description: string;
}

// Interface för produkt som ska visas på prislapp
interface PriceTagProduct {
  productId: number;
  productNumber: string;
  productName: string;
  originalPrice: number;
  salePrice: number;
  selected: boolean;
  instanceId: string; // Unique identifier for each instance
}

// Huvudkomponent för prisskyltsskapande
const PriceTagCreator: React.FC = () => {
  // State för prislappstyper och valda produkter
  const [priceTagTypes, setPriceTagTypes] = useState<PriceTagType[]>([
    { id: 'shoe', name: 'Skoskylt', description: 'Prisskylt för skor' },
  ]);
  const [selectedType, setSelectedType] = useState<string>('shoe');
  const [products, setProducts] = useState<PriceTagProduct[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState<PriceTagProduct[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'error' | 'info' | 'warning'
  >('info');
  const [manualProduct, setManualProduct] = useState<{
    productNumber: string;
    productName: string;
    originalPrice: string;
    salePrice: string;
  }>({
    productNumber: '',
    productName: '',
    originalPrice: '',
    salePrice: '',
  });

  // Hantera val av prislappstyp
  const handleTypeChange = (event: SelectChangeEvent) => {
    setSelectedType(event.target.value);
  };

  // Sök efter produkter
  const searchProducts = async () => {
    if (!searchTerm.trim()) {
      setSearchResults([]);
      return;
    }

    setIsLoading(true);
    try {
      // Använd samma API-endpoint som i PriceChangeModule
      const url = `${config.API_URL}/products?search=${encodeURIComponent(searchTerm)}&page=1&pageSize=10`;
      console.log('Söker produkter med URL:', url);

      const response = await fetch(url);

      if (!response.ok) {
        console.error(
          'Server responded with an error:',
          response.status,
          response.statusText,
        );
        throw new Error('Kunde inte söka efter produkter');
      }

      const data = await response.json();
      console.log('Produktsökningsresultat:', data);

      // Hämta prisinformation för varje produkt
      const productsWithPrices = await Promise.all(
        data.products.map(async (product: any) => {
          try {
            const skuUrl = `${config.API_URL}/products/${product.productId}/skus`;
            console.log(
              `Hämtar SKUs för produkt ${product.productId} från ${skuUrl}`,
            );

            const skuResponse = await fetch(skuUrl);

            if (!skuResponse.ok) {
              console.error(
                'Error fetching SKUs for product:',
                product.productId,
                skuResponse.status,
                skuResponse.statusText,
              );
              return {
                productId: product.productId,
                productNumber: product.productNumber,
                productName: product.productName,
                originalPrice: 0,
                salePrice: 0,
                selected: false,
                instanceId: '',
              };
            }

            const skuData = await skuResponse.json();
            console.log(`SKU-data för produkt ${product.productId}:`, skuData);

            // Använd första SKU:n för att få prisinformation
            // Detta är en förenkling - i verkligheten kan en produkt ha flera SKU:er med olika priser
            if (skuData && skuData.length > 0) {
              const firstSku = skuData[0];
              console.log(
                `Första SKU för produkt ${product.productId}:`,
                firstSku,
              );

              // Konvertera priserna från strängar till nummer
              // Använd parseFloat för att konvertera strängarna till nummer
              const originalPrice = parseFloat(firstSku.unitPriceOriginal) || 0;
              const salePrice = parseFloat(firstSku.unitPrice) || 0;

              console.log(`Priser för produkt ${product.productId}:`, {
                originalPrice,
                salePrice,
                rawOriginalPrice: firstSku.unitPriceOriginal,
                rawSalePrice: firstSku.unitPrice,
                typeOfOriginalPrice: typeof firstSku.unitPriceOriginal,
                typeOfSalePrice: typeof firstSku.unitPrice,
              });

              return {
                productId: product.productId,
                productNumber: product.productNumber,
                productName: product.productName,
                originalPrice,
                salePrice,
                selected: false,
                instanceId: '',
              };
            } else {
              console.log(
                `Inga SKUs hittades för produkt ${product.productId}`,
              );
              return {
                productId: product.productId,
                productNumber: product.productNumber,
                productName: product.productName,
                originalPrice: 0,
                salePrice: 0,
                selected: false,
                instanceId: '',
              };
            }
          } catch (error) {
            console.error(
              'Error fetching SKUs for product:',
              product.productId,
              error,
            );
            return {
              productId: product.productId,
              productNumber: product.productNumber,
              productName: product.productName,
              originalPrice: 0,
              salePrice: 0,
              selected: false,
              instanceId: '',
            };
          }
        }),
      );

      console.log('Produkter med priser:', productsWithPrices);
      setSearchResults(productsWithPrices);
    } catch (error) {
      console.error('Fel vid sökning av produkter:', error);
      setSnackbarMessage('Kunde inte söka efter produkter');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  // Lägg till produkt från sökresultat
  const addProductFromSearch = (product: PriceTagProduct) => {
    const instanceId = `${product.productId}-${Date.now()}`; // Create unique instance ID
    setProducts([...products, { ...product, selected: true, instanceId }]);
    setSnackbarMessage('Produkt tillagd');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };

  // Lägg till manuellt inmatad produkt
  const addManualProduct = () => {
    // Validera inmatning
    if (
      !manualProduct.productNumber.trim() ||
      !manualProduct.productName.trim()
    ) {
      setSnackbarMessage('Artikelnummer och produktnamn måste anges');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const originalPrice = parseFloat(manualProduct.originalPrice) || 0;
    const salePrice = parseFloat(manualProduct.salePrice) || 0;

    const newProduct: PriceTagProduct = {
      productId: -Date.now(), // Använd negativt timestamp som tillfälligt ID
      productNumber: manualProduct.productNumber,
      productName: manualProduct.productName,
      originalPrice,
      salePrice,
      selected: true,
      instanceId: `manual-${Date.now()}`, // Add unique instance ID for manual products
    };

    setProducts([...products, newProduct]);

    // Återställ formuläret
    setManualProduct({
      productNumber: '',
      productName: '',
      originalPrice: '',
      salePrice: '',
    });

    setSnackbarMessage('Produkt tillagd');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };

  // Ta bort produkt från listan
  const removeProduct = (instanceId: string) => {
    setProducts(products.filter((p) => p.instanceId !== instanceId));
    setSnackbarMessage('Produkt borttagen');
    setSnackbarSeverity('info');
    setSnackbarOpen(true);
  };

  // Växla markering av produkt
  const toggleProductSelection = (instanceId: string) => {
    setProducts(
      products.map((p) =>
        p.instanceId === instanceId ? { ...p, selected: !p.selected } : p,
      ),
    );
  };

  // Markera/avmarkera alla produkter
  const toggleAllProducts = (selected: boolean) => {
    setProducts(products.map((p) => ({ ...p, selected })));
  };

  // Generera prisskyltar
  const generatePriceTags = async () => {
    const selectedProducts = products.filter((p) => p.selected);

    if (selectedProducts.length === 0) {
      setSnackbarMessage('Välj minst en produkt');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    if (!selectedType) {
      setSnackbarMessage('Välj en typ av prisskylt');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setIsGenerating(true);
    try {
      // Förbered data för API-anrop
      const requestData = {
        priceTagType: selectedType,
        products: selectedProducts.map((p) => ({
          productNumber: p.productNumber,
          productName: p.productName,
          originalPrice: p.originalPrice,
          salePrice: p.salePrice,
        })),
      };

      // Anropa backend för att generera PDF
      const response = await axios.post(
        `${config.API_URL}/price-tags/generate`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          responseType: 'blob', // Viktigt för att hantera binärdata (PDF)
        },
      );

      // Skapa en blob-URL för PDF-filen
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      // Skapa en länk och klicka på den för att ladda ner filen
      const a = document.createElement('a');
      a.href = url;
      a.download = `prisskyltar_${new Date().toISOString().split('T')[0]}.pdf`;
      document.body.appendChild(a);
      a.click();

      // Rensa upp
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      setSnackbarMessage('Prisskyltar genererade och nedladdade');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Fel vid generering av prisskyltar:', error);
      setSnackbarMessage('Kunde inte generera prisskyltar');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsGenerating(false);
    }
  };

  // Hantera stängning av snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Hantera ändringar i manuell produktinmatning
  const handleManualProductChange = (field: string, value: string) => {
    setManualProduct({
      ...manualProduct,
      [field]: value,
    });
  };

  // Hantera ändring av pris för vald produkt
  const handlePriceChange = (
    instanceId: string,
    field: 'originalPrice' | 'salePrice',
    value: string,
  ) => {
    const newValue = parseFloat(value) || 0;
    setProducts(
      products.map((p) =>
        p.instanceId === instanceId ? { ...p, [field]: newValue } : p,
      ),
    );
  };

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Skapa prisskyltar
        </Typography>

        {/* Val av prislappstyp */}
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Välj typ av prisskylt
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="price-tag-type-label">Prislappstyp</InputLabel>
            <Select
              labelId="price-tag-type-label"
              id="price-tag-type"
              value={selectedType}
              label="Prislappstyp"
              onChange={handleTypeChange}
            >
              {priceTagTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.name} - {type.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Paper>

        {/* Produktsökning */}
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Sök efter produkter
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={9}>
              <TextField
                fullWidth
                label="Sök på artikelnummer eller produktnamn"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && searchProducts()}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="contained"
                onClick={searchProducts}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Sök'}
              </Button>
            </Grid>
          </Grid>

          {/* Sökresultat */}
          {searchResults.length > 0 && (
            <Box mt={3}>
              <Typography variant="subtitle1" gutterBottom>
                Sökresultat
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Artikelnr</TableCell>
                      <TableCell>Produktnamn</TableCell>
                      <TableCell align="right">Ord. pris</TableCell>
                      <TableCell align="right">Förs. pris</TableCell>
                      <TableCell align="center">Åtgärd</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchResults.map((product) => (
                      <TableRow key={product.productId}>
                        <TableCell>{product.productNumber}</TableCell>
                        <TableCell>{product.productName}</TableCell>
                        <TableCell align="right">
                          {typeof product.originalPrice === 'number'
                            ? product.originalPrice.toFixed(2)
                            : '0.00'}
                        </TableCell>
                        <TableCell align="right">
                          {typeof product.salePrice === 'number'
                            ? product.salePrice.toFixed(2)
                            : '0.00'}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={() => addProductFromSearch(product)}
                          >
                            Lägg till
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Paper>

        {/* Manuell inmatning av produkt */}
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Lägg till produkt manuellt
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Artikelnummer"
                value={manualProduct.productNumber}
                onChange={(e) =>
                  handleManualProductChange('productNumber', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Produktnamn"
                value={manualProduct.productName}
                onChange={(e) =>
                  handleManualProductChange('productName', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Ordinarie pris"
                type="number"
                value={manualProduct.originalPrice}
                onChange={(e) =>
                  handleManualProductChange('originalPrice', e.target.value)
                }
                InputProps={{ inputProps: { min: 0, step: 0.01 } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Försäljningspris"
                type="number"
                value={manualProduct.salePrice}
                onChange={(e) =>
                  handleManualProductChange('salePrice', e.target.value)
                }
                InputProps={{ inputProps: { min: 0, step: 0.01 } }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={addManualProduct}
              >
                Lägg till produkt
              </Button>
            </Grid>
          </Grid>
        </Paper>

        {/* Lista över valda produkter */}
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h6">
              Valda produkter ({products.filter((p) => p.selected).length} av{' '}
              {products.length})
            </Typography>
            <Box>
              <Button
                size="small"
                onClick={() => toggleAllProducts(true)}
                sx={{ mr: 1 }}
              >
                Markera alla
              </Button>
              <Button size="small" onClick={() => toggleAllProducts(false)}>
                Avmarkera alla
              </Button>
            </Box>
          </Box>

          {products.length > 0 ? (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Box
                        sx={{
                          position: 'absolute',
                          width: '1px',
                          height: '1px',
                          padding: 0,
                          margin: '-1px',
                          overflow: 'hidden',
                          clip: 'rect(0, 0, 0, 0)',
                          whiteSpace: 'nowrap',
                          borderWidth: 0,
                        }}
                      >
                        Välj
                      </Box>
                    </TableCell>
                    <TableCell>Artikelnr</TableCell>
                    <TableCell>Produktnamn</TableCell>
                    <TableCell align="right">Ord. pris</TableCell>
                    <TableCell align="right">Förs. pris</TableCell>
                    <TableCell align="center">Åtgärd</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((product) => (
                    <TableRow key={product.instanceId}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={product.selected}
                          onChange={() =>
                            toggleProductSelection(product.instanceId)
                          }
                        />
                      </TableCell>
                      <TableCell>{product.productNumber}</TableCell>
                      <TableCell>{product.productName}</TableCell>
                      <TableCell align="right">
                        <TextField
                          size="small"
                          type="number"
                          value={product.originalPrice}
                          onChange={(e) =>
                            handlePriceChange(
                              product.instanceId,
                              'originalPrice',
                              e.target.value,
                            )
                          }
                          InputProps={{
                            inputProps: { min: 0, step: 0.01 },
                            sx: { width: '100px' },
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <TextField
                          size="small"
                          type="number"
                          value={product.salePrice}
                          onChange={(e) =>
                            handlePriceChange(
                              product.instanceId,
                              'salePrice',
                              e.target.value,
                            )
                          }
                          InputProps={{
                            inputProps: { min: 0, step: 0.01 },
                            sx: { width: '100px' },
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          color="error"
                          onClick={() => removeProduct(product.instanceId)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body2" color="textSecondary" align="center">
              Inga produkter valda. Sök efter produkter eller lägg till
              manuellt.
            </Typography>
          )}
        </Paper>

        {/* Generera prisskyltar */}
        <Box display="flex" justifyContent="center" mt={4}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<PictureAsPdfIcon />}
            onClick={generatePriceTags}
            disabled={
              isGenerating ||
              products.filter((p) => p.selected).length === 0 ||
              !selectedType
            }
          >
            {isGenerating ? (
              <>
                <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                Genererar...
              </>
            ) : (
              'Generera prisskyltar'
            )}
          </Button>
        </Box>
      </Box>

      {/* Snackbar för meddelanden */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PriceTagCreator;
