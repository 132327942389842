import React, { useState, useContext, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import config from '../config';

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const { setToken, setPermissions } = useContext(AuthContext);

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${config.API_URL}/auth/login`, {
        email,
        password,
      });
      const token = response.data.token;
      const userPermissions = response.data.permissions;

      localStorage.setItem('token', token);
      localStorage.setItem('permissions', JSON.stringify(userPermissions));
      setToken(token);
      setPermissions(userPermissions);
      navigate('/dashboard');
    } catch (err: any) {
      setError(err.response?.data?.message || 'Login failed');
    }
  };

  // WebGL animation and canvas logic
  useEffect(() => {
    const canvas = document.querySelector('canvas')!;
    const gl = canvas.getContext('webgl');

    if (!gl) {
      console.error(
        'Unable to initialize WebGL. Your browser may not support it.',
      );
      return;
    }

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    gl.viewport(0, 0, canvas.width, canvas.height);

    const config = {
      particleCount: 8000,
      text: 'MiRAKEL',
      particleSize: 1.8,
      forceMultiplier: 0.001,
      returnSpeed: 0.005,
      velocityDamping: 0.85,
      colorMultiplier: 40000,
      saturationMultiplier: 1000,
      rotationForceMultiplier: 0.5,
      colors: ['#3F51B5', '#536DFE', '#FF4081'],
    };

    let textCoordinates = [];
    let time = 0;

    const particles = Array.from({ length: config.particleCount }, () => ({
      x: 0,
      y: 0,
      baseX: 0,
      baseY: 0,
      vx: 0,
      vy: 0,
    }));

    const vertexShaderSource = `
            attribute vec2 a_position;
            attribute float a_hue;
            attribute float a_saturation;
            varying float v_hue;
            varying float v_saturation;
            void main() {
                gl_PointSize = ${config.particleSize.toFixed(1)};
                gl_Position = vec4(a_position, 0.0, 1.0);
                v_hue = a_hue;
                v_saturation = a_saturation;
            }
        `;

    const fragmentShaderSource = `
            precision mediump float;
            varying float v_hue;
            varying float v_saturation;
            void main() {
                float c = v_hue * 6.0;
                float x = 1.0 - abs(mod(c, 2.0) - 1.0);
                vec3 color;
                if (c < 1.0) color = vec3(1.0, x, 0.0);
                else if (c < 2.0) color = vec3(x, 1.0, 0.0);
                else if (c < 3.0) color = vec3(0.0, 1.0, x);
                else if (c < 4.0) color = vec3(0.0, x, 1.0);
                else if (c < 5.0) color = vec3(x, 0.0, 1.0);
                else color = vec3(1.0, 0.0, x);
                vec3 finalColor = mix(vec3(1.0), color, v_saturation);
                gl_FragColor = vec4(finalColor, 1.0);
            }
        `;

    function createShader(
      gl: WebGLRenderingContext,
      type: number,
      source: string,
    ) {
      const shader = gl.createShader(type);
      if (!shader) {
        console.error('Error creating shader.');
        return null;
      }
      gl.shaderSource(shader, source);
      gl.compileShader(shader);
      if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.error(gl.getShaderInfoLog(shader));
        gl.deleteShader(shader);
        return null;
      }
      return shader;
    }

    function createProgram(
      gl: WebGLRenderingContext,
      vertexShader: WebGLShader,
      fragmentShader: WebGLShader,
    ) {
      const program = gl.createProgram();
      if (!program) {
        console.error('Error creating program.');
        return null;
      }
      gl.attachShader(program, vertexShader);
      gl.attachShader(program, fragmentShader);
      gl.linkProgram(program);
      if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
        console.error(gl.getProgramInfoLog(program));
        gl.deleteProgram(program);
        return null;
      }
      return program;
    }

    const vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
    if (!vertexShader) {
      console.error('Failed to create vertex shader');
      return;
    }

    const fragmentShader = createShader(
      gl,
      gl.FRAGMENT_SHADER,
      fragmentShaderSource,
    );
    if (!fragmentShader) {
      console.error('Failed to create fragment shader');
      return;
    }

    const program = createProgram(gl, vertexShader, fragmentShader);
    if (!program) {
      console.error('Failed to create program');
      return;
    }

    const positionAttributeLocation = gl.getAttribLocation(
      program,
      'a_position',
    );
    const hueAttributeLocation = gl.getAttribLocation(program, 'a_hue');
    const saturationAttributeLocation = gl.getAttribLocation(
      program,
      'a_saturation',
    );

    const positionBuffer = gl.createBuffer();
    const hueBuffer = gl.createBuffer();
    const saturationBuffer = gl.createBuffer();

    const positions = new Float32Array(config.particleCount * 2);
    const hues = new Float32Array(config.particleCount);
    const saturations = new Float32Array(config.particleCount);

    function getTextCoordinates(text: string) {
      const ctx = document.createElement('canvas').getContext('2d');
      ctx!.canvas.width = canvas.width;
      ctx!.canvas.height = canvas.height;
      const fontSize = Math.min(canvas.width / 4.5, canvas.height / 4.5);
      ctx!.font = `900 ${fontSize}px Arial`;
      ctx!.fillStyle = 'white';
      ctx!.textAlign = 'center';
      ctx!.textBaseline = 'middle';
      ctx!.fillText(text, canvas.width / 2, canvas.height / 2);
      const imageData = ctx!.getImageData(
        0,
        0,
        canvas.width,
        canvas.height,
      ).data;
      const coordinates = [];
      for (let y = 0; y < canvas.height; y += 4) {
        for (let x = 0; x < canvas.width; x += 4) {
          const index = (y * canvas.width + x) * 4;
          if (imageData[index + 3] > 128) {
            coordinates.push({
              x: (x / canvas.width) * 2 - 1,
              y: (y / canvas.height) * -2 + 1,
            });
          }
        }
      }
      return coordinates;
    }

    function createParticles() {
      textCoordinates = getTextCoordinates(config.text);
      for (let i = 0; i < config.particleCount; i++) {
        const randomIndex = Math.floor(Math.random() * textCoordinates.length);
        const { x, y } = textCoordinates[randomIndex];
        particles[i].x = Math.random() * 2 - 1;
        particles[i].y = Math.random() * 2 - 1;
        particles[i].baseX = x;
        particles[i].baseY = y;
      }
    }

    function updateParticles() {
      time += 0.01;
      const offsetX = Math.sin(time) * 0.01;
      const offsetY = Math.cos(time) * 0.01;

      for (let i = 0; i < config.particleCount; i++) {
        const particle = particles[i];

        // Add some random movement occasionally
        if (Math.random() < 0.01) {
          particle.vx += (Math.random() - 0.5) * 0.02;
          particle.vy += (Math.random() - 0.5) * 0.02;
        }

        // Move towards base position with offset
        particle.vx +=
          (particle.baseX + offsetX - particle.x) * config.returnSpeed;
        particle.vy +=
          (particle.baseY + offsetY - particle.y) * config.returnSpeed;

        particle.x += particle.vx;
        particle.y += particle.vy;
        particle.vx *= config.velocityDamping;
        particle.vy *= config.velocityDamping;

        const speed = Math.sqrt(
          particle.vx * particle.vx + particle.vy * particle.vy,
        );
        const hue = (speed * config.colorMultiplier) % 360;

        hues[i] = hue / 360;
        saturations[i] = Math.min(speed * config.saturationMultiplier, 1);
        positions[i * 2] = particle.x;
        positions[i * 2 + 1] = particle.y;
      }

      if (gl) {
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, positions, gl.DYNAMIC_DRAW);
        gl.bindBuffer(gl.ARRAY_BUFFER, hueBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, hues, gl.DYNAMIC_DRAW);
        gl.bindBuffer(gl.ARRAY_BUFFER, saturationBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, saturations, gl.DYNAMIC_DRAW);
      }
    }

    function animate() {
      updateParticles();

      if (gl) {
        gl.clear(gl.COLOR_BUFFER_BIT);
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
        gl.vertexAttribPointer(
          positionAttributeLocation,
          2,
          gl.FLOAT,
          false,
          0,
          0,
        );
        gl.enableVertexAttribArray(positionAttributeLocation);
        gl.bindBuffer(gl.ARRAY_BUFFER, hueBuffer);
        gl.vertexAttribPointer(hueAttributeLocation, 1, gl.FLOAT, false, 0, 0);
        gl.enableVertexAttribArray(hueAttributeLocation);
        gl.bindBuffer(gl.ARRAY_BUFFER, saturationBuffer);
        gl.vertexAttribPointer(
          saturationAttributeLocation,
          1,
          gl.FLOAT,
          false,
          0,
          0,
        );
        gl.enableVertexAttribArray(saturationAttributeLocation);
        gl.useProgram(program);
        gl.drawArrays(gl.POINTS, 0, config.particleCount);
      }
      requestAnimationFrame(animate);
    }

    window.addEventListener('resize', () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      if (gl) {
        gl.viewport(0, 0, canvas.width, canvas.height);
      }
      createParticles();
    });

    if (gl) {
      gl.clearColor(0, 0, 0, 1);
    }
    createParticles();
    animate();

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  // Lägg till funktionen att logga in med Enter-tangenten
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: '#070B34',
      }}
    >
      <canvas
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
        }}
      />
      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            width: '100%',
            p: 5,
            borderRadius: '24px',
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            backdropFilter: 'blur(20px)',
            boxShadow:
              '0 20px 80px rgba(0, 0, 0, 0.3), 0 0 40px rgba(63, 81, 181, 0.2)',
            textAlign: 'center',
            position: 'relative',
            overflow: 'hidden',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '4px',
              background: 'linear-gradient(90deg, #3F51B5, #FF4081)',
            },
            animation: 'fadeIn 0.8s ease-out',
          }}
        >
          <Typography
            variant="h3"
            component="h1"
            sx={{
              mb: 4,
              fontWeight: 700,
              background: 'linear-gradient(135deg, #3F51B5, #FF4081)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              letterSpacing: '-0.02em',
            }}
          >
            MiRAKEL
          </Typography>
          <Box
            component="form"
            sx={{
              mt: 3,
              '& .MuiTextField-root': {
                mb: 3,
              },
            }}
          >
            <TextField
              fullWidth
              label="E-post"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyDown}
              InputProps={{
                sx: {
                  borderRadius: '12px',
                  '&:hover': {
                    boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.2)',
                  },
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.3)',
                  },
                },
              }}
            />
            <TextField
              fullWidth
              label="Lösenord"
              type="password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}
              InputProps={{
                sx: {
                  borderRadius: '12px',
                  '&:hover': {
                    boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.2)',
                  },
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.3)',
                  },
                },
              }}
            />
            {error && (
              <Typography
                color="error"
                sx={{
                  mt: 2,
                  mb: 2,
                  textAlign: 'center',
                  padding: '10px',
                  borderRadius: '8px',
                  backgroundColor: 'rgba(255, 61, 0, 0.08)',
                  animation: 'pulse 1.5s infinite',
                }}
              >
                {error}
              </Typography>
            )}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleLogin}
              sx={{
                mt: 2,
                mb: 3,
                py: 1.5,
                borderRadius: '12px',
                fontSize: '1rem',
                fontWeight: 600,
                background: 'linear-gradient(135deg, #3F51B5, #536DFE)',
                boxShadow: '0 4px 20px rgba(63, 81, 181, 0.4)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 8px 25px rgba(63, 81, 181, 0.5)',
                  background: 'linear-gradient(135deg, #3949AB, #3F51B5)',
                },
                '&:active': {
                  transform: 'translateY(-1px)',
                },
              }}
            >
              Logga in
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default LoginPage;
