// ProductList.tsx
import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Container,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  Chip,
  IconButton,
  CircularProgress,
  Box,
  ImageList,
  Grid,
  Alert,
  Snackbar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  TableContainer,
} from '@mui/material';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import axios from 'axios';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SmartImage from '../components/SmartImage';
import UploadMediaModal from '../components/UploadMediaModal';
import config from '../config';
import FilterListIcon from '@mui/icons-material/FilterList';
import DescriptionIcon from '@mui/icons-material/Description';
import CategoryIcon from '@mui/icons-material/Category';
import ImageIcon from '@mui/icons-material/Image';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import InventoryIcon from '@mui/icons-material/Inventory';
import WarningIcon from '@mui/icons-material/Warning';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

interface Product {
  productId: number;
  productNumber: string;
  productName: string;
  hasCategories: number;
  hasValidDescription: number;
  totalColors: number;
  colorsWithImages: number;
  longDescription?: string;
  brandName?: string;
  stockByColor?: {
    [key: string]: StockInfo;
  };
  keywords?: string[];
  mediaUrl?: string;
}

interface Category {
  categoryId: number;
  categoryName: string;
  parentCategoryId: number | null;
  children?: Category[];
}

interface CategoryOption {
  categoryId: number;
  categoryName: string;
  fullPath: string;
  parentCategoryId: number | null;
  depth: number;
}

interface FlatCategory {
  categoryId: number;
  categoryName: string;
  parentCategoryId: number | null;
  level: number;
  displayName: string;
  fullPath: string;
}

interface SKU {
  skuId: number;
  priceLookupUnitNumber: string;
  attribute1Code: string;
  attribute1Number: string;
  missingPrice?: boolean;
}

interface UploadData {
  articleNumber: string;
  colorCode: string;
  attribute1Number: string;
  images: File[];
  transparentIndices: number[];
}

interface StockInfo {
  attribute1Code: string;
  attribute1Number: string;
  stockBalance: number;
}

interface SKUPrice {
  skuId: number;
  priceLookupUnitNumber: string;
  attribute1Code: string;
  unitPrice: number; // Svenska priset (bakåtkompatibilitet)
  unitPriceOriginal: number; // Svenska originalpriset (bakåtkompatibilitet)
  taxRate: number;
  seUnitPrice?: number; // Svenska priser
  seUnitPriceOriginal?: number; // Svenska originalpriser
  dkUnitPrice?: number; // Danska priser
  dkUnitPriceOriginal?: number; // Danska originalpriser
}

interface Badge {
  categoryId: number;
  categoryName: string;
  parentCategoryId: number | null;
  children?: any[];
}

// Lägg till interface för varumärken
interface Brand {
  brandId: number;
  brandName: string;
  supplierId: number;
  supplierName: string;
}

const ExpandMore = styled((props: any) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ProductList: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(0); // zero-based index
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [longDescriptionFilter, setLongDescriptionFilter] = useState(false);
  const [noCategoryFilter, setNoCategoryFilter] = useState(false);
  const [noImageFilter, setNoImageFilter] = useState(false);
  const [partialImageFilter, setPartialImageFilter] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [productDescription, setProductDescription] = useState('');
  const [categoryOptions, setCategoryOptions] = useState<CategoryOption[]>([]);
  const [categoryMap, setCategoryMap] = useState<{
    [key: number]: CategoryOption;
  }>({});
  const [parentMap, setParentMap] = useState<{ [key: number]: number | null }>(
    {},
  );
  const [selectedCategories, setSelectedCategories] = useState<
    CategoryOption[]
  >([]);
  const [productCategoryIds, setProductCategoryIds] = useState<number[]>([]);
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  );
  const [isGenerating, setIsGenerating] = useState(false);
  const [productMedia, setProductMedia] = useState<{
    [attribute1Number: string]: string[];
  }>({});
  const [skus, setSkus] = useState<SKU[]>([]);

  // Lägg till state för tillgängliga färger
  const [availableColors, setAvailableColors] = useState<string[]>([]);

  // State för Upload Media Modal
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedSku, setSelectedSku] = useState<SKU | null>(null);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  // Lägg till ny state-variabel
  const [noBrandInDescFilter, setNoBrandInDescFilter] = useState(false);

  // Lägg till state för expand/collapse
  const [expanded, setExpanded] = useState(false);

  // Lägg till nya state-variabler
  const [noStockFilter, setNoStockFilter] = useState(false);
  const [partialStockFilter, setPartialStockFilter] = useState(false);

  // Lägg till ny state-variabel
  const [isSaving, setIsSaving] = useState(false);

  // Lägg till ny state-variabel
  const [noDescriptionFilter, setNoDescriptionFilter] = useState(false);

  const [activeTab, setActiveTab] = useState(0);

  // Lägg till nya states för prishantering
  const [priceInputs, setPriceInputs] = useState<{
    [skuId: number]: { unitPrice: string; unitPriceOriginal: string };
  }>({});
  const [discountInput, setDiscountInput] = useState<{
    [key: string]: string;
  }>({});
  const [skuPrices, setSkuPrices] = useState<SKUPrice[]>([]);

  // Lägg till nya states för valutahantering
  const [selectedCurrencies, setSelectedCurrencies] = useState<{
    [skuId: number]: string[];
  }>({});
  const [currencyPriceInputs, setCurrencyPriceInputs] = useState<{
    [skuId: number]: {
      [currencyCode: string]: {
        unitPrice: string;
        unitPriceOriginal: string;
      };
    };
  }>({});
  const [showCurrencyHelpMessage, setShowCurrencyHelpMessage] = useState<{
    [skuId: number]: boolean;
  }>({});

  // Lägg till dessa nya states
  const [badges, setBadges] = useState<Badge[]>([]);
  const [selectedBadges, setSelectedBadges] = useState<Badge[]>([]);
  const [productBadgeIds, setProductBadgeIds] = useState<number[]>([]);

  // Lägg till ny state-variabel
  const [hasStockFilter, setHasStockFilter] = useState(false);

  // Lägg till ny state-variabel för aktiva produkter
  const [activeFilter, setActiveFilter] = useState<boolean | null>(null);

  // Lägg till state-variabler för varumärken
  const [brands, setBrands] = useState<Brand[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<string>('');

  // Lägg till state-variabel för vald kategori
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null,
  );
  const [flatCategories, setFlatCategories] = useState<FlatCategory[]>([]);

  // Lägg till funktion för att beräkna rabattprocent
  const calculateDiscountPercentage = (
    originalPrice: number,
    currentPrice: number,
  ): string => {
    if (originalPrice <= 0 || currentPrice >= originalPrice) return '';
    const discount = ((originalPrice - currentPrice) / originalPrice) * 100;
    return discount.toFixed(0);
  };

  // Uppdatera useEffect för att sätta initiala priser och rabatter
  useEffect(() => {
    if (skuPrices.length > 0) {
      // Sätt priser
      const initialPriceInputs: {
        [skuId: number]: { unitPrice: string; unitPriceOriginal: string };
      } = {};
      const initialDiscountInputs: { [skuId: number]: string } = {};
      const initialCurrencyInputs: {
        [skuId: number]: {
          [currencyCode: string]: {
            unitPrice: string;
            unitPriceOriginal: string;
          };
        };
      } = {};
      const initialSelectedCurrencies: {
        [skuId: number]: string[];
      } = {};

      skuPrices.forEach((sku) => {
        // Regular SEK price inputs (for backward compatibility)
        initialPriceInputs[sku.skuId] = {
          unitPrice: (sku.seUnitPrice || sku.unitPrice)
            .toString()
            .replace('.', ','),
          unitPriceOriginal: (sku.seUnitPriceOriginal || sku.unitPriceOriginal)
            .toString()
            .replace('.', ','),
        };

        // Initialize currency price inputs (always include SEK)
        initialCurrencyInputs[sku.skuId] = {
          SEK: {
            unitPrice: (sku.seUnitPrice || sku.unitPrice)
              .toString()
              .replace('.', ','),
            unitPriceOriginal: (
              sku.seUnitPriceOriginal || sku.unitPriceOriginal
            )
              .toString()
              .replace('.', ','),
          },
        };

        // Initialize SEK as the default selected currency
        initialSelectedCurrencies[sku.skuId] = ['SEK'];

        // Initialize DKK prices if available but don't select them by default
        if (
          sku.dkUnitPrice !== undefined &&
          sku.dkUnitPriceOriginal !== undefined
        ) {
          initialCurrencyInputs[sku.skuId]['DKK'] = {
            unitPrice: sku.dkUnitPrice.toString().replace('.', ','),
            unitPriceOriginal: sku.dkUnitPriceOriginal
              .toString()
              .replace('.', ','),
          };
        }

        // Calculate and set initial discount percentage
        const discountPercentage = calculateDiscountPercentage(
          sku.seUnitPriceOriginal || sku.unitPriceOriginal,
          sku.seUnitPrice || sku.unitPrice,
        );
        if (discountPercentage) {
          initialDiscountInputs[sku.skuId] = discountPercentage;
        }
      });

      setPriceInputs(initialPriceInputs);
      setDiscountInput(initialDiscountInputs);
      setCurrencyPriceInputs(initialCurrencyInputs);
      setSelectedCurrencies(initialSelectedCurrencies);
    }
  }, [skuPrices]);

  // Lägg till refs för varje sektion
  const descriptionRef = useRef<HTMLDivElement>(null);
  const categoriesRef = useRef<HTMLDivElement>(null);
  const mediaRef = useRef<HTMLDivElement>(null);
  const pricesRef = useRef<HTMLDivElement>(null);

  // Uppdatera handleTabChange för att scrolla till rätt sektion
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    const refs = [descriptionRef, categoriesRef, mediaRef, pricesRef];
    refs[newValue]?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`product-tabpanel-${index}`}
        aria-labelledby={`product-tab-${index}`}
        {...other}
        style={{ height: '100%', overflowY: 'auto' }}
      >
        {value === index && <Box sx={{ p: 3, height: '100%' }}>{children}</Box>}
      </div>
    );
  }

  // Hanterare för expand/collapse
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line
  }, [
    page,
    rowsPerPage,
    search,
    longDescriptionFilter,
    noCategoryFilter,
    noImageFilter,
    partialImageFilter,
    noBrandInDescFilter,
    noStockFilter,
    partialStockFilter,
    noDescriptionFilter,
    hasStockFilter,
    activeFilter,
    selectedBrand,
    selectedCategoryId,
  ]);

  useEffect(() => {
    fetchAllCategories();
    fetchAllBadges();
    fetchBrands();
    fetchFlatCategories();
  }, []);

  const fetchProducts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${config.API_URL}/products`, {
        params: {
          search,
          page: page + 1, // backend pages start from 1
          pageSize: rowsPerPage,
          longDescriptionFilter,
          noCategoryFilter,
          noImageFilter,
          partialImageFilter,
          noBrandInDescFilter,
          noStockFilter,
          partialStockFilter,
          noDescriptionFilter,
          hasStockFilter,
          activeFilter:
            activeFilter !== null ? activeFilter.toString() : undefined,
          brand: selectedBrand || undefined,
          categoryId: selectedCategoryId || undefined,
        },
      });
      setProducts(response.data.products);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllCategories = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/categories`);
      const categoryData = response.data;
      const flatCategories = flattenCategories(categoryData);
      setCategoryOptions(flatCategories);

      // Skapa maps för enkel åtkomst
      const cMap: { [key: number]: CategoryOption } = {};
      const pMap: { [key: number]: number | null } = {};
      flatCategories.forEach((cat) => {
        cMap[cat.categoryId] = cat;
        pMap[cat.categoryId] = cat.parentCategoryId;
      });
      setCategoryMap(cMap);
      setParentMap(pMap);
    } catch (error) {
      console.error(error);
    }
  };

  // Funktion för att hämta platta kategorier för dropdown
  const fetchFlatCategories = async () => {
    try {
      const response = await axios.get(
        `${config.API_URL}/products/categories?flat=true`,
      );
      setFlatCategories(response.data);
    } catch (error) {
      console.error('Error fetching flat categories:', error);
    }
  };

  const flattenCategories = (
    categories: Category[],
    parentPath = '',
    depth = 0,
  ): CategoryOption[] => {
    let flatList: CategoryOption[] = [];

    categories.forEach((category) => {
      const currentPath = parentPath
        ? `${parentPath} > ${category.categoryName}`
        : category.categoryName;

      flatList.push({
        categoryId: category.categoryId,
        categoryName: category.categoryName,
        fullPath: currentPath,
        parentCategoryId: category.parentCategoryId,
        depth: depth,
      });

      if (category.children && category.children.length > 0) {
        flatList = flatList.concat(
          flattenCategories(category.children, currentPath, depth + 1),
        );
      }
    });

    return flatList;
  };

  useEffect(() => {
    if (productCategoryIds && categoryOptions.length > 0) {
      // Mappa produktkategorier till categoryOptions
      const selectedCats = categoryOptions.filter((cat) =>
        productCategoryIds.includes(cat.categoryId),
      );

      // Inkludera föräldrakategorier
      const allSelectedCategoriesMap = new Map<number, CategoryOption>();
      selectedCats.forEach((cat) => {
        allSelectedCategoriesMap.set(cat.categoryId, cat);
        const ancestors = getAncestors(cat.categoryId);
        ancestors.forEach((ancestor) => {
          if (!allSelectedCategoriesMap.has(ancestor.categoryId)) {
            allSelectedCategoriesMap.set(ancestor.categoryId, ancestor);
          }
        });
      });

      const allSelectedCategories = Array.from(
        allSelectedCategoriesMap.values(),
      );

      // Sortera kategorier baserat på djup
      allSelectedCategories.sort((a, b) => a.depth - b.depth);

      setSelectedCategories(allSelectedCategories);
    } else {
      setSelectedCategories([]);
    }
  }, [productCategoryIds, categoryOptions]);

  const getAncestors = (categoryId: number): CategoryOption[] => {
    const ancestors: CategoryOption[] = [];
    let currentCategoryId = categoryId;
    while (
      parentMap[currentCategoryId] !== null &&
      parentMap[currentCategoryId] !== undefined
    ) {
      const parentId = parentMap[currentCategoryId];
      if (parentId !== null && categoryMap[parentId]) {
        ancestors.unshift(categoryMap[parentId]);
        currentCategoryId = parentId;
      } else {
        break;
      }
    }
    return ancestors;
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setPage(0);
  };

  const handleLongDescriptionFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLongDescriptionFilter(e.target.checked);
    setPage(0);
  };

  const handleNoCategoryFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNoCategoryFilter(e.target.checked);
    setPage(0);
  };

  const handleNoImageFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNoImageFilter(e.target.checked);
    setPage(0);
  };

  const handlePartialImageFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPartialImageFilter(e.target.checked);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleProductClick = async (product: Product) => {
    try {
      // Återställ activeTab till 0 när en ny produkt öppnas
      setActiveTab(0);

      // Återställ rabatt-inputs innan vi laddar ny produkt
      setDiscountInput({});

      const [
        productResponse,
        skuResponse,
        mediaResponse,
        categoryResponse,
        priceResponse,
        badgeResponse,
      ] = await Promise.all([
        axios.get(`${config.API_URL}/products/${product.productId}`),
        axios.get(`${config.API_URL}/products/${product.productId}/skus`),
        axios.get(`${config.API_URL}/products/${product.productId}/media`),
        axios.get(`${config.API_URL}/products/${product.productId}/categories`),
        axios.get<SKUPrice[]>(
          `${config.API_URL}/products/${product.productId}/skus`,
        ),
        axios.get(`${config.API_URL}/products/${product.productId}/badges`),
      ]);

      const productData = productResponse.data;
      const skuData = skuResponse.data as SKU[];
      const mediaData = mediaResponse.data;
      const productCategories = categoryResponse.data as number[];
      const priceData = priceResponse.data;
      const badgeData = badgeResponse.data;

      // Sätt SKUs
      setSkus(skuData);

      // Sätt SKU priser
      setSkuPrices(priceData);

      // Initiera prisinputs
      const initialInputs: {
        [skuId: number]: { unitPrice: string; unitPriceOriginal: string };
      } = {};
      priceData.forEach((sku: SKUPrice) => {
        initialInputs[sku.skuId] = {
          unitPrice: sku.unitPrice.toString().replace('.', ','),
          unitPriceOriginal: sku.unitPriceOriginal.toString().replace('.', ','),
        };
      });
      setPriceInputs(initialInputs);

      // Hämta produktmedia
      setProductMedia(mediaData);

      // Räkna ut totalColors och colorsWithImages
      const uniqueColors = new Set(skuData.map((sku) => sku.attribute1Code));
      const totalColors = uniqueColors.size;
      const colorsWithImages = Object.keys(mediaData).length;

      // Uppdatera selectedProduct med beräknade värden
      setSelectedProduct({
        ...productData,
        colorsWithImages,
        totalColors,
        hasCategories: productData.hasCategories ?? product.hasCategories,
        hasValidDescription:
          productData.hasValidDescription ?? product.hasValidDescription,
      });

      // Hantera keywords oavsett om det kommer som sträng eller array
      let keywordsArray: string[] = [];
      if (typeof productData.keywords === 'string') {
        keywordsArray = productData.keywords
          .split(',')
          .map((k: string) => k.trim());
      } else if (Array.isArray(productData.keywords)) {
        keywordsArray = productData.keywords;
      }
      setKeywords(keywordsArray.filter((k) => k));

      // Konvertera HTML till DraftJS ContentState
      const html = productData.longDescription || '';
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      } else {
        setEditorState(EditorState.createEmpty());
      }

      // Initiera `productDescription` med befintlig HTML
      setProductDescription(html);

      // Hämta produktkategorier
      setProductCategoryIds(productCategories);

      // Extrahera unika färger från SKUs
      const colors = Array.from(uniqueColors);
      setAvailableColors(colors);

      // Uppdatera hur vi sätter selectedBadges
      // Hitta matchande badges från vår huvudlista av badges
      const productBadges = badges.filter((badge) =>
        badgeData.some(
          (productBadge: Badge) => productBadge.categoryId === badge.categoryId,
        ),
      );

      setSelectedBadges(productBadges);
      setProductBadgeIds(badgeData.map((badge: Badge) => badge.categoryId));
    } catch (error) {
      console.error('Error fetching product details:', error);
      setSnackbarMessage('Ett fel inträffade vid hämtning av produktdata');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditorChange = (state: EditorState) => {
    setEditorState(state);
    const content = draftToHtml(convertToRaw(state.getCurrentContent()));
    setProductDescription(content);
  };

  const handleSaveChanges = async () => {
    if (!selectedProduct) return;

    setIsSaving(true);
    try {
      // Samla alla ändringar som ska sparas
      const promises = [];

      // Spara produktdata (beskrivning, kategorier, keywords)
      const dataToSave = {
        longDescription: productDescription,
        categoriesToReplaceExisting: [
          ...selectedCategories.map((cat) => ({
            categoryId: cat.categoryId,
          })),
          ...selectedBadges.map((badge) => ({
            categoryId: badge.categoryId,
          })),
        ],
        keywords: keywords,
      };
      promises.push(
        axios.put(
          `${config.API_URL}/products/${selectedProduct.productId}`,
          dataToSave,
        ),
      );

      // Spara prisuppdateringar (om det finns några)
      if (skuPrices.length > 0) {
        const updatedSkus = skuPrices
          .map((sku) => {
            const currencyInputs = currencyPriceInputs[sku.skuId] || {};
            const selectedCurrenciesForSku = selectedCurrencies[sku.skuId] || [
              'SEK',
            ];

            // Hämta nya priser eller använd ursprungliga
            const newSeUnitPrice = currencyInputs['SEK']?.unitPrice
              ? parseFloat(currencyInputs['SEK'].unitPrice.replace(',', '.'))
              : sku.seUnitPrice || sku.unitPrice;
            const newSeUnitPriceOriginal = currencyInputs['SEK']
              ?.unitPriceOriginal
              ? parseFloat(
                  currencyInputs['SEK'].unitPriceOriginal.replace(',', '.'),
                )
              : sku.seUnitPriceOriginal || sku.unitPriceOriginal;
            const newDkUnitPrice = currencyInputs['DKK']?.unitPrice
              ? parseFloat(currencyInputs['DKK'].unitPrice.replace(',', '.'))
              : sku.dkUnitPrice;
            const newDkUnitPriceOriginal = currencyInputs['DKK']
              ?.unitPriceOriginal
              ? parseFloat(
                  currencyInputs['DKK'].unitPriceOriginal.replace(',', '.'),
                )
              : sku.dkUnitPriceOriginal;

            // Kontrollera om några priser har ändrats
            const hasPriceChanges =
              newSeUnitPrice !==
                parseFloat(String(sku.seUnitPrice || sku.unitPrice)) ||
              newSeUnitPriceOriginal !==
                parseFloat(
                  String(sku.seUnitPriceOriginal || sku.unitPriceOriginal),
                ) ||
              (selectedCurrenciesForSku.includes('DKK') &&
                (newDkUnitPrice !==
                  parseFloat(String(sku.dkUnitPrice || '0')) ||
                  newDkUnitPriceOriginal !==
                    parseFloat(String(sku.dkUnitPriceOriginal || '0'))));

            if (!hasPriceChanges) {
              return null;
            }

            // Grundläggande prisdata med SEK-priser
            const basePriceData = {
              attribute1Code: sku.attribute1Code,
              unitPrice: newSeUnitPrice,
              unitPriceOriginal: newSeUnitPriceOriginal,
              taxRate: sku.taxRate,
            };

            // Om DKK är valt och har ändrats, lägg till danska priser
            if (selectedCurrenciesForSku.includes('DKK')) {
              return {
                ...basePriceData,
                dkUnitPrice: newDkUnitPrice || 0,
                dkUnitPriceOriginal: newDkUnitPriceOriginal || 0,
              };
            }

            return basePriceData;
          })
          .filter((sku): sku is NonNullable<typeof sku> => sku !== null);

        if (updatedSkus.length > 0) {
          promises.push(
            axios.post(
              `${config.API_URL}/products/${selectedProduct.productId}/update-prices`,
              { skus: updatedSkus },
            ),
          );
        }
      }

      // Vänta på att alla sparningar är klara
      await Promise.all(promises);

      setSnackbarMessage('Produktuppgifter har sparats');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);

      handleCloseDialog();
      fetchProducts();
    } catch (error) {
      console.error('Error saving changes:', error);
      setSnackbarMessage('Ett fel inträffade vid sparande av ändringar');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCloseDialog = () => {
    setSelectedProduct(null);
    setProductDescription('');
    setSelectedCategories([]);
    setProductCategoryIds([]);
    setEditorState(EditorState.createEmpty());
    setProductMedia({});
    setSkus([]);
    setAvailableColors([]);
    setKeywords([]);
    setPriceInputs({});
    setDiscountInput({}); // Återställ rabatt-inputs
  };

  const handleCategoryChange = (event: any, newValue: CategoryOption[]) => {
    // Automatisk lägga till föräldrakategorier
    const allSelectedCategoriesMap = new Map<number, CategoryOption>();
    newValue.forEach((cat) => {
      allSelectedCategoriesMap.set(cat.categoryId, cat);
      const ancestors = getAncestors(cat.categoryId);
      ancestors.forEach((ancestor) => {
        if (!allSelectedCategoriesMap.has(ancestor.categoryId)) {
          allSelectedCategoriesMap.set(ancestor.categoryId, ancestor);
        }
      });
    });

    // Konvertera map till array
    const allSelectedCategories = Array.from(allSelectedCategoriesMap.values());

    // Sortera kategorier baserat på djup (lägst djup först)
    allSelectedCategories.sort((a, b) => a.depth - b.depth);

    setSelectedCategories(allSelectedCategories);
  };

  // Funktion för att generera beskrivning med AI
  const handleGenerateDescription = async () => {
    if (!selectedProduct) return;

    setIsGenerating(true);
    try {
      const content = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      );

      const response = await axios.post(
        `${config.API_URL}/products/${selectedProduct.productId}/generate-description`,
        {
          currentDescription: content,
        },
      );

      let generatedDescription = response.data.generatedDescription;

      // Formatera texten med HTML-paragrafer
      generatedDescription = generatedDescription
        .split('\n\n')
        .filter((paragraph: string) => paragraph.trim() !== '')
        .map((paragraph: string) => `<p>${paragraph.trim()}</p>`)
        .join('');

      const contentBlock = htmlToDraft(generatedDescription);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
          contentBlock.entityMap,
        );
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
        setProductDescription(generatedDescription);
      }
    } catch (error) {
      console.error('Error generating description:', error);
      alert('Ett fel inträffade vid generering av produktbeskrivning.');
    } finally {
      setIsGenerating(false);
    }
  };

  // Hantera förhandsgranskning (processa bilder utan att stnga modal)
  const handlePreview = async (data: UploadData) => {
    try {
      // Skicka data till backend
      const formData = new FormData();
      formData.append('articleNumber', data.articleNumber);
      formData.append('colorCode', data.colorCode);
      formData.append('attribute1Number', data.attribute1Number);
      formData.append(
        'transparentIndices',
        JSON.stringify(data.transparentIndices),
      );
      data.images.forEach((file) => {
        formData.append('images', file, file.name);
      });

      const response = await axios.post(
        `${config.API_URL}/upload/preview`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      // Lägg till API_URL till varje bild-URL som kommer tillbaka
      const imageUrls = response.data.imageUrls.map(
        (url: string) => `${config.API_URL}/${url}`,
      );

      setUploadedImages(imageUrls);
    } catch (error) {
      console.error(error);
      alert('Ett fel inträffade vid bearbetning av bilder.');
    }
  };

  const handleUploadSubmit = async (data: UploadData) => {
    try {
      await axios.post(`${config.API_URL}/upload/save`, {
        articleNumber: data.articleNumber,
        colorCode: data.colorCode,
        attribute1Number: data.attribute1Number,
        imageUrls: uploadedImages,
      });

      // Hämta uppdaterad produktinformation inklusive media
      if (selectedProduct) {
        const mediaResponse = await axios.get(
          `${config.API_URL}/products/${selectedProduct.productId}/media`,
        );
        setProductMedia(mediaResponse.data);
      }

      handleCloseUploadModal();
      alert('Bilder har sparats.');
    } catch (error: any) {
      console.error('Upload error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        url: error.config?.url,
        method: error.config?.method,
        data: error.config?.data,
      });

      // Mer specifikt felmeddelande till användaren
      const errorMessage =
        error.response?.data?.message ||
        'Ett fel inträffade vid sparande av bilder';
      alert(errorMessage);
    }
  };

  const handleOpenUploadModal = (sku: SKU) => {
    setSelectedSku(sku);
    setIsUploadModalOpen(true);
    setUploadedImages([]); // Återställ uppladdade bilder
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
    setSelectedSku(null);
    setUploadedImages([]);
  };

  // Lägg till ny hanterare för filtret
  const handleNoBrandInDescFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNoBrandInDescFilter(e.target.checked);
    setPage(0);
  };

  // Lägg till nya hanterare för filterändringar
  const handleNoStockFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNoStockFilter(e.target.checked);
    setPage(0);
  };

  const handlePartialStockFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPartialStockFilter(e.target.checked);
    setPage(0);
  };

  const [keywords, setKeywords] = useState<string[]>([]);
  const [newKeyword, setNewKeyword] = useState('');

  const handleAddKeyword = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && newKeyword.trim()) {
      if (!keywords.includes(newKeyword.trim())) {
        setKeywords([...keywords, newKeyword.trim()]);
        setNewKeyword('');
      }
    }
  };

  const handleDeleteKeyword = (keywordToDelete: string) => {
    setKeywords(keywords.filter((keyword) => keyword !== keywordToDelete));
  };

  const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewKeyword(event.target.value);
  };

  const [isGeneratingKeywords, setIsGeneratingKeywords] = useState(false);

  const handleGenerateKeywords = async () => {
    if (!selectedProduct) return;

    setIsGeneratingKeywords(true);
    try {
      const content = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      );

      const response = await axios.post(
        `${config.API_URL}/products/${selectedProduct.productId}/generate-keywords`,
        {
          currentDescription: content,
        },
      );

      // Kombinera existerande och nya nyckelord, ta bort eventuella dubletter
      const newKeywords = response.data.keywords;
      const combinedKeywords = Array.from(
        new Set([...keywords, ...newKeywords]),
      );
      setKeywords(combinedKeywords);
    } catch (error) {
      console.error('Error generating keywords:', error);
      alert('Ett fel inträffade vid generering av nyckelord.');
    } finally {
      setIsGeneratingKeywords(false);
    }
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success',
  );

  // Lägg till ny hanterare för filtret
  const handleNoDescriptionFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNoDescriptionFilter(e.target.checked);
    setPage(0);
  };

  // Lägg till prishanteringsfunktioner
  const handlePriceChange = (
    skuId: number,
    priceType: 'unitPrice' | 'unitPriceOriginal',
    value: string,
  ) => {
    setPriceInputs((prev) => ({
      ...prev,
      [skuId]: {
        ...prev[skuId],
        [priceType]: value,
      },
    }));
  };

  const handleDiscountChange = (skuId: number, discountValue: string) => {
    setDiscountInput((prev) => ({
      ...prev,
      [skuId]: discountValue,
    }));

    // Om värdet är tomt, sätt försäljningspris till ordinarie pris
    if (!discountValue.trim()) {
      const originalPrice = priceInputs[skuId]?.unitPriceOriginal || '0';
      handlePriceChange(skuId, 'unitPrice', originalPrice);
      return;
    }

    const originalPrice =
      parseFloat(priceInputs[skuId]?.unitPriceOriginal.replace(',', '.')) || 0;

    if (originalPrice && discountValue) {
      const discount = parseFloat(discountValue) / 100;
      if (!isNaN(discount)) {
        const discountedPrice = Math.round(originalPrice * (1 - discount));
        handlePriceChange(skuId, 'unitPrice', discountedPrice.toString());
      }
    }
  };

  // Lägg till nya hanterare för valutahantering
  const handleCurrencyPriceChange = (
    skuId: number,
    currencyCode: string,
    priceType: 'unitPrice' | 'unitPriceOriginal',
    value: string,
  ) => {
    setCurrencyPriceInputs((prev) => ({
      ...prev,
      [skuId]: {
        ...prev[skuId],
        [currencyCode]: {
          ...prev[skuId]?.[currencyCode],
          [priceType]: value,
        },
      },
    }));

    // Uppdatera även priceInputs för bakåtkompatibilitet om det är SEK
    if (currencyCode === 'SEK') {
      setPriceInputs((prev) => ({
        ...prev,
        [skuId]: {
          ...prev[skuId],
          [priceType]: value,
        },
      }));
    }
  };

  const handleCurrencyDiscountChange = (
    skuId: number,
    currencyCode: string,
    discountValue: string,
  ) => {
    // Uppdatera discountInput för den specifika valutan
    setDiscountInput((prev) => ({
      ...prev,
      [`${skuId}-${currencyCode}`]: discountValue,
    }));

    if (!discountValue.trim()) {
      const originalPrice =
        currencyPriceInputs[skuId]?.[currencyCode]?.unitPriceOriginal || '0';
      handleCurrencyPriceChange(
        skuId,
        currencyCode,
        'unitPrice',
        originalPrice,
      );
      return;
    }

    const originalPrice =
      parseFloat(
        currencyPriceInputs[skuId]?.[currencyCode]?.unitPriceOriginal.replace(
          ',',
          '.',
        ),
      ) || 0;

    if (originalPrice && discountValue) {
      const discount = parseFloat(discountValue) / 100;
      if (!isNaN(discount)) {
        const discountedPrice = Math.round(originalPrice * (1 - discount));
        handleCurrencyPriceChange(
          skuId,
          currencyCode,
          'unitPrice',
          discountedPrice.toString(),
        );
      }
    }
  };

  const handleToggleCurrency = (skuId: number, currencyCode: string) => {
    setSelectedCurrencies((prev) => {
      const currentCurrencies = prev[skuId] || ['SEK'];
      const newCurrencies = currentCurrencies.includes(currencyCode)
        ? currentCurrencies.filter((code) => code !== currencyCode)
        : [...currentCurrencies, currencyCode];

      // Om DKK läggs till för första gången, visa hjälpmeddelande
      if (currencyCode === 'DKK' && !currentCurrencies.includes('DKK')) {
        setShowCurrencyHelpMessage((prev) => ({
          ...prev,
          [skuId]: true,
        }));
      }

      return {
        ...prev,
        [skuId]: newCurrencies,
      };
    });
  };

  const handleHelpMessageClose = (skuId: number) => {
    setShowCurrencyHelpMessage((prev) => ({
      ...prev,
      [skuId]: false,
    }));
  };

  // Lägg till useCallback för att hantera intersection
  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === descriptionRef.current) setActiveTab(0);
          else if (entry.target === categoriesRef.current) setActiveTab(1);
          else if (entry.target === mediaRef.current) setActiveTab(2);
          else if (entry.target === pricesRef.current) setActiveTab(3);
        }
      });
    },
    [],
  );

  // Lägg till Intersection Observer
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, // Elementet måste vara 50% synligt
      root: document.querySelector(
        '[role="dialog"] > .MuiBox-root > .MuiBox-root',
      ), // Scrollcontainern
    });

    const refs = [descriptionRef, categoriesRef, mediaRef, pricesRef];
    refs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      refs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [handleIntersection]);

  const fetchAllBadges = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/categories/badges`);
      setBadges(response.data);
    } catch (error) {
      console.error('Error fetching badges:', error);
    }
  };

  // Funktion för att hämta alla varumärken
  const fetchBrands = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/products/brands`);
      setBrands(response.data);
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  // Lägg till hanterare för det nya filtret
  const handleHasStockFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setHasStockFilter(e.target.checked);
    setPage(0);
  };

  // Lägg till hanterare för aktiva produkter filter
  const handleActiveFilterChange = (value: boolean | null) => {
    setActiveFilter(value);
    setPage(0);
  };

  // Hantera val av varumärke
  const handleBrandChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedBrand(event.target.value);
    setPage(0); // Återställ till första sidan vid nytt varumärkesval
  };

  // Hantera val av kategori
  const handleCategoryFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    setSelectedCategoryId(value ? Number(value) : null);
    setPage(0); // Återställ till första sidan vid nytt kategorival
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Produkthantering
      </Typography>
      <TextField
        label="Sök"
        variant="outlined"
        value={search}
        onChange={handleSearchChange}
        sx={{ mb: 2, width: '100%' }}
      />

      <Paper sx={{ p: 2, mb: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={handleExpandClick}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FilterListIcon sx={{ mr: 1 }} />
            <Typography variant="h6">Produktfilter</Typography>
          </Box>
          <ExpandMore
            expand={expanded}
            aria-expanded={expanded}
            aria-label="visa filter"
            sx={{ ml: 'auto' }}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              {/* Beskrivningsfilter */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <DescriptionIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="subtitle1">Beskrivning</Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={longDescriptionFilter}
                        onChange={handleLongDescriptionFilterChange}
                      />
                    }
                    label="Kort produktbeskrivning"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={noDescriptionFilter}
                        onChange={handleNoDescriptionFilterChange}
                      />
                    }
                    label="Saknar beskrivning"
                  />
                </Paper>
              </Grid>

              {/* Kategorifilter */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <CategoryIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="subtitle1">Kategorier</Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={noCategoryFilter}
                        onChange={handleNoCategoryFilterChange}
                      />
                    }
                    label="Saknar kategori"
                  />
                  <Box sx={{ minWidth: 120, mt: 2 }}>
                    <TextField
                      select
                      fullWidth
                      label="Välj kategori"
                      value={selectedCategoryId || ''}
                      onChange={handleCategoryFilterChange}
                      variant="outlined"
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="">Alla kategorier</option>
                      {flatCategories
                        .slice()
                        .sort((a, b) =>
                          a.fullPath.localeCompare(b.fullPath, 'sv'),
                        )
                        .map((category) => (
                          <option
                            key={category.categoryId}
                            value={category.categoryId}
                          >
                            {category.fullPath}
                          </option>
                        ))}
                    </TextField>
                  </Box>
                </Paper>
              </Grid>

              {/* Bildfilter */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <ImageIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="subtitle1">Bilder</Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={noImageFilter}
                        onChange={handleNoImageFilterChange}
                      />
                    }
                    label="Saknar bilder"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={partialImageFilter}
                        onChange={handlePartialImageFilterChange}
                      />
                    }
                    label="Ofullständiga bilder"
                  />
                </Paper>
              </Grid>

              {/* Varumärkesfilter */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <BrandingWatermarkIcon
                      sx={{ mr: 1, color: 'primary.main' }}
                    />
                    <Typography variant="subtitle1">Varumärke</Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={noBrandInDescFilter}
                        onChange={handleNoBrandInDescFilterChange}
                      />
                    }
                    label="Saknar varumärke i beskrivning"
                  />
                  <Box sx={{ minWidth: 120, mt: 2 }}>
                    <TextField
                      select
                      fullWidth
                      label="Välj varumärke"
                      value={selectedBrand}
                      onChange={handleBrandChange}
                      variant="outlined"
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="">Alla varumärken</option>
                      {brands.map((brand) => (
                        <option key={brand.brandId} value={brand.brandName}>
                          {brand.brandName}
                        </option>
                      ))}
                    </TextField>
                  </Box>
                </Paper>
              </Grid>

              {/* Lagersaldofilter */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <InventoryIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="subtitle1">Lagersaldo</Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={noStockFilter}
                        onChange={handleNoStockFilterChange}
                      />
                    }
                    label="Saknar lagersaldo"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={partialStockFilter}
                        onChange={handlePartialStockFilterChange}
                      />
                    }
                    label="Ofullständigt lagersaldo"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hasStockFilter}
                        onChange={handleHasStockFilterChange}
                      />
                    }
                    label="Har lagersaldo"
                  />
                </Paper>
              </Grid>

              {/* Statusfilter (Aktiva/Inaktiva produkter) */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <FilterListIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="subtitle1">Produktstatus</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={activeFilter === true}
                          onChange={() =>
                            handleActiveFilterChange(
                              activeFilter === true ? null : true,
                            )
                          }
                        />
                      }
                      label="Visa endast aktiva produkter"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={activeFilter === false}
                          onChange={() =>
                            handleActiveFilterChange(
                              activeFilter === false ? null : false,
                            )
                          }
                        />
                      }
                      label="Visa endast inaktiva produkter"
                    />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Paper>

      <Paper sx={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              zIndex: 1,
            }}
          >
            <CircularProgress sx={{ color: '#3F51B5' }} />
          </Box>
        )}
        <Table stickyHeader aria-label="Produktlista">
          <TableHead>
            <TableRow>
              <TableCell>Artikelnummer</TableCell>
              <TableCell>Produktnamn</TableCell>
              <TableCell>Varumärke</TableCell>
              <TableCell>Bild</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, index) => (
              <TableRow
                key={`${product.productId}-${index}`}
                hover
                onClick={() => handleProductClick(product)}
                style={{ cursor: 'pointer' }}
              >
                <TableCell>{product.productNumber}</TableCell>
                <TableCell>{product.productName}</TableCell>
                <TableCell>{product.brandName}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {product.mediaUrl ? (
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          borderRadius: 1,
                          overflow: 'hidden',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: 'lightgray',
                        }}
                      >
                        <SmartImage
                          baseUrl="https://media.viskanassets.com/v2/enghssportprod/original/"
                          imageName={product.mediaUrl}
                          alt={product.productName}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      </Box>
                    ) : (
                      <ImageIcon
                        sx={{ color: 'text.disabled', width: 40, height: 40 }}
                      />
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination
        component="div"
        count={totalPages * rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Dialog för produktdetaljer */}
      <Dialog
        open={!!selectedProduct}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            height: '90vh',
            maxHeight: '90vh',
            display: 'flex',
            flexDirection: 'row',
            m: 0,
            borderRadius: '24px',
            overflow: 'hidden',
            boxShadow:
              '0 20px 80px rgba(0, 0, 0, 0.3), 0 0 40px rgba(63, 81, 181, 0.2)',
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '4px',
              background: 'linear-gradient(90deg, #3F51B5, #FF4081)',
              zIndex: 1,
            },
          },
        }}
      >
        {isSaving && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              backdropFilter: 'blur(10px)',
              zIndex: 9999,
              borderRadius: '24px',
            }}
          >
            <CircularProgress sx={{ color: '#3F51B5' }} />
          </Box>
        )}

        {/* Sidopanel */}
        <Box
          sx={{
            width: 280,
            minWidth: 280,
            maxWidth: 280,
            borderRight: '1px solid rgba(0, 0, 0, 0.06)',
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'rgba(249, 250, 255, 0.95)',
            position: 'sticky',
            left: 0,
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              p: 3,
              borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
              overflow: 'hidden',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                mb: 3,
                background: 'linear-gradient(135deg, #3F51B5, #FF4081)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                letterSpacing: '-0.02em',
              }}
            >
              Produktdetaljer
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              Artikelnummer
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 500,
                color: '#1A237E',
                mb: 2,
              }}
            >
              {selectedProduct?.productNumber}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              Produktnamn
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 500,
                color: '#1A237E',
                mb: 2,
              }}
            >
              {selectedProduct?.productName}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              Varumärke
            </Typography>
            <Typography
              variant="body1"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 500,
                color: '#1A237E',
                mb: 2,
              }}
            >
              {selectedProduct?.brandName}
            </Typography>

            {/* Statusindikator */}
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Status
              </Typography>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                <Chip
                  size="small"
                  icon={<DescriptionIcon />}
                  label={
                    selectedProduct?.hasValidDescription === 1
                      ? 'Beskrivning OK'
                      : 'För kort beskrivning'
                  }
                  color={
                    selectedProduct?.hasValidDescription === 1
                      ? 'success'
                      : 'error'
                  }
                  sx={{
                    borderRadius: '12px',
                    fontWeight: 500,
                    '& .MuiChip-icon': {
                      color: 'inherit',
                    },
                  }}
                />
                <Chip
                  size="small"
                  icon={<CategoryIcon />}
                  label={
                    selectedProduct?.hasCategories === 1
                      ? 'Kategorier OK'
                      : 'Saknar kategorier'
                  }
                  color={
                    selectedProduct?.hasCategories === 1 ? 'success' : 'error'
                  }
                  sx={{
                    borderRadius: '12px',
                    fontWeight: 500,
                    '& .MuiChip-icon': {
                      color: 'inherit',
                    },
                  }}
                />
                <Chip
                  size="small"
                  icon={<ImageIcon />}
                  label={
                    !selectedProduct?.totalColors ||
                    selectedProduct?.totalColors === 0
                      ? 'Saknar färger'
                      : selectedProduct?.colorsWithImages === 0
                        ? 'Saknar bilder'
                        : selectedProduct?.colorsWithImages ===
                            selectedProduct?.totalColors
                          ? 'Alla färger har bilder'
                          : `Bilder ${selectedProduct?.colorsWithImages}/${selectedProduct?.totalColors}`
                  }
                  color={
                    !selectedProduct?.totalColors ||
                    selectedProduct?.totalColors === 0
                      ? 'error'
                      : selectedProduct?.colorsWithImages === 0
                        ? 'error'
                        : selectedProduct?.colorsWithImages ===
                            selectedProduct?.totalColors
                          ? 'success'
                          : 'warning'
                  }
                  sx={{
                    borderRadius: '12px',
                    fontWeight: 500,
                    '& .MuiChip-icon': {
                      color: 'inherit',
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>

          <List sx={{ flexGrow: 0, py: 1 }}>
            <ListItem
              sx={{
                cursor: 'pointer',
                borderRadius: '0 24px 24px 0',
                mx: 1,
                my: 0.5,
                bgcolor:
                  activeTab === 0 ? 'rgba(63, 81, 181, 0.1)' : 'transparent',
                color: activeTab === 0 ? '#3F51B5' : 'inherit',
                transition: 'all 0.3s ease',
                '&:hover': {
                  bgcolor:
                    activeTab === 0
                      ? 'rgba(63, 81, 181, 0.15)'
                      : 'rgba(0, 0, 0, 0.04)',
                  transform: 'translateX(4px)',
                },
              }}
              onClick={(e) => handleTabChange(e, 0)}
            >
              <ListItemIcon
                sx={{
                  color: activeTab === 0 ? '#3F51B5' : 'inherit',
                  minWidth: '40px',
                }}
              >
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText
                primary="Beskrivning"
                primaryTypographyProps={{
                  fontWeight: activeTab === 0 ? 600 : 400,
                }}
              />
            </ListItem>
            <ListItem
              sx={{
                cursor: 'pointer',
                borderRadius: '0 24px 24px 0',
                mx: 1,
                my: 0.5,
                bgcolor:
                  activeTab === 1 ? 'rgba(63, 81, 181, 0.1)' : 'transparent',
                color: activeTab === 1 ? '#3F51B5' : 'inherit',
                transition: 'all 0.3s ease',
                '&:hover': {
                  bgcolor:
                    activeTab === 1
                      ? 'rgba(63, 81, 181, 0.15)'
                      : 'rgba(0, 0, 0, 0.04)',
                  transform: 'translateX(4px)',
                },
              }}
              onClick={(e) => handleTabChange(e, 1)}
            >
              <ListItemIcon
                sx={{
                  color: activeTab === 1 ? '#3F51B5' : 'inherit',
                  minWidth: '40px',
                }}
              >
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText
                primary="Kategorier"
                primaryTypographyProps={{
                  fontWeight: activeTab === 1 ? 600 : 400,
                }}
              />
            </ListItem>
            <ListItem
              sx={{
                cursor: 'pointer',
                borderRadius: '0 24px 24px 0',
                mx: 1,
                my: 0.5,
                bgcolor:
                  activeTab === 2 ? 'rgba(63, 81, 181, 0.1)' : 'transparent',
                color: activeTab === 2 ? '#3F51B5' : 'inherit',
                transition: 'all 0.3s ease',
                '&:hover': {
                  bgcolor:
                    activeTab === 2
                      ? 'rgba(63, 81, 181, 0.15)'
                      : 'rgba(0, 0, 0, 0.04)',
                  transform: 'translateX(4px)',
                },
              }}
              onClick={(e) => handleTabChange(e, 2)}
            >
              <ListItemIcon
                sx={{
                  color: activeTab === 2 ? '#3F51B5' : 'inherit',
                  minWidth: '40px',
                }}
              >
                <LocalOfferIcon />
              </ListItemIcon>
              <ListItemText
                primary="Badges"
                primaryTypographyProps={{
                  fontWeight: activeTab === 2 ? 600 : 400,
                }}
              />
            </ListItem>
            <ListItem
              sx={{
                cursor: 'pointer',
                borderRadius: '0 24px 24px 0',
                mx: 1,
                my: 0.5,
                bgcolor:
                  activeTab === 3 ? 'rgba(63, 81, 181, 0.1)' : 'transparent',
                color: activeTab === 3 ? '#3F51B5' : 'inherit',
                transition: 'all 0.3s ease',
                '&:hover': {
                  bgcolor:
                    activeTab === 3
                      ? 'rgba(63, 81, 181, 0.15)'
                      : 'rgba(0, 0, 0, 0.04)',
                  transform: 'translateX(4px)',
                },
              }}
              onClick={(e) => handleTabChange(e, 3)}
            >
              <ListItemIcon
                sx={{
                  color: activeTab === 3 ? '#3F51B5' : 'inherit',
                  minWidth: '40px',
                }}
              >
                <ImageIcon />
              </ListItemIcon>
              <ListItemText
                primary="Media & Lager"
                primaryTypographyProps={{
                  fontWeight: activeTab === 3 ? 600 : 400,
                }}
              />
            </ListItem>
            <ListItem
              sx={{
                cursor: 'pointer',
                borderRadius: '0 24px 24px 0',
                mx: 1,
                my: 0.5,
                bgcolor:
                  activeTab === 4 ? 'rgba(63, 81, 181, 0.1)' : 'transparent',
                color: activeTab === 4 ? '#3F51B5' : 'inherit',
                transition: 'all 0.3s ease',
                '&:hover': {
                  bgcolor:
                    activeTab === 4
                      ? 'rgba(63, 81, 181, 0.15)'
                      : 'rgba(0, 0, 0, 0.04)',
                  transform: 'translateX(4px)',
                },
              }}
              onClick={(e) => handleTabChange(e, 4)}
            >
              <ListItemIcon
                sx={{
                  color: activeTab === 4 ? '#3F51B5' : 'inherit',
                  minWidth: '40px',
                }}
              >
                <MonetizationOnIcon />
              </ListItemIcon>
              <ListItemText
                primary="Priser"
                primaryTypographyProps={{
                  fontWeight: activeTab === 4 ? 600 : 400,
                }}
              />
            </ListItem>
          </List>

          <Box
            sx={{
              mt: 'auto',
              p: 3,
              borderTop: '1px solid rgba(0, 0, 0, 0.06)',
              flexShrink: 0,
            }}
          >
            <Button
              onClick={handleCloseDialog}
              sx={{
                mb: 2,
                width: '100%',
                borderRadius: '12px',
                padding: '0.625rem 1.5rem',
                color: '#455A64',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: 'rgba(63, 81, 181, 0.05)',
                  transform: 'translateY(-2px)',
                },
              }}
            >
              Avbryt
            </Button>
            <Button
              onClick={handleSaveChanges}
              color="primary"
              variant="contained"
              disabled={isSaving}
              sx={{
                width: '100%',
                borderRadius: '12px',
                padding: '0.625rem 1.5rem',
                fontWeight: 600,
                background: 'linear-gradient(135deg, #3F51B5, #536DFE)',
                boxShadow: '0 4px 12px rgba(63, 81, 181, 0.2)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  background: 'linear-gradient(135deg, #3949AB, #3F51B5)',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 8px 15px rgba(63, 81, 181, 0.3)',
                },
                '&:active': {
                  transform: 'translateY(-1px)',
                },
              }}
            >
              {isSaving ? 'Sparar...' : 'Spara'}
            </Button>
          </Box>
        </Box>

        {/* Huvudinnehåll */}
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            minWidth: 0,
            bgcolor: '#FFFFFF',
          }}
        >
          <Box sx={{ p: 4, flexGrow: 1, overflowY: 'auto' }}>
            {/* Beskrivningssektion */}
            <Box ref={descriptionRef} sx={{ mb: 6, pt: 2 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  mb: 3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5,
                  color: '#1A237E',
                }}
              >
                <DescriptionIcon sx={{ color: '#3F51B5' }} />
                Produktbeskrivning
              </Typography>
              <Box
                sx={{
                  position: 'relative',
                  mb: 4,
                  borderRadius: '16px',
                  overflow: 'hidden',
                  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.05)',
                }}
              >
                <IconButton
                  onClick={handleGenerateDescription}
                  color="primary"
                  sx={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    zIndex: 1,
                    transition: 'all 0.3s ease',
                    background: 'linear-gradient(135deg, #3F51B5, #536DFE)',
                    color: '#FFFFFF',
                    boxShadow: '0 4px 12px rgba(63, 81, 181, 0.2)',
                    '&:hover': {
                      background: 'linear-gradient(135deg, #3949AB, #3F51B5)',
                      transform: 'scale(1.1)',
                      boxShadow: '0 8px 15px rgba(63, 81, 181, 0.3)',
                    },
                  }}
                  disabled={isGenerating}
                >
                  {isGenerating ? (
                    <CircularProgress size={24} sx={{ color: '#FFFFFF' }} />
                  ) : (
                    <SmartToyIcon />
                  )}
                </IconButton>
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={handleEditorChange}
                  editorStyle={{
                    border: '1px solid rgba(0, 0, 0, 0.08)',
                    borderRadius: '16px',
                    minHeight: '300px',
                    padding: '16px 60px 50px 16px',
                    backgroundColor: '#FFFFFF',
                  }}
                  toolbar={{
                    options: ['inline', 'blockType', 'list', 'link'],
                    inline: {
                      options: ['bold', 'italic', 'underline'],
                    },
                    blockType: {
                      options: ['Normal', 'H2', 'H3'],
                    },
                  }}
                />
              </Box>

              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  mt: 5,
                  mb: 3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5,
                  color: '#1A237E',
                }}
              >
                <LocalOfferIcon sx={{ color: '#3F51B5' }} />
                Nyckelord
              </Typography>
              <Paper
                sx={{
                  p: 3,
                  borderRadius: '16px',
                  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.05)',
                  background: '#FFFFFF',
                }}
              >
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 3 }}>
                  {Array.isArray(keywords) &&
                    keywords.map((keyword) => (
                      <Chip
                        key={keyword}
                        label={keyword}
                        onDelete={() => handleDeleteKeyword(keyword)}
                        size="small"
                        sx={{
                          borderRadius: '12px',
                          background: 'rgba(63, 81, 181, 0.08)',
                          color: '#3F51B5',
                          fontWeight: 500,
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            background: 'rgba(63, 81, 181, 0.12)',
                            transform: 'translateY(-2px)',
                          },
                          '& .MuiChip-deleteIcon': {
                            color: '#3F51B5',
                            opacity: 0.5,
                            transition: 'all 0.3s ease',
                          },
                          '&:hover .MuiChip-deleteIcon': {
                            opacity: 1,
                          },
                        }}
                      />
                    ))}
                </Box>
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={newKeyword}
                    onChange={handleKeywordChange}
                    onKeyPress={handleAddKeyword}
                    placeholder="Skriv ett nyckelord och tryck Enter"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.2)',
                        },
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.3)',
                        },
                      },
                    }}
                  />
                  <IconButton
                    onClick={handleGenerateKeywords}
                    disabled={isGeneratingKeywords}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      color: '#3F51B5',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        background: 'rgba(63, 81, 181, 0.08)',
                        transform: 'translateY(-50%) scale(1.1)',
                      },
                    }}
                  >
                    {isGeneratingKeywords ? (
                      <CircularProgress size={24} sx={{ color: '#3F51B5' }} />
                    ) : (
                      <SmartToyIcon />
                    )}
                  </IconButton>
                </Box>
              </Paper>
            </Box>

            {/* Kategorisektion */}
            <Box ref={categoriesRef} sx={{ mb: 6, pt: 4 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  mb: 3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5,
                  color: '#1A237E',
                }}
              >
                <CategoryIcon sx={{ color: '#3F51B5' }} />
                Kategorier
              </Typography>
              <Paper
                sx={{
                  p: 3,
                  borderRadius: '16px',
                  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.05)',
                  background: '#FFFFFF',
                }}
              >
                <Autocomplete
                  multiple
                  options={categoryOptions}
                  getOptionLabel={(option) =>
                    option.fullPath || option.categoryName || ''
                  }
                  value={selectedCategories}
                  onChange={handleCategoryChange}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      const { key, ...restTagProps } = getTagProps({ index });
                      return (
                        <Chip
                          key={option.categoryId}
                          label={option.fullPath || option.categoryName}
                          size="small"
                          sx={{
                            borderRadius: '12px',
                            background: 'rgba(63, 81, 181, 0.08)',
                            color: '#3F51B5',
                            fontWeight: 500,
                            m: 0.5,
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              background: 'rgba(63, 81, 181, 0.12)',
                              transform: 'translateY(-2px)',
                            },
                            '& .MuiChip-deleteIcon': {
                              color: '#3F51B5',
                              opacity: 0.5,
                              transition: 'all 0.3s ease',
                            },
                            '&:hover .MuiChip-deleteIcon': {
                              opacity: 1,
                            },
                          }}
                          {...restTagProps}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Kategorier"
                      placeholder="Välj kategorier"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '12px',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.2)',
                          },
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.3)',
                          },
                        },
                      }}
                    />
                  )}
                  noOptionsText="Inga kategorier tillgängliga"
                />
              </Paper>

              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  mt: 5,
                  mb: 3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5,
                  color: '#1A237E',
                }}
              >
                <LocalOfferIcon sx={{ color: '#3F51B5' }} />
                Badges
              </Typography>
              <Paper
                sx={{
                  p: 3,
                  borderRadius: '16px',
                  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.05)',
                  background: '#FFFFFF',
                }}
              >
                <Autocomplete
                  multiple
                  options={badges}
                  getOptionLabel={(option) => option.categoryName || ''}
                  value={selectedBadges}
                  onChange={(event, newValue) => {
                    setSelectedBadges(newValue);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      const { key, ...restTagProps } = getTagProps({ index });
                      return (
                        <Chip
                          key={option.categoryId}
                          label={option.categoryName || ''}
                          size="small"
                          sx={{
                            borderRadius: '12px',
                            background: 'rgba(63, 81, 181, 0.08)',
                            color: '#3F51B5',
                            fontWeight: 500,
                            m: 0.5,
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              background: 'rgba(63, 81, 181, 0.12)',
                              transform: 'translateY(-2px)',
                            },
                            '& .MuiChip-deleteIcon': {
                              color: '#3F51B5',
                              opacity: 0.5,
                              transition: 'all 0.3s ease',
                            },
                            '&:hover .MuiChip-deleteIcon': {
                              opacity: 1,
                            },
                          }}
                          {...restTagProps}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Badges"
                      placeholder="Välj badges"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '12px',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.2)',
                          },
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.3)',
                          },
                        },
                      }}
                    />
                  )}
                  noOptionsText="Inga badges tillgängliga"
                />
              </Paper>
            </Box>

            {/* Media & Lagersektion */}
            <Box ref={mediaRef} sx={{ mb: 6, pt: 4 }}>
              <Typography variant="h6" gutterBottom>
                Media & Lager
              </Typography>
              <Grid container spacing={2}>
                {skus.map((sku) => (
                  <Grid item xs={12} md={6} key={sku.attribute1Number}>
                    <Paper sx={{ p: 2 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          mb: 2,
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 'bold' }}
                        >
                          Färg: {sku.attribute1Code}
                        </Typography>
                        <Box
                          component="div"
                          sx={{
                            display: 'flex',
                            gap: 2,
                            ml: 'auto',
                          }}
                        >
                          {selectedProduct?.stockByColor && (
                            <Chip
                              size="small"
                              icon={<InventoryIcon />}
                              label={
                                (selectedProduct.stockByColor[
                                  sku.attribute1Number
                                ]?.stockBalance ?? 0) > 0
                                  ? `${selectedProduct.stockByColor[sku.attribute1Number].stockBalance} st`
                                  : 'Ej i lager'
                              }
                              color={
                                (selectedProduct.stockByColor[
                                  sku.attribute1Number
                                ]?.stockBalance ?? 0) > 0
                                  ? 'success'
                                  : 'error'
                              }
                            />
                          )}
                          {sku.missingPrice === true && (
                            <Chip
                              size="small"
                              icon={<WarningIcon />}
                              label="Saknar pris"
                              color="warning"
                            />
                          )}
                        </Box>
                      </Box>

                      {productMedia[sku.attribute1Number] &&
                      productMedia[sku.attribute1Number].length > 0 ? (
                        <ImageList
                          cols={4}
                          gap={4}
                          sx={{ width: '100%', height: 'auto' }}
                        >
                          {productMedia[sku.attribute1Number].map(
                            (imageName, index) => (
                              <Box
                                key={index}
                                sx={{
                                  height: 100,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  padding: 0,
                                  backgroundColor: 'lightgray',
                                  borderRadius: '8px',
                                  overflow: 'hidden',
                                }}
                              >
                                <SmartImage
                                  baseUrl="https://media.viskanassets.com/v2/enghssportprod/original/"
                                  imageName={imageName}
                                  alt={`Bild ${index + 1}`}
                                  loading="lazy"
                                  style={{
                                    maxHeight: '100%',
                                    maxWidth: '100%',
                                    objectFit: 'contain',
                                  }}
                                />
                              </Box>
                            ),
                          )}
                        </ImageList>
                      ) : (
                        <Box
                          sx={{
                            height: 100,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'background.paper',
                            borderRadius: 1,
                            flexDirection: 'column',
                            cursor: 'pointer',
                            border: '1px dashed',
                            borderColor: 'divider',
                            transition: 'all 0.2s ease-in-out',
                            '&:hover': {
                              borderColor: 'primary.main',
                              backgroundColor: 'action.hover',
                              '& .MuiSvgIcon-root': {
                                color: 'primary.main',
                              },
                              '& .MuiTypography-root': {
                                color: 'primary.main',
                              },
                            },
                          }}
                          onClick={() => handleOpenUploadModal(sku)}
                        >
                          <AddCircleOutlineIcon
                            sx={{
                              fontSize: 40,
                              color: 'text.secondary',
                              mb: 1,
                              transition: 'color 0.2s ease-in-out',
                            }}
                          />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              transition: 'color 0.2s ease-in-out',
                              px: 2,
                              textAlign: 'center',
                            }}
                          >
                            Ingen media kopplad. Lägg till media
                          </Typography>
                        </Box>
                      )}
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Prissektion */}
            <Box ref={pricesRef} sx={{ mb: 6, pt: 4 }}>
              <Typography variant="h6" gutterBottom>
                Prishantering
              </Typography>
              <Paper sx={{ p: 2 }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Färg</TableCell>
                        <TableCell>Valuta</TableCell>
                        <TableCell>Ordinarie Pris</TableCell>
                        <TableCell>Rabatt %</TableCell>
                        <TableCell>Försäljningspris</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {skuPrices.map((sku) => (
                        <TableRow key={sku.skuId}>
                          <TableCell>{sku.attribute1Code}</TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                              <Chip
                                label="🇸🇪 SEK"
                                onClick={() =>
                                  handleToggleCurrency(sku.skuId, 'SEK')
                                }
                                color={
                                  selectedCurrencies[sku.skuId]?.includes('SEK')
                                    ? 'primary'
                                    : 'default'
                                }
                                sx={{ cursor: 'pointer' }}
                              />
                              <Chip
                                label="🇩🇰 DKK"
                                onClick={() =>
                                  handleToggleCurrency(sku.skuId, 'DKK')
                                }
                                color={
                                  selectedCurrencies[sku.skuId]?.includes('DKK')
                                    ? 'primary'
                                    : 'default'
                                }
                                sx={{ cursor: 'pointer' }}
                              />
                            </Box>
                          </TableCell>
                          <TableCell>
                            {selectedCurrencies[sku.skuId]?.map(
                              (currencyCode) => (
                                <Box key={currencyCode} sx={{ mb: 1 }}>
                                  <TextField
                                    value={
                                      currencyPriceInputs[sku.skuId]?.[
                                        currencyCode
                                      ]?.unitPriceOriginal || ''
                                    }
                                    variant="outlined"
                                    size="small"
                                    label={`${currencyCode} Original`}
                                    onChange={(e) =>
                                      handleCurrencyPriceChange(
                                        sku.skuId,
                                        currencyCode,
                                        'unitPriceOriginal',
                                        e.target.value,
                                      )
                                    }
                                    inputProps={{
                                      inputMode: 'decimal',
                                      pattern: '^[0-9]*[.,]?[0-9]*$',
                                    }}
                                  />
                                </Box>
                              ),
                            )}
                          </TableCell>
                          <TableCell>
                            {selectedCurrencies[sku.skuId]?.map(
                              (currencyCode) => (
                                <Box key={currencyCode} sx={{ mb: 1 }}>
                                  <TextField
                                    value={
                                      discountInput[
                                        `${sku.skuId}-${currencyCode}`
                                      ] || ''
                                    }
                                    variant="outlined"
                                    size="small"
                                    label="%"
                                    onChange={(e) =>
                                      handleCurrencyDiscountChange(
                                        sku.skuId,
                                        currencyCode,
                                        e.target.value,
                                      )
                                    }
                                    inputProps={{
                                      inputMode: 'decimal',
                                      pattern: '^[0-9]*[.,]?[0-9]*$',
                                      style: { width: '80px' },
                                    }}
                                  />
                                </Box>
                              ),
                            )}
                          </TableCell>
                          <TableCell>
                            {selectedCurrencies[sku.skuId]?.map(
                              (currencyCode) => (
                                <Box key={currencyCode} sx={{ mb: 1 }}>
                                  <TextField
                                    value={
                                      currencyPriceInputs[sku.skuId]?.[
                                        currencyCode
                                      ]?.unitPrice || ''
                                    }
                                    variant="outlined"
                                    size="small"
                                    label={`${currencyCode} Försäljning`}
                                    onChange={(e) =>
                                      handleCurrencyPriceChange(
                                        sku.skuId,
                                        currencyCode,
                                        'unitPrice',
                                        e.target.value,
                                      )
                                    }
                                    inputProps={{
                                      inputMode: 'decimal',
                                      pattern: '^[0-9]*[.,]?[0-9]*$',
                                    }}
                                  />
                                </Box>
                              ),
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Box>
        </Box>
      </Dialog>

      {/* Upload Media Modal */}
      {selectedSku && (
        <UploadMediaModal
          open={isUploadModalOpen}
          onClose={handleCloseUploadModal}
          onSubmit={handleUploadSubmit}
          uploadedImages={uploadedImages}
          handlePreview={handlePreview}
          productNumber={selectedProduct?.productNumber || ''}
          availableColors={availableColors}
          initialColorCode={selectedSku.attribute1Code}
          attribute1Number={selectedSku.attribute1Number}
        />
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ProductList;
