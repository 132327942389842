import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import Unauthorized from './pages/Unauthorized';
import ProtectedRoute from './components/ProtectedRoute';
import UserManagement from './pages/UserManagement';
import ProductManagement from './pages/ProductManagement';
import Dashboard from './pages/Dashboard';
import PriceChangeModule from './pages/PriceChangeModule';
import Layout from './components/Layout';
import ProductImportEdit from './pages/ProductImportEdit';
import ProductImportList from './pages/ProductImport';
import SalesDashboard from './pages/SalesDashboard';
import JobTriggers from './pages/JobTriggers';
import PriceTagCreator from './pages/PriceTagCreator';

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Layout>
              <Dashboard />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/user-management"
        element={
          <ProtectedRoute>
            <Layout>
              <UserManagement />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/product-management"
        element={
          <ProtectedRoute>
            <Layout>
              <ProductManagement />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/price-change"
        element={
          <ProtectedRoute>
            <Layout>
              <PriceChangeModule />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/product-import"
        element={
          <ProtectedRoute>
            <Layout>
              <ProductImportList />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/product-import/new"
        element={
          <ProtectedRoute>
            <Layout>
              <ProductImportEdit />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/product-import/edit/:id"
        element={
          <ProtectedRoute>
            <Layout>
              <ProductImportEdit />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/product-import/view/:id"
        element={
          <ProtectedRoute>
            <Layout>
              <ProductImportEdit isReadOnly />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/sales"
        element={
          <ProtectedRoute>
            <Layout>
              <SalesDashboard />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/jobs"
        element={
          <ProtectedRoute>
            <Layout>
              <JobTriggers />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/price-tags"
        element={
          <ProtectedRoute>
            <Layout>
              <PriceTagCreator />
            </Layout>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default App;
