import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  Typography,
  Box,
  IconButton,
  Card,
  CardContent,
  Button,
  Grow,
  Divider,
  useTheme,
  Avatar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InventoryIcon from '@mui/icons-material/Inventory';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { StockNotification } from '../types/notifications';

interface StockNotificationsDialogProps {
  open: boolean;
  onClose: () => void;
  notifications: StockNotification[];
  onMarkAsRead: (notificationId: number) => void;
}

const StockNotificationsDialog: React.FC<StockNotificationsDialogProps> = ({
  open,
  onClose,
  notifications,
  onMarkAsRead,
}) => {
  const theme = useTheme();
  const [displayCount, setDisplayCount] = useState(5);

  // Begränsa antalet notifikationer som visas
  const visibleNotifications = notifications.slice(0, displayCount);

  // Hantera "Visa mer"
  const handleLoadMore = () => {
    setDisplayCount((prev) => prev + 5);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '24px',
          background: 'rgba(255, 255, 255, 0.95)',
          backdropFilter: 'blur(20px)',
          boxShadow:
            '0 20px 80px rgba(0, 0, 0, 0.3), 0 0 40px rgba(63, 81, 181, 0.2)',
          overflow: 'hidden',
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '4px',
            background: 'linear-gradient(90deg, #3F51B5, #FF4081)',
            zIndex: 1,
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 3,
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          <Avatar
            sx={{
              background: 'linear-gradient(135deg, #3F51B5, #536DFE)',
              width: 40,
              height: 40,
              boxShadow: '0 4px 12px rgba(63, 81, 181, 0.2)',
              mr: 2,
            }}
          >
            <InventoryIcon sx={{ fontSize: 24 }} />
          </Avatar>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              background: 'linear-gradient(135deg, #3F51B5, #FF4081)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              letterSpacing: '-0.02em',
            }}
          >
            Lagerförändringar
          </Typography>
        </Box>
        <IconButton
          aria-label="stäng"
          onClick={onClose}
          sx={{
            color: theme.palette.text.secondary,
            transition: 'all 0.3s ease',
            '&:hover': {
              color: theme.palette.primary.main,
              transform: 'rotate(90deg)',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        {notifications.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 6,
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Avatar
              sx={{
                bgcolor: 'rgba(63, 81, 181, 0.1)',
                width: 80,
                height: 80,
                mb: 2,
              }}
            >
              <InventoryIcon
                sx={{ fontSize: 48, color: theme.palette.primary.main }}
              />
            </Avatar>
            <Typography variant="h6" color="text.secondary" fontWeight={500}>
              Inga nya lagerförändringar
            </Typography>
          </Box>
        ) : (
          <>
            <List sx={{ p: 0 }}>
              {visibleNotifications.map((notification, index) => (
                <Grow
                  key={notification.notificationId}
                  in={true}
                  timeout={(index + 1) * 200}
                >
                  <Card
                    sx={{
                      mb: 2,
                      background: '#FFFFFF',
                      transition: 'all 0.3s ease',
                      borderRadius: '16px',
                      boxShadow: '0 10px 30px rgba(0, 0, 0, 0.05)',
                      overflow: 'hidden',
                      position: 'relative',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 15px 35px rgba(0, 0, 0, 0.1)',
                      },
                    }}
                  >
                    <CardContent sx={{ position: 'relative', p: 3 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box>
                          <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                              fontWeight: 600,
                              color: theme.palette.text.primary,
                            }}
                          >
                            {notification.brandName} -{' '}
                            {notification.productName}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: theme.palette.text.secondary,
                              mb: 2,
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5,
                            }}
                          >
                            Art.nr: {notification.productNumber}
                          </Typography>
                        </Box>
                        <Button
                          variant="contained"
                          size="small"
                          startIcon={<CheckCircleIcon />}
                          onClick={() =>
                            onMarkAsRead(notification.notificationId)
                          }
                          sx={{
                            borderRadius: '12px',
                            background:
                              'linear-gradient(135deg, #3F51B5, #536DFE)',
                            boxShadow: '0 4px 12px rgba(63, 81, 181, 0.2)',
                            color: '#FFFFFF',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              background:
                                'linear-gradient(135deg, #3949AB, #3F51B5)',
                              transform: 'translateY(-2px)',
                              boxShadow: '0 8px 15px rgba(63, 81, 181, 0.3)',
                            },
                          }}
                        >
                          Markera som läst
                        </Button>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 3,
                          background: 'rgba(63, 81, 181, 0.05)',
                          borderRadius: '12px',
                          p: 2,
                          mt: 2,
                        }}
                      >
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <Typography variant="body2" color="text.secondary">
                            Tidigare:
                          </Typography>
                          <Typography
                            variant="h6"
                            color="text.primary"
                            fontWeight={600}
                          >
                            {notification.previousBalance} st
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: notification.increase
                              ? theme.palette.success.main
                              : theme.palette.error.main,
                            gap: 1,
                            px: 2,
                            py: 0.5,
                            borderRadius: '8px',
                            bgcolor: notification.increase
                              ? 'rgba(0, 200, 83, 0.1)'
                              : 'rgba(255, 61, 0, 0.1)',
                          }}
                        >
                          {notification.increase ? (
                            <TrendingUpIcon />
                          ) : (
                            <TrendingDownIcon />
                          )}
                          <Typography variant="h6" fontWeight={600}>
                            {notification.increase ? '+' : ''}
                            {notification.newBalance -
                              notification.previousBalance}{' '}
                            st
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            px: 2,
                            py: 0.5,
                            borderRadius: '8px',
                            bgcolor: 'rgba(63, 81, 181, 0.1)',
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="primary.main"
                            fontWeight={500}
                          >
                            Nytt saldo:
                          </Typography>
                          <Typography
                            variant="h6"
                            color="primary.main"
                            fontWeight={600}
                          >
                            {notification.newBalance} st
                          </Typography>
                        </Box>
                      </Box>

                      <Typography
                        variant="caption"
                        sx={{
                          display: 'block',
                          mt: 2,
                          color: theme.palette.text.secondary,
                          fontStyle: 'italic',
                        }}
                      >
                        {new Date(notification.createdAt).toLocaleDateString(
                          'sv-SE',
                          {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          },
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grow>
              ))}
            </List>
            {displayCount < notifications.length && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <Button
                  onClick={handleLoadMore}
                  variant="outlined"
                  sx={{
                    borderRadius: '12px',
                    borderWidth: '2px',
                    padding: '0.625rem 1.5rem',
                    fontWeight: 600,
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      borderWidth: '2px',
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 8px rgba(63, 81, 181, 0.2)',
                    },
                  }}
                >
                  Visa fler ({notifications.length - displayCount} kvar)
                </Button>
              </Box>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default StockNotificationsDialog;
